import { Component, Input } from '@angular/core';
import { Team } from '../models/team.model';

// interface ITeam {
//   name: string;
//   players: IPlayer[];
// }

// interface IPlayer {
//   name: string;
//   score: number;
// }

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.css']
})
export class ScoreboardComponent {
  @Input() teams: Team[] = [];
}
