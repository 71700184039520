import { GameController } from "../controllers/game.controller";
import { InteractionDisplayType, VideoInteraction, VideoMarker, VideoMarkerType } from "./video-interaction.model";

// export enum InteractionDisplayType {
//   MULTIPLE_CHOICE_TEXT = 'MULTIPLE_CHOICE_TEXT',
//   IMAGE_AED = 'IMAGE_AED',
// }


export class Question implements VideoInteraction {
  constructor({id, pointsValue = 20, question, answer, options, styles = [], markers, answeredBy, incorrectAnsweredBy, teamIndex, displayType = InteractionDisplayType.MULTIPLE_CHOICE_TEXT, scene = ""}: {id?: string, pointsValue?: number, question: string, answer: string, options?: Array<string>, styles?: Array<string | any>, markers?: Array<VideoMarker>, answeredBy?: string, incorrectAnsweredBy?: Array<string>, teamIndex?: number, displayType?: InteractionDisplayType, scene?: string}) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.options = options || [];
    this.styles = [];
    for(let style of styles || []) {
      if (typeof style === 'string') {
        if (style.length > 0) {
          try {
            // strip off any extra quotes
            while (style.startsWith("'") || style.startsWith('"')) {
              style = style.slice(1);
            }
            while (style.endsWith("'") || style.endsWith('"')) {
              style = style.slice(0, -1);
            }
            this.styles.push(JSON.parse(style));
          }
          catch (e) {
            console.error(e);
            this.styles.push(style);
          }
        }
      }
      else {
        this.styles.push(style);
      }
    }
    this.setMarkers(markers || []);
    this.answeredBy = answeredBy;
    this.incorrectAnsweredBy = incorrectAnsweredBy;
    this.teamIndex = teamIndex || -1;
    if (typeof displayType === 'string') {
      this.displayType = InteractionDisplayType[displayType as keyof typeof InteractionDisplayType];
    }
    else {
      this.displayType = displayType || InteractionDisplayType.MULTIPLE_CHOICE_TEXT;
    }
    this.scene = scene;
    this.pointsValue = pointsValue;
  }

  id: string;
  pointsValue: number
  question: string;
  answer: string;
  options?: Array<string> = [];
  styles?: Array<any> = [];

  private _markers?: Array<VideoMarker>;
  get markers(): Array<VideoMarker> {
    return this._markers || [];
  }
  setMarkers(markers: Array<VideoMarker>) {
    // Sort the markers by timestamp
    markers = (markers || []).map(m => new VideoMarker(m));
    markers.sort((a, b) => a.timestamp - b.timestamp);
    this._markers = markers;
  }

  answeredBy?: string;
  incorrectAnsweredBy?: Array<string>;
  teamIndex: number
  displayType: InteractionDisplayType = InteractionDisplayType.MULTIPLE_CHOICE_TEXT;
  scene: string;
  interactionComplete: boolean = false;

  // @Transient
  selectedAnswer?: string;

  checkAnswer(answer: string): boolean {
    return this.answer.toLowerCase() === answer.toLowerCase();
  }

  update(question: Question) {
    this.id = question.id;
    this.pointsValue = question.pointsValue;
    this.question = question.question;
    this.answer = question.answer;
    this.options = question.options;
    this.styles = question.styles;
    this.setMarkers(question.markers);
    this.answeredBy = question.answeredBy;
    this.incorrectAnsweredBy = question.incorrectAnsweredBy;
    this.teamIndex = question.teamIndex;
    this.displayType = question.displayType || InteractionDisplayType.MULTIPLE_CHOICE_TEXT;
    this.scene = question.scene;
  }
}

export class CPRCompleteSession extends Question {

  constructor({id = 'COMPLETE_CPR_SESSION_QUESTION', pointsValue = 40, question = 'Who won?', answer = '', options = ['Team Cardiac', 'Tie', 'Team Star'], styles = [], markers = [], answeredBy = null, incorrectAnsweredBy = null, teamIndex = -1, displayType = InteractionDisplayType.MULTIPLE_CHOICE_TEXT, scene = "", timestamp}: {id?: string, pointsValue?: number, question?: string, answer?: string, options?: Array<string>, styles?: Array<string | any>, markers?: Array<VideoMarker>, answeredBy?: string, incorrectAnsweredBy?: Array<string>, teamIndex?: number, displayType?: InteractionDisplayType, scene?: string, timestamp: number}) {
    super({id, pointsValue, question, answer, options, styles, markers, answeredBy, incorrectAnsweredBy, teamIndex, displayType, scene});

    // create START marker at the timestamp
    let startPoint = new VideoMarker({type: VideoMarkerType.START, timestamp: timestamp, id: 'START_POINT', offset: 0});
    // create one PAUSE_POINT marker at the timestamp + 150ms
    let pausePoint = new VideoMarker({type: VideoMarkerType.PAUSE_POINT, timestamp: timestamp + GameController.TIME_OFFSET + 150, id: 'PAUSE_POINT', offset: GameController.TIME_OFFSET + 150});
    // create an END_POINT marker at the timestamp + 300ms
    let endPoint = new VideoMarker({type: VideoMarkerType.END, timestamp: timestamp + GameController.TIME_OFFSET + 250, id: 'END_POINT', offset: GameController.TIME_OFFSET + 250});
    this.setMarkers([startPoint, pausePoint, endPoint]);
  }

  override checkAnswer(answer: string): boolean {
    // any answer is correct
    if (answer === "Team Cardiac") {
      this.teamIndex = 0;
      this.answer = "Team Cardiac";
    }
    else if (answer === "Team Star") {
      this.teamIndex = 1;
      this.answer = "Team Star";
    }
    else {
      this.teamIndex = 2;
      this.answer = "Tie";
    }
    return true;
  }
}
