import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache, ApolloLink, Observable } from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import { AuthTokenService } from './auth-token.service';

export enum FetchState {
  NONE,
  PENDING,
  LOADING,
  ERROR,
  LOADED_ALL,
  NO_RESULTS,
  GOOD
}


// const handleLink = (): ApolloLink => {
//   return new ApolloLink((operation, forward) => {
//     return new Observable(observer => {
//       const subscription = forward(operation).subscribe({
//         next: response => {          // Check if the response contains an error indicating token expiration
//           const { errors } = response as any;
//           console.log("=== GRAPHQL ERRORS ===", errors);

//           observer.next(response);
//           observer.complete();
//         },
//         error: err => {
//           observer.error(err);
//         },
//       });

//       return () => {
//         if (subscription) {
//           subscription.unsubscribe();
//         }
//       };
//     });
//   });
// };


export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  // const auth = setContext((operation, context) => {
  //   console.log("Operation: " + operation.operationName + " With variables: " )
  //   console.log(operation.variables)

  //   const token = localStorage.getItem('access_token');
  //   const systemId = localStorage.getItem('system_id');

  //   console.log("Token Stored: " + token)
  //   if (token) {
  //     console.log('=== GRAPHQL AUTHORIZATION HEADER ===');
  //     console.log("Token: " + token)
  //     return {
  //       headers: {
  //         Authorization: token ? `Bearer ${token}` : '',
  //         'x-system-id': systemId ? systemId : '',
  //       }
  //     };
  //   } else {
  //     return {};
  //   }
  // });

  // Create an http link:
  const http = httpLink.create({
    uri: `${environment.api}/graphql`,
  });

  // const isHttps = environment.api.startsWith('https://');

  // // Create a WebSocket link:
  // const wsUrl = environment.api_ws ? `${environment.api_ws}/graphql` : `${isHttps ? 'wss' : 'ws'}://${environment.api.replace(/^https?:\/\//, '')}/graphql`;
  // // const wsUrl = environment.api_ws ? `${environment.api_ws}` : `${isHttps ? 'wss' : 'ws'}://${environment.api.replace(/^https?:\/\//, '')}/subscriptions`;
  // // const ws = new WebSocketLink({
  // //   uri: wsUrl,
  // //   options: {
  // //     reconnect: true,
  // //   },
  // // });

  // const ws = new GraphQLWsLink(
  //   createClient({
  //     url: wsUrl,
  //   }),
  // );


  // const link = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  //   },
  //   ws,
  //   http
  // );

  return {
    uri: `${environment.api}/graphql`,
    // link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      // deps: [HttpLink, AuthTokenService],
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
