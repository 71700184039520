import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject, catchError, map, takeUntil, throwError } from 'rxjs';
import { Game } from '../models/game.model';
import { SCORE_POINT, START_GAME, STOP_GAME, SUBMIT_QUESTION, SUBMIT_ANSWER, GAME, FETCH_PLAYER_DATA, UPDATE_GAME } from '../graphql/game.graphql';
import { Question } from '../models/question.model';
import { HistogramData,  } from './gyroscope.service';

export class PlayerData {

  constructor({playerId, teamId, name, score, histogramData}: {playerId: string, teamId: string, name: string, score?: number, histogramData: HistogramData}) {
    this.playerId = playerId;
    this.teamId = teamId;
    this.name = name;
    this.score = score;
    this.histogramData = new HistogramData(histogramData || {} as any);
  }

  playerId: string;
  teamId: string;
  name: string;
  score: number;
  histogramData: HistogramData;
}


@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private readonly apollo: Apollo,
  ) {
    // bind methods
    this.fetchGame = this.fetchGame.bind(this);
    this.loadAndSubscribeToGame = this.loadAndSubscribeToGame.bind(this);
    this.subscribeToGame = this.subscribeToGame.bind(this);
    this.subscribeToPlayerData = this.subscribeToPlayerData.bind(this);
    this.unsubscribeFromPlayerData = this.unsubscribeFromPlayerData.bind(this);
    this.startGame = this.startGame.bind(this);
    this.stopGame = this.stopGame.bind(this);
    this.scorePoint = this.scorePoint.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  private _gameSubscription: BehaviorSubject<Game | null> = new BehaviorSubject<Game | null>(null);

  fetchGame(gameId: string): Observable<Game | null> {
    return this.apollo.query({
      query: GAME,
      variables: {
        id: gameId,
      }
    }).pipe(
      map(({ data }) => {
        console.log('Got game data', data);
        let gameData: any = (data as any)['fetchGame'];
        if (!!gameData) {
          const result = new Game(gameData);
          return result;
        } else {
          console.error('Game not found');
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        // Handle the error here, such as displaying an error message or performing any necessary cleanup
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    );
  }

  loadAndSubscribeToGame(gameId: string): Observable<Game> {
    return this.subscribeToGame(gameId);
  }

  private _gameKillSwitch$ = new Subject<void>();
  subscribeToGame(game: Game | string): Observable<Game> {
    let gameId: string = '';
    if (!game) {
      return this._gameSubscription;
    }
    if (game instanceof Game) {
      gameId = game.id;
      // this.game$.next(game);
    }
    else {
      gameId = game;
    }
    if (!!this._gameSubscription) {
      if (this._gameSubscription.getValue()?.id === gameId) {
        return this._gameSubscription;
      }
    }

    return this._subscribeToGame(gameId);
  }

  private _gameInterval: any;
  private _subscribeToGame(gameId: string): BehaviorSubject<Game | null> {
    if (!!this._gameInterval) {
      clearInterval(this._gameInterval);
      this._gameInterval = null;
    }
    // Unsubscribe from the previous game
    if (!!this._gameSubscription) {
      // this.game$.next(null);
      this._gameKillSwitch$.next();
    }

    this.apollo
    .query({
      query: GAME,
      variables: {
        id: gameId,
      },
      fetchPolicy: 'no-cache',
    })
    .pipe(
      takeUntil(this._gameKillSwitch$),
      map(({ data }) => {
        console.log('Got game data', data);
        let gameData: any = (data as any)['fetchGame'];
        if (!!gameData) {
          return new Game(gameData);
        } else {
          console.error('Game not found');
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        // Handle the error here, such as displaying an error message or performing any necessary cleanup
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    )
    .subscribe({
      next: (game) => {
        console.log('Got game data', game);
        this._gameSubscription.next(game);

        // Poll the game every few seconds
        this._gameInterval = setInterval(() => {
          if (!this._fetchingPlayerData) {
            this.apollo.query({
              query: GAME,
              variables: {
                id: gameId,
              },
              fetchPolicy: 'no-cache',
            }).subscribe({
              next: ({data}) => {
                console.log('Got game data', data);
                let gameData: any = (data as any)['fetchGame'];
                if (!!gameData) {
                  this._gameSubscription.next(new Game(gameData));
                }
              },
              error: (error) => {
                console.error(error);
              }
            });
          }
        }, 2500);
      },
      error: (error) => {
        console.error(error);
      }
    });

    return this._gameSubscription;
  }

  private _playerDataIntervalTime: number = 15;
  private _playerDataGameId: string;
  private _playerDataSubscription: BehaviorSubject<PlayerData[]> = new BehaviorSubject<PlayerData[]>(null);
  subscribeToPlayerData(gameId: string, cprSessionId: string): Observable<any> {
    if (!!this._fetchingPlayerData) {
      if (this._playerDataGameId === gameId) {
        return this._playerDataSubscription;
      }
    }

    return this._subscribeToPlayerData(gameId, cprSessionId);
  }
  unsubscribeFromPlayerData() {
    console.log('Unsubscribing from player data');
    this._fetchingPlayerData = false;
    if (!!this._playerDataInterval) {
      clearInterval(this._playerDataInterval);
      this._playerDataInterval = null;
    }

    if (!!this._playerDataSubscription) {
      this._playerDataSubscription.next(null);
    }
  }

  private _playerDataInterval: any;
  private _fetchingPlayerData: boolean = false;
  private _subscribeToPlayerData(gameId: string, cprSessionId: string): BehaviorSubject<any> {
    this._playerDataGameId = gameId;
    if (!!this._playerDataInterval) {
      clearInterval(this._playerDataInterval);
      this._playerDataInterval = null;
    }
    // Unsubscribe from the previous game
    if (!!this._playerDataSubscription) {
      this._playerDataSubscription.next(null);
    }

    // Poll the game every {_playerDataIntervalTime} milliseconds
    this._fetchingPlayerData = true;
    this._playerDataInterval = setTimeout(() => {
      this._internalFetchPlayerData(gameId, cprSessionId);
    }, this._playerDataIntervalTime);

    return this._playerDataSubscription;
  }

  private async _internalFetchPlayerData(gameId, cprSessionId) {
    let startTime = Date.now();
    this.apollo.query({
      query: FETCH_PLAYER_DATA,
      variables: {
        id: gameId,
        cprSessionId,
      },
      fetchPolicy: 'network-only',
    }).subscribe({
      next: ({data}) => {
        // let endTime = Date.now();
        // let delta = endTime - startTime;
        // console.log('DELTA TIME', delta);
        let playerData: any = (data as any)['fetchPlayerData'];
        if (!!playerData) {
          playerData = playerData.map((data) => new PlayerData(data));
          // if (playerData.length > 0) {
          //   let histogramData = new HistogramData(playerData[0].histogramData);
          //   console.log('Player data', {delta: delta, distance: histogramData.distance, duration: histogramData.duration});
          // }
          this._playerDataSubscription.next(playerData);
        }
        else {
          console.error('Player data not found');
        }
      },
      error: (error) => {
        console.log('Error getting player data', error);
        console.error(error);
      }
    });

    // Poll the game every {_playerDataIntervalTime} milliseconds
    if (this._fetchingPlayerData) {
      this._playerDataInterval = setTimeout(() => {
        this._internalFetchPlayerData(gameId, cprSessionId);
      }, this._playerDataIntervalTime);
    }
  }

  async startGame(gameId: string, gameType: string = "athlete_v21", numberOfTeams: number = 2): Promise<Game> {
    // Unsubscribe from the previous game
    if (!!this._gameSubscription) {
      this._gameKillSwitch$.next();
    }

    const that = this;

    return new Promise<Game>((resolve, reject) => {
      // Create subscription by starting the game
      that.apollo.mutate({
        mutation: START_GAME,
        variables: {
          id: gameId,
          numberOfTeams,
          gameType: gameType
        }
      }).subscribe({
        next: ({data}) => {
          let startGameData = (data as any)?.startGame;
          const gameId: string = startGameData?.id;
          if (!!gameId) {
            const game = new Game(startGameData);

            // Store the game in local storage
            localStorage.setItem('game_id', gameId);
            resolve(game);
          }
          else {
            console.error('Game ID not found');
            reject('Game ID not found');
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  async stopGame(gameId: string): Promise<boolean> {

    // Unsubscribe from the previous game
    if (!!this._gameKillSwitch$) {
      this._gameKillSwitch$.next();
      this._gameKillSwitch$.complete();
      this._gameKillSwitch$ = new Subject<void>();
    }

    this._fetchingPlayerData = false;
    if (!!this._playerDataInterval) {
      clearInterval(this._playerDataInterval);
      this._playerDataInterval = null;
    }

    if (!!this._gameInterval) {
      clearInterval(this._gameInterval);
      this._gameInterval = null;
    }

    if (!!this._gameSubscription) {
      this._gameSubscription.unsubscribe();
    }

    // Remove the game from local storage
    localStorage.removeItem('game');

    if (!gameId) {
      return false;
    }

    // Stop the game
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: STOP_GAME,
        variables: {
          id: gameId,
        }
      }).subscribe({
        next: ({data}) => {
          console.log('Stopped game', data);
          // this.game$.next(null);
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  async saveGame(game: Game): Promise<Game> {
    if (!game) {
      return null;
    }

    // Save the game
    return new Promise<Game>((resolve, reject) => {
      this.apollo.mutate({
        mutation: UPDATE_GAME,
        variables: {
          id: game.id,
          status: game.status,
          timestamp: game.currentTimestamp,
          paused: game.currentPaused,
          teamScores: game.teams.map((team) => team.score),
        }
      }).subscribe({
        next: ({data}) => {
          console.log('Saved game', data);
          resolve(new Game(data['updateGame']));
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  scorePoint(gameId: string, playerId: string): void {
    if (!gameId || !playerId) {
      return;
    }

    this.apollo.mutate({
      mutation: SCORE_POINT,
      variables: {
        id: gameId,
        playerId: playerId,
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Scored point', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  async submitQuestion(gameId: string, question: Question) {
    if (!gameId || !question) {
      return;
    }

    // Submit the question
    this.apollo.mutate({
      mutation: SUBMIT_QUESTION,
      variables: {
        gameId: gameId,
        id: question.id,
        question: question.question,
        answer: question.answer,
        options: question.options,
        styles: question.styles,
        timestamp: Date.now(),
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Question Submitted', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  async submitAnswer(gameId: string, interactionId: string,  playerId: string, answer: string) {
    if (!gameId || !interactionId || !playerId) {
      return;
    }

    // Submit the answer
    this.apollo.mutate({
      mutation: SUBMIT_ANSWER,
      variables: {
        gameId: gameId,
        questionId: interactionId,
        playerId: playerId,
        answer: answer,
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Answer Submitted', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


}
