import gql from 'graphql-tag';

export type PlayerInput = {
  id: string;
  name: string;
}

export const PLAYER_DETAILS = gql`
  fragment PlayerDetails on Player {
    id
    name
    score
    url
    currentResults {
      compressionScore
      depthScore
      averageCompressionDepth
      qualityScore
      activePercentage
      overallScore
      averageDuration
    }
    finalResults {
      compressionScore
      depthScore
      averageCompressionDepth
      qualityScore
      activePercentage
      overallScore
      averageDuration
    }
  }
`;

export const VIDEO_MARKER_DETAILS = gql`
  fragment VideoMarkerDetails on VideoMarker {
    id
    timestamp
    offset
    type
    value
  }
`;

export const BASE_INTERACTION_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment BaseInteractionDetails on BaseInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

export const QUESTION_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment QuestionDetails on Question {
    id
    pointsValue
    question
    answer
    options
    styles
    markers {
      ...VideoMarkerDetails
    }
    answeredBy
    incorrectAnsweredBy
    teamIndex
    displayType
    scene
  }
`;

export const CPR_SESSION_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment CPRSessionDetails on CPRSession {
    id
    pointsValue
    groupNumber
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

export const JUMP_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment JumpDetails on JumpInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

// RESULTS
export const RESULTS_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment ResultsDetails on ResultsInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

// FINAL_RESULTS
export const FINAL_RESULTS_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment FinalResultsDetails on FinalResultsInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

// TEAM_SCORE
export const TEAM_SCORE_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment TeamScoreDetails on TeamScoreInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    teamName
    scene
  }
`;

// SUMMARY
export const SUMMARY_DETAILS = gql`
${VIDEO_MARKER_DETAILS}
  fragment SummaryDetails on SummaryInteraction {
    id
    markers {
      ...VideoMarkerDetails
    }
    displayType
    scene
  }
`;

export const BASE_GAME_DETAILS = gql`
${PLAYER_DETAILS}
${QUESTION_DETAILS}
${CPR_SESSION_DETAILS}
${JUMP_DETAILS}
${RESULTS_DETAILS}
${FINAL_RESULTS_DETAILS}
${TEAM_SCORE_DETAILS}
${SUMMARY_DETAILS}
${BASE_INTERACTION_DETAILS}
  fragment BaseGameDetails on Game {
    id
    name
    dateCreated
    dateEnded
    status
    videoUrl
    interactions {
      ... on Question {
        ...QuestionDetails
      }
      ... on CPRSession {
        ...CPRSessionDetails
      }
      ... on JumpInteraction {
        ...JumpDetails
      }
      ... on ResultsInteraction {
        ...ResultsDetails
      }
      ... on FinalResultsInteraction {
        ...FinalResultsDetails
      }
      ... on TeamScoreInteraction {
        ...TeamScoreDetails
      }
      ... on SummaryInteraction {
        ...SummaryDetails
      }
      ... on BaseInteraction {
        ...BaseInteractionDetails
      }
    }
    maxNumPlayers
    teams {
      id
      name
      score
      players {
        ...PlayerDetails
      }
    }
  }
`;

export const GAME_STATE_DETAILS = gql`
  fragment GameStateDetails on Game {
    id
    status
    lastTimestamp
    teams {
      id
      name
      score
    }
  }
`;


export const GAME = gql`
${BASE_GAME_DETAILS}
  query fetchGame($id: String!) {
    fetchGame(id: $id) {
      ...BaseGameDetails
    }
  }
`;

export const GAME_STATE = gql`
${GAME_STATE_DETAILS}
  query fetchGame($id: String!) {
    fetchGame(id: $id) {
      ...GameStateDetails
    }
  }
`;

// Keep this since it might be needed on the server side
export const PLAYER_DATA_SUBSCRIPTION = gql`
  subscription PlayerDataUpdated($gameId: String!) {
    playerDataUpdated(gameId: $gameId) {
      playerId
      teamId
      name
      score
      finalResults {
        overallScore
        compressionDepth
        compressionRate
        compressionRecoil
        handsOnTime
      }
      currentResults {
        overallScore
        compressionDepth
        compressionRate
        compressionRecoil
        handsOnTime
      }
    }
  }
`;

export const SYNC_GAME_DATA = gql`
  query syncGameData($id: String!, $status: String, $timestamp: Int, $paused: Boolean, $teamScores: [Int!], $cprSessionId: String) {
    syncGameData(id: $id, gameInput: {status: $status, timestamp: $timestamp, paused: $paused, teamScores: $teamScores}, cprSessionId: $cprSessionId) {
      playerId
      teamId
      name
      score
      currentResults {
        compressionScore
        depthScore
        averageCompressionDepth
        qualityScore
        activePercentage
        overallScore
        averageDuration
      }
      finalResults {
        compressionScore
        depthScore
        averageCompressionDepth
        qualityScore
        activePercentage
        overallScore
        averageDuration
      }
    }
  }
`;


export const START_GAME = gql`
${BASE_GAME_DETAILS}
  mutation startGame($id: String, $numberOfTeams: Int, $gameType: String) {
    startGame(id: $id, numberOfTeams: $numberOfTeams, gameType: $gameType) {
      ...BaseGameDetails
    }
  }
`;

export const STOP_GAME = gql`
  mutation stopGame($id: String!) {
    stopGame(id: $id)
  }
`;

export const UPDATE_GAME = gql`
${BASE_GAME_DETAILS}
  mutation UpdateGame($id: String!, $status: String, $timestamp: Int, $paused: Boolean, $teamScores: [Int!]) {
    updateGame(id: $id, gameInput: {status: $status, timestamp: $timestamp, paused: $paused, teamScores: $teamScores}) {
      ...BaseGameDetails
    }
  }
`;

export const JOIN_GAME = gql`
${BASE_GAME_DETAILS}
  mutation JoinGame($gameId: String!, $playerInput: PlayerInput!, $teamId: String) {
    joinGame(id: $gameId, playerInput: $playerInput, teamId: $teamId) {
      ...BaseGameDetails
    }
  }
`;

export const SCORE_POINT = gql`
  mutation scorePoint($id: String!, $playerId: String!) {
    scorePoint(id: $id, playerId: $playerId)
  }
`;

// @Component({
//   selector: 'app-game',
//   template: `<!-- your template here -->`,
// })
// export class GameComponent implements OnInit {
//   constructor(private apollo: Apollo) {}

//   ngOnInit() {
//     this.apollo.subscribe({
//       query: START_GAME,
//       variables: { gameId: 'some_game_id' }
//     }).subscribe(({data}) => {
//       console.log('Got data', data);
//     });

//     this.apollo.subscribe({
//       query: JOIN_GAME,
//       variables: {
//         gameId: 'some_game_id',
//         player: { /* player object */ },
//         teamId: 'some_team_id'
//       }
//     }).subscribe(({data}) => {
//       console.log('Got data', data);
//     });
//   }
// }

export const SUBMIT_QUESTION = gql`
  mutation submitQuestion($gameId: String!, $id: String, $question: String!, $answer: String!, $options: [String!], $timestamp: Int) {
    submitQuestion(gameId: $gameId, id: $id, question: $question, answer: $answer, options: $options, timestamp: $timestamp)
  }
`;

export const SUBMIT_ANSWER = gql`
  mutation submitAnswer($gameId: String!, $questionId: String!, $playerId: String!, $answer: String!) {
    submitAnswer(gameId: $gameId, questionId: $questionId, playerId: $playerId, answer: $answer)
  }
`;

export const UPDATE_PLAYER_GYRO_AND_ORIENTATION = gql`
${PLAYER_DETAILS}
mutation UpdatePlayerGyroAndOrientation($gameId: String!, $gamePosition: String!, $data: [ZValueDataInput!]) {
    updatePlayerGyroAndOrientation(gameId: $gameId, gamePosition: $gamePosition, data: $data) {
      ...PlayerDetails
    }
  }
`;

