import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-feedback-indicator',
  templateUrl: './feedback-indicator.component.html',
  styleUrl: './feedback-indicator.component.css'
})
export class FeedbackIndicatorComponent implements OnChanges, OnInit {
  @Input() rate: number;
  @Input() fillLevel: number; // Expected range 0 to 100
  @Input() movementDirection: "horizontal" | "vertical" = "horizontal";
  fillPercent: number = 0;
  fillHeight: number = 0; // Initial fill height
  currentColor: string;
  fillColor: string = '#ff0000'; // Initial color: red
  private interval: any;

  static DESIRED_RATE_IN_Hz = 2.2;

  get rateSigmoid(): number {
    // rate is in milliseconds, so convert to Hz
    let rate = 1000 / this.rate;
    rate = rate - FeedbackIndicatorComponent.DESIRED_RATE_IN_Hz;
    return 1 / (1 + Math.exp(-rate));
    // return this.calculateAmplification(this.rate);
  }

  calculateAmplification(rate: number): number {
    const targetRate = 2; // Target rate in Hz
    const a = 4; // Chosen amplification factor, adjust based on experimentation
    const maxDeviationRate = 1.5; // One of the bounds (the other being 2.5Hz)

    // Calculate the maximum amplification (at 1.5Hz or 2.5Hz) for normalization
    const maxAmplification = a * Math.pow(maxDeviationRate - targetRate, 2);

    // Calculate the current amplification
    let amplification = a * Math.pow(rate - targetRate, 2);

    // Normalize amplification to the [0..100] range
    amplification = (amplification / maxAmplification) * 100;

    // Ensure the result is bounded between 0 and 100
    amplification = Math.min(Math.max(amplification, 0), 100);

    return amplification;
  }



  // used for the style attribute
  get positionOffsetString(): string {
    // get the dimensions of the sliding container
    let parentContainerDimension = 0;
    if (this.slidingContainer?.nativeElement) {
      if (this.movementDirection === "horizontal") {
        parentContainerDimension = this.slidingContainer.nativeElement.offsetWidth;
      }
      else if (this.movementDirection === "vertical") {
        parentContainerDimension = this.slidingContainer.nativeElement.offsetHeight;
      }
    }

    // get the dimensions of the diamond container
    const containerWidth = this.diamondContainer?.nativeElement?.offsetWidth || 0;
    const diamondWidth = containerWidth;
    const diamondCenter = containerWidth / 2;

    // available space for the diamond to move
    const availableSpace = parentContainerDimension - diamondWidth;
    // let desiredCenterPosition = this.rateSigmoid * availableSpace * 1.2;
    let desiredCenterPosition = (this.fillLevel / 100.) * availableSpace * 1.2;
    if (this.movementDirection === "vertical") {
      desiredCenterPosition = (parentContainerDimension - desiredCenterPosition);
    }

    let offsetPosition = desiredCenterPosition - diamondCenter + diamondCenter;
    if (this.movementDirection === "vertical") {
      offsetPosition = desiredCenterPosition - diamondCenter;
    }
    else {
      offsetPosition = desiredCenterPosition + diamondCenter;
    }

    // minimum and maximum bounds
    offsetPosition = Math.min(Math.max(offsetPosition, -75), availableSpace);

    // console.log(`offsetPosition: ${offsetPosition}px`);
    return `${offsetPosition}px`;
  }

  get styleObject(): any {
    if (this.movementDirection === "horizontal") {
      return {
        'left': this.positionOffsetString
      };
    }
    else if (this.movementDirection === "vertical") {
      return {
        'top': this.positionOffsetString
      };
    }

    return {};
  }

  @ViewChild('slidingContainer') slidingContainer: ElementRef;
  @ViewChild('diamondContainer') diamondContainer: ElementRef;


  constructor() {
    this.fillLevel = 0; // Default fill level
    // this.updateFill();
  }

  ngOnInit(): void {
    this.updateFill(true); // Initialize with instant update
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['fillLevel']) {
      this.fillHeight = this.fillLevel;
      this.updateFill();
    }
  }

  private updateFill(instant: boolean = false): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    const steps = 20; // Number of steps for the animation
    const stepDuration = 25; // Duration of each step in ms
    const startPercent = this.fillPercent;
    const endPercent = this.fillLevel;
    const colorProgressStart = startPercent / 100;
    const colorProgressEnd = endPercent / 100;
    let currentStep = 0;

    this.interval = setInterval(() => {
      currentStep++;
      const stepEase = currentStep / steps;
      this.fillPercent = startPercent + (endPercent - startPercent) * stepEase;
      const colorProgress = colorProgressStart + (colorProgressEnd - colorProgressStart) * stepEase;
      this.currentColor = `rgb(${255 * (1 - colorProgress)}, ${255 * colorProgress}, 0)`;

      if (currentStep >= steps) {
        clearInterval(this.interval);
      }
    }, stepDuration);
    // this.fillPercent = this.fillLevel;
    // const colorProgress = this.fillLevel / 100;
    // this.currentColor = `rgb(${255 * (1 - colorProgress)}, ${255 * colorProgress}, 0)`;

    const greenIntensity = Math.round((this.fillLevel / 100) * 255);
    const redIntensity = 255 - greenIntensity;
    this.fillColor = `rgb(${redIntensity}, ${greenIntensity}, 0)`;
    // this.fillColor = `rgb(255, 255, 255)`;
    if (this.fillLevel > 115) {
      this.fillColor = `red`;
    }
    else {
      this.fillColor = `black`;
    }
  }
}
