export const environment = {
  production: false,
  // api: "http://192.168.125.196:5001",
  // api: "https://10.0.0.207:5001",
  api: "https://1a3pup9k57.execute-api.us-west-2.amazonaws.com/dev",
  // api: "https://10.0.0.94:5001",
  // api: "https://1a3pup9k57.execute-api.us-west-2.amazonaws.com/dev",
  // api_ws: "wss://192.168.125.197:5001",
  // api: "https://localhost:5001",
  // api_ws: "wss://localhost:5001",
  // api: "https://svrn4x5cd9.execute-api.us-east-2.amazonaws.com/dev",
  // api_ws: "wss://zvptvgm1hd.execute-api.us-east-2.amazonaws.com/dev",
};
