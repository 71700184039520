import { Component, Input } from '@angular/core';
import { Player, Team } from 'src/app/models/team.model';

@Component({
  selector: 'app-team-scoreboard',
  templateUrl: './team-scoreboard.component.html',
  styleUrls: ['./team-scoreboard.component.css']
})
export class TeamScoreboardComponent {
  @Input() team: Team | null = null;
  @Input() player: Player | null = null;

  constructor() { }

  isCurrentPlayer(player: Player): boolean {
    if (!this.player || !player) {
      return false;
    }
    return player.id === this.player.id;
  }
}
