import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { Subject, takeUntil } from 'rxjs';
import { FetchState } from 'src/app/graphql.module';
import { Game } from 'src/app/models/game.model';
import { Question } from 'src/app/models/question.model';
import { Team } from 'src/app/models/team.model';
import { CPRSession } from 'src/app/models/video-interaction.model';
import { GyroscopeService, HistogramData } from 'src/app/services/gyroscope.service';
import { GameStateDetails, PlayerService } from 'src/app/services/player.service';



@Component({
  selector: 'app-player-game',
  templateUrl: './player-game.component.html',
  styleUrls: ['./player-game.component.css']
})
export class PlayerGameComponent implements OnInit, OnDestroy {

  get game(): Game {
    return this.playerService.game;
  }

  private _gameState: GameStateDetails = {
    id: '',
    status: '',
    lastTimestamp: 0,
    teams: [],
  };
  get gameState(): GameStateDetails {
    return this._gameState;
  }

  FetchState = FetchState;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly playerService: PlayerService,
    private readonly gyroService: GyroscopeService,
  ) {
  }

  @ViewChild('backgroundVideo', { static: true }) backgroundVideo!: ElementRef;
  private wakeLock: any = null;

  async requestWakeLock() {
    // Check if Wake Lock API is supported
    if ('wakeLock' in navigator) {
      try {
        this.wakeLock = await (navigator as any).wakeLock.request('screen');
      } catch (err) {
        console.error(`Failed to acquire wake lock: ${err}`);
        this.startVideoFallback(); // Fallback to video if API fails
      }
    } else {
      this.startVideoFallback(); // Fallback to video if API not available
    }
  }

  releaseWakeLock() {
    // Release wake lock if possible
    if (this.wakeLock) {
      this.wakeLock.release().then(() => {
        this.wakeLock = null;
      });
    }
    // Stop video fallback if running
    this.stopVideoFallback();
  }

  private startVideoFallback() {
    const videoElement = this.backgroundVideo.nativeElement as HTMLVideoElement;
    if (!!videoElement) {
      videoElement.play();
    }
  }

  private stopVideoFallback() {
    const videoElement = this.backgroundVideo.nativeElement as HTMLVideoElement;
    if (!!videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.releaseWakeLock();
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.requestWakeLock();
    console.log('GameComponent ngOnInit');
    console.log('GameComponent route', this.route);

    // Get the game ID from the route
    const gameId = this.route.snapshot.paramMap.get('gameId');

    // Join the game
    if (!!gameId) {
      await this.playerService.joinGame(gameId);
    }

    // Subscribe to the game data
    this.playerService.subscribeToGameState(gameId)
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: (game) => {
        this._gameState = game;
        this.setIsActiveCprSession();
      },
      error: (error) => {
        console.error(error);
      }
    });

    this.gyroService.startListening();

    this.gyroService.getMotionDataStream().pipe(
      takeUntil(this.destroy$),
    ).subscribe((gyroscopeData) => {
      this.gyroData = gyroscopeData;
    });

    this.gyroService.histogramDataStream$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((histogram) => {
      this.histogram = histogram;

      try {
        if (!!this.game?.id && !!this.playerService.playerId) {
          let player = this.game.teams.flatMap(t => t.players).find(p => p.id === this.playerService.playerId);
          if (!player) {
            return;
          }
          this.playerService.updatePlayerGyroAndOrientation(this.game.id, player.name, histogram);
        }
      }
      catch (error) {
        console.error('Error updating player gyro and orientation', error);
      }
    });
  }

  isActiveCprSession: boolean = false;
  private setIsActiveCprSession() {
    if (!this.game || !this.game.interactions || !this.player || !this.gameState) {
      this.isActiveCprSession = false;
      return;
    }
    let cprInteractions = (this.game?.interactions || []).filter(i => ((i as any) instanceof CPRSession)) as CPRSession[];
    // see if the gameStatus current timestamp falls within the start and end timestamps of the CPR session
    this.isActiveCprSession = (cprInteractions || []).some(i => {
      if (`${i.groupNumber}` !== this.player?.name[0]) {
        return false;
      }
      if (!i.markers || i.markers.length === 0) {
        return false;
      }
      let start = i.markers[0].timestamp || 0;
      let end = i.markers[i.markers.length - 1].timestamp || 0;
      // let start = i.markers.find(m => m.type === 'START')?.timestamp || 0;
      // let end = i.markers.find(m => m.type === 'END')?.timestamp || 0;
      return this.gameState.lastTimestamp >= start && this.gameState.lastTimestamp <= end;
    });
    if (this.isActiveCprSession) {
      console.log('CPR Session is active');
    }
    else {
      console.log('CPR Session is not active');
    }
    console.log('cprInteractions', cprInteractions);
    // this.isActiveCprSession = cprInteractions.some(i => `${i.groupNumber}` === this.player?.name[0]);
  }

  get speedIndicator(): string {
    return this.histogram?.speedIndicator || 'None';
  }

  get depthIndicator(): string {
    return this.histogram?.depthIndicator || 'None';
  }

  initializeChartData(): void {
    this.histogramBarChartData = {
        labels: this.histogram?.histogramLabels || [],
        datasets: [
            { data: this.histogram?.histogramData || [], label: 'Acceleration Distribution' }
        ]
    };

    this.percentileChartData = {
      labels: this.histogram?.percentileLabels || [],
      datasets: [
          { data: this.histogram?.percentileValues || [], label: 'Acceleration Percentiles', fill: false }
      ]
    };

    this.percentileChartType = 'line';  // Or 'scatter'

    // this.fftChartData = {
    //   labels: this.histogram?.fftLabels || [],
    //   datasets: [
    //       { data: this.histogram?.fftMagnitudes || [], label: 'FFT', fill: false }
    //   ]
    // };
  }


  get jsonHistogram(): string {
    return this.histogram?.jsonHistogram;
  }

  histogram: HistogramData = null;
  public histogramBarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true
      }
    }
  };
  public histogramBarChartType: ChartType = 'bar';
  public histogramBarChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      // { data: [], label: 'CPR Compression Depth' }
      { data: [], label: 'Acceleration Distribution' }
    ]
  };

  public percentileChartType: ChartType = 'line';  // Or 'scatter'
  public percentileChartData: ChartData<'line' | 'scatter'> = {
      labels: [],  // Initialize with empty labels
      datasets: [
          { data: [], label: 'Acceleration Percentiles', fill: false, borderColor: 'blue', pointBackgroundColor: 'blue' }
      ]
  };
  public percentileChartOptions: any = {
      responsive: true,
      scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
      },
      // Additional options as needed
  };

  // // FFT Chart
  // public fftChartType: ChartType = 'line';  // Or 'scatter'
  // public fftChartData: ChartData<'line' | 'scatter'> = {
  //     labels: [],  // Initialize with empty labels
  //     datasets: [
  //         { data: [], label: 'FFT', fill: false, borderColor: 'blue', pointBackgroundColor: 'blue' }
  //     ]
  // };
  // public fftChartOptions: any = {
  //     responsive: true,
  //     scales: {
  //         yAxes: [{
  //             ticks: {
  //                 beginAtZero: true
  //             }
  //         }]
  //     },
  //     // Additional options as needed
  // };


  depthStats: { min: number, max: number, duration: number, averageFrequency: number } = { min: 0, max: 0, duration: 0, averageFrequency: 0 };
  gyroData: any = null;
  get jsonGyroData() {
    return JSON.stringify(this.gyroData, null, 2);
  }
  gyroState: FetchState = FetchState.NONE;
  gyroError: string | null = null;
  motionPermission = null;
  // orientationPermission = null;

  async startGyro() {
    this.gyroState = FetchState.LOADING;
    try {
      this.motionPermission = await this.gyroService.requestMotionPermission();
      console.log('motionPermission', this.motionPermission);
      // this.orientationPermission = await this.gyroService.requestOrientationPermission();
      // console.log('orientationPermission', this.orientationPermission);
      if (this.motionPermission) {
        this.gyroState = FetchState.LOADED_ALL;
        this.gyroService.startListening();
      }
      else {
        this.gyroError = 'Permission denied';
        this.gyroState = FetchState.ERROR;
      }
    }
    catch (error) {
      this.gyroError = error as string;
      this.gyroState = FetchState.ERROR;
    }
  }

  joinGame(): void {
    if (this.motionPermission) {
      this.gyroService.startListening();
    }
  }

  get player() {
    if (!this.playerService.playerId || !this.game?.id) {
      return null;
    }
    const result = this.game.teams.flatMap(t => t.players).find(p => p.id === this.playerService.playerId) || null;
    return result;
  }

  get team(): Team | null {
    if (!this.playerService.playerId) {
      return null;
    }
    if (!this.game?.id) {
      return null;
    }

    return this.game.teams.find(t => t.players.some(p => p.id === this.playerService.playerId)) || null;
  }

  get question(): Question | undefined {
    if (!this.game?.id) {
      return undefined;
    }
    return this.game.currentQuestion;
  }
}
