<div class="flex flex-col justify-center items-center w-[14vw] gap-0">
  <div class="text-6xl font-bold"
    *ngIf="isInPreliminaryCprSession"
  [ngClass]="{
    'text-green-500': playerIsConnected,
    'text-white': !playerIsConnected
  }"
  >{{player?.name}}</div>
  <!-- <app-speedometer [percentage]="subscribedToPlayerData ? (depthPercent / 100.0) : 0.0" [rate]="subscribedToPlayerData ? histogram?.duration : 0.0" class="w-full"></app-speedometer> -->
  <!-- <app-speedometer [percentage]="subscribedToPlayerData ? (depthPercent / 100.0) : 0.0" [rate]="subscribedToPlayerData ? duration : 0.0" class="w-full"></app-speedometer> -->
  <app-speedometer [percentage]="(depthPercent / 100.0)" [rate]="duration" class="w-full"></app-speedometer>
  <!-- *ngIf="!isInPreliminaryCprSession" -->
  <!-- 'text-green-500': playerIsConnected, -->
  <div class="text-6xl font-bold"
  [ngClass]="{
  'text-gray-500': isInPreliminaryCprSession,
  'text-white': !isInPreliminaryCprSession,
  'bg-green-600': (60000 / duration) > 100 && (60000 / duration) < 120,
  }"
  >{{duration === 0.0 ? '0' : (60000 / duration).toFixed(0)}}</div>
  <!-- 'bg-green-600': subscribedToPlayerData && (60000 / (subscribedToPlayerData ? duration : 0.0)) > 100 && (60000 / (subscribedToPlayerData ? duration : 0.0)) < 120, -->
  <!-- 'bg-green-600': subscribedToPlayerData && (60000 / (subscribedToPlayerData ? histogram?.duration : 0.0)) > 100 && (60000 / (subscribedToPlayerData ? histogram?.duration : 0.0)) < 120, -->
</div>

<!--
<div class="flex flex-col justify-center items-center rounded-md transition-opacity duration-300 ease-in-out"
[ngStyle]="{
  'opacity': opacity
}"
[ngClass]="{
  'no-feedback': !this.histogram?.speedIndicator,
  'too-fast': this.histogram?.speedIndicator === 'Too Fast',
  'too-slow': this.histogram?.speedIndicator === 'Too Slow',
  'good': this.histogram?.speedIndicator === 'Good',
}">
<! -- 'background-color': player.color,
'border-color': player.color, -- >
<! -- 'good': this.histogram?.speedIndicator === 'Good' && this.histogram?.depthIndicator === 'Good', -- >
<! -- 'too-shallow': this.histogram?.depthIndicator === 'Too Shallow', -- >
<! -- <div class="w-full text-center bg-black border border-white m-2 p-2"><span class="text-center text-white font-bold font-['Arial'] uppercase w-full leading-[30px] tracking-widest">PLAYER<br/></span><span class="text-center text-opacity-80 font-normal font-scoreboard team-transition text-yellow-400 tracking-[4px] leading-tight">15</span></div> -- >
  <div class="flex flex-col justify-center items-center m-2 ">
    <! -- <div class="flex flex-col justify-center items-center">
      <div class="text-xl">{{ speedIndicator }}</div>
      <div class="text-xl">{{ depthIndicator }}</div>
    </div> -- >
    <app-feedback-indicator *ngIf="this.histogram?.speedIndicator !== 'Too Fast' || true" [fillLevel]="depthPercent" [rate]="histogram?.duration" [movementDirection]="'vertical'" class="w-[6vw] h-[24vw]"></app-feedback-indicator>
    <! -- <div *ngIf="this.histogram?.speedIndicator !== 'Too Fast' || true" class="player-number opacity-50">{{ player.name }}</div> -- >



    <! -- <app-feedback-indicator [fillLevel]="depthPercent" [rate]="histogram?.duration" [movementDirection]="'horizontal'" class="h-[6vw] w-[16vw]"></app-feedback-indicator> -- >
    <! -- <pre>{{jsonHistogram}}</pre> -- >

    <div *ngIf="this.histogram?.speedIndicator === 'Too Fast' && false" class="too-fast-indicator-container">
      <! -- <div *ngFor="let square of squares" [ngStyle]="{
        'height': square.height,
        'top': square.top,
        'background-color': square.color === 'bg-red-500' ? '#ef4444' : '#000000'
      }" class="absolute w-full"></div> -- >
      <! -- <div *ngFor="let square of squares; let i = index" [ngClass]="square.color" [ngStyle]="{
        'height': square.height,
        'top': square.top
      }" class="fibonacci-stripe absolute w-full"></div> -- >
                  <span class="flashing-text">X</span>
    </div>
  </div>
</div>
-->
