import { Component, Input } from '@angular/core';
import { GameController } from 'src/app/controllers/game.controller';
import { CPRCompleteSession, Question } from 'src/app/models/question.model';
import { Team } from 'src/app/models/team.model';
import { CPRSession, FinalResultsInteraction, ResultsInteraction, SummaryInteraction, TeamScoreInteraction, VideoMarkerType } from 'src/app/models/video-interaction.model';

export enum DisplayState {
  SCOREBOARD = 'SCOREBOARD',
  ANSWERING = 'ANSWERING',
  ANSWERED = 'ANSWERED',
  FINAL_ANSWERED = 'FINAL_ANSWERED',
  RESULTS = 'RESULTS',
  FINAL_RESULTS = 'FINAL_RESULTS',
}

enum ScoreType {
  ALL_PLAYERS_ONLY = 'ALL_PLAYERS_ONLY',
  ACTIVE_PLAYERS_ONLY = 'ACTIVE_PLAYERS_ONLY',
  NON_ACTIVE_PLAYERS_ONLY = 'NON_ACTIVE_PLAYERS_ONLY',
  TEAM_ONLY = 'TEAM_ONLY',
  FINAL_RESULTS = 'FINAL_RESULTS',
}


@Component({
  selector: 'app-team-score',
  templateUrl: './team-score.component.html',
  styleUrls: ['./team-score.component.css']
})
export class TeamScoreComponent {
  private _team: Team;
  @Input() set team(value: Team) {
    this._team = value;
    this.buildContainerClass();
  }
  get team(): Team {
    return this._team;
  }
  @Input() teamIndex: number = -1;

  private _containerDims: {width: number, height: number};
  @Input() set containerDims(value: {width: number, height: number}) {
    this._containerDims = value;
    this.buildContainerClass();
  }

  get name(): string {
    // The current team name minus "Team " prefix (if present)
    return this.team?.name?.replace('Team ', '') || '';
  }

  DisplayState = DisplayState;
  ScoreType = ScoreType;

  constructor(
    protected gameController: GameController,
  ) {

  }

  get displayState(): DisplayState {
    const question = this.currentQuestions[0] || null;
    if (!!question && !(question instanceof CPRCompleteSession)) {
      const teamAnswering = this.gameController.teamAnswering || this.gameController.game?.currentTeam || null;
      if (teamAnswering?.id === this._team?.id) {
        return DisplayState.ANSWERING;
      }
    }
    if (this.currentFinalResults.length > 0 || this.isInFinalResults) {
      return DisplayState.FINAL_RESULTS;
    }
    if (this.currentResults.length > 0) {
      return DisplayState.RESULTS;
    }
    if (this.currentTeamScores.length > 0) {
      const lowerTeamName = this._team?.name.toLowerCase();
      if (this.currentTeamScores.some(tsi => lowerTeamName.indexOf(tsi.teamName.toLowerCase()) >= 0)) {
        return DisplayState.FINAL_RESULTS;
      }
    }
    return DisplayState.SCOREBOARD;
  }

  get teamScore(): number {
    if (!this.team) {
      return 0;
    }
    // if in an active cpr session, then don't include scores from that session (meaning players that are part of that session)
    const currentCprSession = this.gameController?.currentCprSessions[0];
    if (!!currentCprSession) {
      // if the timestamp is past the FINAL_RESULTS timestamp, then include all scores
      if (this.isInFinalResults) {
        return this._calculateTeamScore(ScoreType.ACTIVE_PLAYERS_ONLY);
      }
      else {
        // else only include scores from that session
        return this.team.score + this._calculateTeamScore(ScoreType.NON_ACTIVE_PLAYERS_ONLY);
      }
    }
    return this._calculateTeamScore(ScoreType.FINAL_RESULTS);
  }

  private _calculateTeamScore(scoreType: ScoreType = ScoreType.FINAL_RESULTS): number {
    if (!this.team) {
      return 0;
    }

    // if in an active cpr session, then don't include scores from that session (meaning players that are part of that session)
    let resultScore = 0;

    let currentCprSession = this.gameController?.currentCprSessions[0];

    switch (scoreType) {
      case ScoreType.ACTIVE_PLAYERS_ONLY:
        if (!!currentCprSession) {
          const activePlayers = (this.team.players || []).filter(p => p.name.length > 0 && p.name[0] === `${currentCprSession.groupNumber}`);
          if (activePlayers.length === 0) {
            resultScore = 0;
          }
          else {
            resultScore = (activePlayers.reduce((acc, player) => {
              return acc + (player.score || player.finalResults?.overallScore || 0);
            }, 0) / activePlayers.length) || 0;
          }
        }
        break;
      case ScoreType.NON_ACTIVE_PLAYERS_ONLY:
        if (!!currentCprSession) {
          const inactivePlayers = (this.team.players || []).filter(p => p.name.length > 0 && p.name[0] !== `${currentCprSession.groupNumber}`);
          if (inactivePlayers.length === 0) {
            resultScore = 0;
          }
          else {
            resultScore = (inactivePlayers.reduce((acc, player) => {
              return acc + (player.score || player.finalResults?.overallScore || 0);
            }, 0) / inactivePlayers.length) || 0;
          }
        }
        else {
          if (this.team.players.length === 0) {
            resultScore = 0;
          }
          else {
            resultScore = this.team.players.reduce((acc, player) => acc + (player.score || player.finalResults?.overallScore || 0), 0) / this.team.players.length;
          }
        }
        break;
      case ScoreType.ALL_PLAYERS_ONLY:
        if (this.team.players.length === 0) {
          resultScore = 0;
        }
        else {
          resultScore = this.team.players.reduce((acc, player) => acc + (player.score || player.finalResults?.overallScore || 0), 0 / this.team.players.length) || 0;
        }
        break;
      case ScoreType.FINAL_RESULTS:
        if (this.team.players.length === 0) {
          resultScore = this.team.score;
        }
        else {
          resultScore = this.team.score + this.team.players.reduce((acc, player) => acc + (player.finalResults?.overallScore || 0) / this.team.players.length, 0);
        }
        break;
      case ScoreType.TEAM_ONLY:
        resultScore = this.team.score;
        break;

    }
    resultScore = Math.round(resultScore);

    return resultScore;
  }

  // returns the total score of the team, including the scores of all players not in the current cpr session
  get scoreNonActiveCprSession(): number {
    return this._calculateTeamScore(ScoreType.NON_ACTIVE_PLAYERS_ONLY);
  }

  // returns the total score of all players in the current cpr session
  get scoreActiveCprSessionOnly(): number {
    return this._calculateTeamScore(ScoreType.ACTIVE_PLAYERS_ONLY);
  }

  // returns the total score of the team, including the scores of all players (even ones in the current cpr session)
  get scoreFinalResults(): number {
    return this._calculateTeamScore(ScoreType.FINAL_RESULTS);
  }

  // returns the base score of the team (excluding the scores of all players)
  get scoreTeamOnly(): number {
    return this._calculateTeamScore(ScoreType.TEAM_ONLY);
  }

  // returns the total score of all the players only
  get scoreAllPlayers(): number {
    return this._calculateTeamScore(ScoreType.ALL_PLAYERS_ONLY);
  }


  get currentQuestions(): Question[] {
    return this.gameController.currentQuestions;
  }
  get currentCprSessions(): CPRSession[] {
    return this.gameController.currentCprSessions;
  }
  get currentResults(): ResultsInteraction[] {
    return this.gameController.currentResults;
  }
  get currentFinalResults(): FinalResultsInteraction[] {
    return this.gameController.currentFinalResults;
  }
  get currentTeamScores(): TeamScoreInteraction[] {
    return this.gameController.currentTeamScores;
  }
  get currentSummaries(): SummaryInteraction[] {
    return this.gameController.currentSummaries;
  }

  get isInFinalResults(): boolean {
    const currentCprSession = this.gameController?.currentCprSessions[0];
    if (!!currentCprSession) {
      // if the timestamp is past the FINAL_RESULTS timestamp, then include all scores
      const finalResultsMarker = currentCprSession.markers.find(m => m.type === VideoMarkerType.FINAL_RESULTS);
      if (!finalResultsMarker || this.gameController?.game.currentTimestamp >= finalResultsMarker.timestamp) {
        return true;
      }
    }
    return false;
  }


  containerStyle: any = {};
  containerClass: string = '';
  scoreStyle: any = {
    'font-size': 'clamp(1.8rem, 2vw, 80px)'
  };
  teamStyle: any = {
    'font-size': 'clamp(0.65rem, 1vw, 25px)'
  };
  nameStyle: any = {
    'font-size': 'clamp(0.75rem, 1.25vw, 1.875rem)'
  };

  private buildContainerClass() {
    let _displayState = this.displayState;
    let _containerClass = ``;
    let _containerStyle = {};
    let _scoreStyle = {};
    let _teamStyle = {};
    let _nameStyle = {};

    if (!!this._containerDims?.width && !!this._containerDims?.height) {
      _containerClass = `border-opacity-0`;
      _containerStyle = {
        'width': `${(this._containerDims.width*0.1152239583).toFixed(0)}px`,
        'height': `${(this._containerDims.height*0.1823240741).toFixed(0)}px`,
      };
      _scoreStyle = {
        // 'font-size': 'clamp(1.8rem, 2vw, 80px)'
        'font-size': `${(this._containerDims.width*0.04).toFixed(1)}px`
      };
      _teamStyle = {
        'font-size': `${(this._containerDims.width*0.01125).toFixed(1)}px`
      };
      _nameStyle = {
        'font-size': `${(this._containerDims.width*0.015).toFixed(1)}px`
      };

      if (_displayState === DisplayState.FINAL_RESULTS) {
        _containerClass = 'border-opacity-75 bg-black bg-opacity-70';
        _containerStyle = {
          'margin-top': '0rem',
          'width': `${(this._containerDims.width/4).toFixed(0)}px`,
          'height': `${(this._containerDims.height/2).toFixed(0)}px`,
        };
        if (this.teamIndex === 0) {
          _containerStyle['margin-left'] = '9rem';
          _containerStyle['margin-right'] = '0';
        }
        else if (this.teamIndex === 1) {
          _containerStyle['margin-left'] = '0';
          _containerStyle['margin-right'] = '9rem';
        }
        _scoreStyle = {
          'font-size': `${(this._containerDims.width*0.12).toFixed(1)}px`
        };
        _teamStyle = {
          'font-size': `${(this._containerDims.width*0.03375).toFixed(1)}px`
        };
        _nameStyle = {
          'font-size': `${(this._containerDims.width*0.045).toFixed(1)}px`
        };
      }
      else if (_displayState === DisplayState.ANSWERING) {
        _containerClass = `border-opacity-75`;
        _containerStyle = {
          'width': `${(this._containerDims.width*0.15625).toFixed(0)}px`,
          'height': `${(this._containerDims.height*0.287037037).toFixed(0)}px`,
        };
        _scoreStyle = {
          'font-size': `${(this._containerDims.width*0.069375).toFixed(1)}px`
          // 'font-size': 'clamp(2rem, 6vw, 5.6vw)'
        };
        _teamStyle = {
          'font-size': `${(this._containerDims.width*0.01875).toFixed(1)}px`
        };
        _nameStyle = {
          'font-size': `${(this._containerDims.width*0.025).toFixed(1)}px`
        };
      }
    }
    else {
      _containerClass = `border-opacity-0`;
      _containerStyle = {
        'width': `calc(100% / 7)`,
        'height': `calc(100% / 6)`,
      };
      _scoreStyle = {
        'font-size': `clamp(1.8rem, 2vw, 80px)`
      };
      _teamStyle = {
        'font-size': `clamp(0.65rem, 1vw, 25px)`
      };
      _nameStyle = {
        'font-size': `clamp(0.75rem, 1.25vw, 1.875rem)`
      };

      if (_displayState === DisplayState.FINAL_RESULTS) {
        _containerClass = 'border-opacity-75';
        _containerStyle = {
          'margin-top': '2.5rem',
          'width': `calc(100% / 4)`,
          'height': `calc(100% / 2)`,
        };
        if (this.teamIndex === 0) {
          _containerStyle['margin-left'] = '9rem';
          _containerStyle['margin-right'] = '0';
        }
        else if (this.teamIndex === 1) {
          _containerStyle['margin-left'] = '0';
          _containerStyle['margin-right'] = '9rem';
        }
        _scoreStyle = {
          'font-size': `clamp(2rem, 9vw, 8vw)`
        };
        _teamStyle = {
          'font-size': `clamp(0.75rem, 1.5vw, 28.8px)`
        };
        _nameStyle = {
          'font-size': `clamp(1rem, 2vw, 40px)`
        };
      }
      else if (_displayState === DisplayState.ANSWERING) {
        _containerClass = `border-opacity-75`;
        _containerStyle = {
          'width': `calc(100% / 6)`,
          'height': `calc(100% / 4)`,
        };
        _scoreStyle = {
          'font-size': `clamp(2rem, 6vw, 5.6vw)`
        };
        _teamStyle = {
          'font-size': `clamp(0.9rem, 1.75vw, 33.76px)`
        };
        _nameStyle = {
          'font-size': `clamp(1rem, 1.85vw, 40px)`
        };
      }
    }

    this.containerStyle = _containerStyle;
    this.containerClass = _containerClass;
    this.scoreStyle = _scoreStyle;
    this.teamStyle = _teamStyle;
    this.nameStyle = _nameStyle;
  }

}
