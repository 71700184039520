import { Component, Input, OnDestroy } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { HistogramData, PercentileData } from 'src/app/services/gyroscope.service';

@Component({
  selector: 'app-interactive-results',
  templateUrl: './interactive-results.component.html',
  styleUrls: ['./interactive-results.component.css']
})
export class InteractiveResultsComponent implements OnDestroy {
  private _histogram: HistogramData;
  @Input()
  set histogram(histogram: HistogramData) {
    this._histogram = histogram;
    this.percentiles = histogram?.percentiles  ||  [];
    // this.initializeChartData();
  }
  get histogram(): HistogramData {
    return this._histogram;
  }

  percentiles: Array<PercentileData> = [];

  constructor() { }

  ngOnDestroy(): void {
    console.log('InteractiveResultsComponent ngOnDestroy');
  }

  get speedIndicator(): string {
    return this.histogram?.speedIndicator  ||  'None';
  }

  get depthIndicator(): string {
    return this.histogram?.depthIndicator  ||  'None';
  }

  get dataPoints(): any[] {
    return this.histogram?.histogramData?.map((value, index) => {
      return {
        timestamp: index,
        value: value
      };
    });
  }

  private _histogramLabels: string[] = [];
  private _histogramData: number[] = [];
  initializeChartData(): void {
    this.updateHistogramChartData();
    this.updatePercentileChartData();
  }

  private _historicalCounts: number[] = [];
  get count(): number {
    if (this.showHistory) {
      // Return average of historical counts
      let sum = 0;
      for(let count of this._historicalCounts) {
        sum += count;
      }
      return sum / this._historicalCounts.length;
    }
    return this.histogram?.count || 0;
  }
  private _historicalDurations: number[] = [];
  get duration(): number {
    if (this.showHistory) {
      // Return average of historical durations
      let sum = 0;
      for(let duration of this._historicalDurations) {
        sum += duration;
      }
      return sum / this._historicalDurations.length;
    }
    return this.histogram?.duration || 0;
  }

  private _historicalSampleFrequencies: number[] = [];
  get sampleFrequency(): number {
    if (this.showHistory) {
      // Return average of historical frequencies
      let sum = 0;
      for(let frequency of this._historicalSampleFrequencies) {
        sum += frequency;
      }
      return sum / this._historicalSampleFrequencies.length;
    }
    return this.histogram?.sampleFrequency || 0;
  }
  private _historicalAccelDistances: number[] = [];
  get accelDistance(): number {
    if (this.showHistory) {
      // Return average of historical accel distances
      let sum = 0;
      for(let distance of this._historicalAccelDistances) {
        sum += distance;
      }
      return sum / this._historicalAccelDistances.length;
    }
    return this.histogram?.accelDistance || 0;
  }


  private _percentileLabels: string[] = [];
  get percentileLabels(): string[] {
    return this._percentileLabels;
  }
  private _percentileValues: number[] = [];
  private _historicalPercentileValues: Map<string, number[]> = new Map<string, number[]>();
  showHistory = true;
  toggleHistory(): void {
    this.showHistory = !this.showHistory;
  }
  updatePercentileChartData(): void {
    // Only update the chart data if the histogram has changed
    let percentileLabels: string[] = this.histogram?.percentileLabels || [];
    let percentileValues: number[] = this.histogram?.percentileValues || [];

    let percentileLabelsChanged = this._percentileLabels.length !== percentileLabels.length;
    if (!percentileLabelsChanged) {
      for(let i = 0; i < percentileLabels.length; i++) {
        if (this._percentileLabels[i] !== percentileLabels[i]) {
          percentileLabelsChanged = true;
          break;
        }
      }
    }
    let percentileValuesChanged = this._percentileValues.length !== percentileValues.length;
    if (!percentileValuesChanged) {
      for(let i = 0; i < percentileValues.length; i++) {
        if (this._percentileValues[i] !== percentileValues[i]) {
          percentileValuesChanged = true;
          break;
        }
      }
    }

    if (percentileValuesChanged || percentileLabelsChanged) {
      this._percentileLabels = percentileLabels;
      this._percentileValues = percentileValues;
      this._historicalCounts.push(this.histogram?.count || 0);
      this._historicalDurations.push(this.histogram?.duration || 0);
      this._historicalSampleFrequencies.push(this.histogram?.sampleFrequency || 0);
      this._historicalAccelDistances.push(this.histogram?.accelDistance || 0);

      // Add to the historical values
      for(let label of this._percentileLabels) {
        let historicalValues = this._historicalPercentileValues.get(label);
        if (!historicalValues) {
          historicalValues = [];
          this._historicalPercentileValues.set(label, historicalValues);
        }
        historicalValues.push(this._percentileValues[this._percentileLabels.indexOf(label)]);
      }

      this.percentileChartData = {
        labels: this._percentileLabels || [],
        datasets: [
            { data: this.getPercentileData(), label: 'Acceleration Percentiles', fill: false }
        ]
      };
      // this.percentileChartType = 'line';  // Or 'scatter'
    }

    // this.fftChartData = {
    //   labels: this.histogram?.fftLabels || [],
    //   datasets: [
    //       { data: this.histogram?.fftMagnitudes || [], label: 'FFT', fill: false }
    //   ]
    // };
  }

  getPercentileData(): number[] {
    let data: number[] = this._percentileValues;
    if (this.showHistory) {
      data = [];
      for(let index = 0; index < this._percentileLabels.length; index++) {
        let label = this._percentileLabels[index];
        // Sum up the historical values at this index
        let sum = 0;
        let count = 0;
        for(let value of this._historicalPercentileValues.get(label) || []) {
          sum += value;
          count++;
        }
        data.push(sum / count);
      }
    }
    return data;
  }

  updateHistogramChartData(): void {
    // Only update the chart data if the histogram has changed
    let histogramLabels: string[] = this.histogram?.histogramLabels || [];
    let histogramData: number[] = this.histogram?.histogramData || [];

    let histogramLabelsChanged = this._histogramLabels.length !== histogramLabels.length;
    if (!histogramLabelsChanged) {
      for(let i = 0; i < histogramLabels.length; i++) {
        if (this._histogramLabels[i] !== histogramLabels[i]) {
          histogramLabelsChanged = true;
          break;
        }
      }
    }

    let histogramDataChanged = this._histogramData.length !== histogramData.length;
    if (!histogramDataChanged) {
      for(let i = 0; i < histogramData.length; i++) {
        if (this._histogramData[i] !== histogramData[i]) {
          histogramDataChanged = true;
          break;
        }
      }
    }

    if (histogramDataChanged || histogramLabelsChanged) {
      this._histogramLabels = histogramLabels;
      this._histogramData = histogramData;

      this.histogramBarChartData = {
          labels: this._histogramLabels || [],
          datasets: [
              { data: this._histogramData || [], label: 'Acceleration Distribution' }
          ]
      };
    }
  }


  get jsonHistogram(): string {
    return this.histogram?.jsonHistogram;
  }

  // histogram: HistogramData = null;
  public histogramBarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true
      }
    }
  };
  public histogramBarChartType: ChartType = 'bar';
  public histogramBarChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      // { data: [], label: 'CPR Compression Depth' }
      { data: [], label: 'Acceleration Distribution' }
    ]
  };

  public percentileChartType: ChartType = 'line';  // Or 'scatter'
  public percentileChartData: ChartData<'line' | 'scatter'> = {
      labels: [],  // Initialize with empty labels
      datasets: [
          { data: [], label: 'Acceleration Percentiles', fill: false, borderColor: 'blue', pointBackgroundColor: 'blue' }
      ]
  };
  public percentileChartOptions: ChartConfiguration['options'] = {
      // animation: true,
      animation: {
        delay: 50,
      },
      animations: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: false
        }
      },
      responsive: true,
      scales: {
          x: { beginAtZero: false },
          y: { beginAtZero: false }
      }
      // scales: {
      //     yAxes: [{
      //         ticks: {
      //             beginAtZero: true
      //         }
      //     }]
      // },
      // Additional options as needed
  };


}
