import { HistogramData } from "../services/gyroscope.service";

export class PlayerResults {
  constructor({compressionScore, depthScore, qualityScore, activePercentage, averageCompressionDepth, overallScore}: {
    compressionScore: number;
    depthScore: number;
    qualityScore: number;
    activePercentage: number;
    averageCompressionDepth: number;
    overallScore: number;
  }) {
    this.compressionScore = compressionScore;
    this.depthScore = depthScore;
    this.qualityScore = qualityScore;
    this.activePercentage = activePercentage;
    this.averageCompressionDepth = averageCompressionDepth;
    this.overallScore = overallScore;
  }

  compressionScore: number = 0;
  depthScore: number = 0;
  averageCompressionDepth: number = 0;
  qualityScore: number = 0;
  activePercentage: number = 0;
  overallScore: number = 0;
}

export class Player {

  constructor({id, name, score = 0, histogramData = null, results = null}: {id: string, name: string, score: number, histogramData?: any, results?: any}) {
    this.id = id;
    this.name = name;
    this.score = score;
    this.histogramData = histogramData ? new HistogramData(histogramData) : null;
    this.results = results ? new PlayerResults(results) : null;
  }

  overwrite(player: Player) {
    if (!player) {
      return;
    }
    if (!!player.id) {
      this.id = player.id;
    }
    if (!!player.name) {
      this.name = player.name;
    }
    if (!!player.score) {
      this.score = player.score;
    }
    if (!!player.histogramData) {
      this.histogramData = new HistogramData(player.histogramData);
    }
    if (!!player.results) {
      this.results = new PlayerResults(player.results);
    }
  }

  id: string;
  name: string;
  score: number;

  histogramData: HistogramData;
  results?: PlayerResults;
}

// Team model
export class Team {

  constructor({id, name, score = 0, players = []}: {id: string, name: string, score: number, players: Player[]}) {
    this.id = id;
    this.name = name;
    this.score = score;
    this.players = [];
    for(const player of players) {
      this.players.push(new Player(player));
    }
  }

  incrementScore(points: number): void {
    this.score += points;
  }

  overwrite(team: Team) {
    this.id = team.id;
    this.name = team.name;
    this.score = team.score;

    // Overwrite players, update any existing players, add new players, remove any players that don't exist anymore
    const newPlayers: Player[] = [];
    for (const player of (team.players || [])) {
      const existingPlayer = this.players.find(p => p.id === player.id);
      if (existingPlayer) {
        existingPlayer.name = player.name;
        newPlayers.push(existingPlayer);
      }
      else {
        newPlayers.push(new Player(player));
      }
    }
    this.players = newPlayers;
  }

  id: string;
  name: string;
  private _score: number;
  get score(): number {
    return this._score;
  }
  set score(score: number) {
    this._score = score;
  }
  players: Player[];

  get totalScore(): number {
    return Math.round(this.score || 0) + this.players.reduce((acc, player) => acc + (player.score || 0), 0);
  }
}


