import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HistogramData } from 'src/app/services/gyroscope.service';
import { PlayerFeedbackModel } from '../../team-interaction/player-interaction/player-interaction.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-speedometer',
  templateUrl: './speedometer.component.html',
  styleUrl: './speedometer.component.css'
})
export class SpeedometerComponent implements OnInit, OnDestroy {

  private _intervalTimer: any;
  private _playerFeedbackModel: PlayerFeedbackModel;
  @Input() set playerFeedbackModel(value: PlayerFeedbackModel) {
    this._playerFeedbackModel = value;
  }
  get playerFeedbackModel(): PlayerFeedbackModel {
    return this._playerFeedbackModel;
  }

  constructor() { }

  ngOnInit(): void {
    // start the interval timer
    this._intervalTimer = setInterval(() => {
      this.setFill((this.playerFeedbackModel?.depthPercent || 0.0) / 100.0);
      this.setSpeedometer(this.rateSigmoid(this.playerFeedbackModel?.duration || 0));
      this.setSpeedIndicator(this.playerFeedbackModel?.duration || 0);
    }, 250);
  }

  private _destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    if (!!this._intervalTimer) {
      clearInterval(this._intervalTimer);
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  private setSpeedIndicator(rate: number) {
    if (rate <= 0) {
      this.speedFillLeftColor = '#fff';
      this.leftOpacity = '0%';
      this.speedFillRightColor = '#fff';
      this.rightOpacity = '0%';
    }
    else if (rate < HistogramData.MIN_DURATION) {
      this.speedFillLeftColor = '#fff';
      this.leftOpacity = '0%';
      // this.speedFillRightColor = '#ff8080';
      this.speedFillRightColor = '#ffd880';
      this.rightOpacity = '100%';
    }
    else if (rate > HistogramData.MAX_DURATION) {
      this.speedFillLeftColor = '#ffd880';
      this.leftOpacity = '100%';
      this.speedFillRightColor = '#fff';
      this.rightOpacity = '0%';
    }
    else {
      this.speedFillLeftColor = '#00ff00';
      this.leftOpacity = '0%';
      this.speedFillRightColor = '#00ff00';
      this.rightOpacity = '0%';
    }
  }

  // timer to reset the speedometer percentage and rate to 0 after 2.5 seconds of inactivity
  private resetTimer: any;
  private resetTimeout: number = 2500;
  private resetSpeedometer() {
    if (this.resetTimer) {
      clearTimeout(this.resetTimer);
    }
    this.resetTimer = setTimeout(() => {
      this.setFill(0);
      this.setSpeedometer(0);
      this.setSpeedIndicator(0);
    }, this.resetTimeout);
  }

  speedFillLeftColor: string = '#fff';
  speedFillRightColor: string = '#fff';
  leftOpacity: string = '0%';
  rightOpacity: string = '100%';

  // @ViewChild('needle') needle: ElementRef;
  needleTransform: string = 'rotate(0 300 300)';

  static DESIRED_RATE_IN_Hz = 2.0;

  rateSigmoid(rate: number): number {
    // rate is in milliseconds, so convert to Hz
    rate = 1000 / rate;
    rate = rate - SpeedometerComponent.DESIRED_RATE_IN_Hz;
    return 1 / (1 + Math.exp(-rate));
    // return this.calculateAmplification(this.rate);
  }
  calculateAmplification(rate: number): number {
    const targetRate = 2; // Target rate in Hz
    const a = 4; // Chosen amplification factor, adjust based on experimentation
    const maxDeviationRate = 1.5; // One of the bounds (the other being 2.5Hz)

    // Calculate the maximum amplification (at 1.5Hz or 2.5Hz) for normalization
    const maxAmplification = a * Math.pow(maxDeviationRate - targetRate, 2);

    // Calculate the current amplification
    let amplification = a * Math.pow(rate - targetRate, 2);

    // Normalize amplification to the [0..100] range
    amplification = (amplification / maxAmplification) * 100;

    // Ensure the result is bounded between 0 and 100
    amplification = Math.min(Math.max(amplification, 0), 100);

    return amplification;
  }

  // setSpeedometer(percentage: number) {
  //   this.currentPercentage = percentage;
  //   const rotation = this.interpolateRotation(percentage);
  //   this.needleTransform = `rotate(${rotation} 0 100)`;
  // }

  // animateSpeedometer(targetPercentage: number) {
  //   const startPercentage = this.currentPercentage;
  //   const duration = 1000; // Animation duration in milliseconds
  //   const startTime = performance.now();
  //   const animate = (currentTime: number) => {
  //     const elapsed = currentTime - startTime;
  //     const progress = Math.min(elapsed / duration, 1);
  //     const currentPercentage = startPercentage + (targetPercentage - startPercentage) * progress;
  //     this.setNeedlePosition(currentPercentage);
  //     if (progress < 1) {
  //       requestAnimationFrame(animate);
  //     } else {
  //       this.currentPercentage = targetPercentage;
  //     }
  //   };
  //   requestAnimationFrame(animate);
  // }

  // setNeedlePosition(percentage: number) {
  //   const clampedPercentage = Math.max(0, Math.min(1, percentage));
  //   const rotation = this.interpolateRotation(clampedPercentage);
  //   const needleElement = this.needle.nativeElement;
  //   needleElement.setAttribute('transform', `rotate(${rotation} 300 300)`);
  // }

  interpolateRotation(percentage: number): number {
    // Assuming 0% corresponds to -90 degrees and 100% corresponds to 90 degrees
    // return -90 + (percentage * 180);
    return (percentage * 180);
  }




  // fillColor: string = '#00c8ff';
  fillColor: string = '#14ff00';

  pathData: string = 'm300.07,353.26c-10.761,0-21.326.87-31.634,2.546l23.956,88.098h15.216l23.962-88.119c-10.265-1.661-20.785-2.524-31.5-2.524Z';

  private startPoints: { x: number, y: number }[] = [
    { x: 292.392, y: 443.904 },
    { x: 307.608, y: 443.904 },
    { x: 307.608, y: 443.904 },
    { x: 292.392, y: 443.904 },
  ];

  private endPoints: { x: number, y: number }[] = [
    { x: 292.392, y: 443.904 },
    { x: 307.608, y: 443.904 },
    { x: 383.671, y: 165.625 },
    { x: 216.721, y: 165.625 },
  ];

  private currentPercentage: number = 0;
  private startColor: string = '#00c8ff'; // Color when empty
  private endColor: string = '#14ff00'; // Color when full

  animateFill(targetPercentage: number) {
    const startPercentage = this.currentPercentage;
    const duration = 1000; // Animation duration in milliseconds
    const startTime = performance.now();
    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const currentPercentage = startPercentage + (targetPercentage - startPercentage) * progress;
      this.setFill(currentPercentage);
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        this.currentPercentage = targetPercentage;
      }
    };
    requestAnimationFrame(animate);
  }

  setFill(percentage: number) {
    const clampedPercentage = Math.max(0, Math.min(1, percentage));
    // const rotation = this.interpolateRotation(clampedPercentage);
    // this.needleTransform = `rotate(${rotation} 300 300)`;
    // console.log('clampedPercentage', {clampedPercentage, percentage});
    this.pathData = this.interpolatePath(this.startPoints, this.endPoints, clampedPercentage);
    this.fillColor = this.interpolateColor(this.startColor, this.endColor, clampedPercentage);
  }

  setSpeedometer(percentage: number) {
    // this.currentPercentage = percentage;
    const rotation = this.interpolateRotation(percentage);
    this.needleTransform = `rotate(${rotation} 300 300)`;
  }

  interpolatePath(startPoints: { x: number, y: number }[], endPoints: { x: number, y: number }[], factor: number): string {
    const interpolatedPoints = startPoints.map((startPoint, i) => {
      const endPoint = endPoints[i];
      return {
        x: startPoint.x + factor * (endPoint.x - startPoint.x),
        y: startPoint.y + factor * (endPoint.y - startPoint.y),
      };
    });

    return this.pointsToPath(interpolatedPoints);
  }

  pointsToPath(points: { x: number, y: number }[]): string {
    return `M${points[0].x},${points[0].y} L${points[1].x},${points[1].y} L${points[2].x},${points[2].y} L${points[3].x},${points[3].y} Z`;
  }

  interpolateColor(startColor: string, endColor: string, factor: number): string {
    const startRgb = this.hexToRgb(startColor);
    const endRgb = this.hexToRgb(endColor);
    const interpolatedRgb = startRgb.map((startComponent, i) => {
      return Math.round(startComponent + factor * (endRgb[i] - startComponent));
    });
    return `rgb(${interpolatedRgb[0]}, ${interpolatedRgb[1]}, ${interpolatedRgb[2]})`;
  }

  hexToRgb(hex: string): number[] {
    hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [0, 0, 0];
  }

}
