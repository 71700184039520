import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { GameController } from 'src/app/controllers/game.controller';
import { Game } from 'src/app/models/game.model';
import { NavControlService } from 'src/app/services/nav-control.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy {


  constructor(
    private readonly route: ActivatedRoute,
    private readonly apollo: Apollo,
    private readonly playerService: PlayerService,
    private readonly gameController: GameController,
    private readonly navControlService: NavControlService,
  ) {
  }

  goToGame(): void {
    if (!!this.gameController?.game) {
      this.navControlService.goToInstructor(this.gameController.game.id);
    }
  }

  ngOnInit(): void {
    console.log('GameComponent ngOnInit');
    console.log('GameComponent route', this.route);

    const cachedGameId = localStorage.getItem('game_id');
    if (!!cachedGameId) {
      // Go to the game page
      this.gameController.loadGame(cachedGameId);
      return;
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get game(): Game {
    return this.gameController.game;
  }
}
