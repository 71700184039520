<!-- Gyro Data -->
<div class="flex flex-col justify-center items-center overflow-y-auto px-6 py-4 rounded-md bg-white bg-opacity-90 relative">
  <!-- <pre>{{jsonGyroData}}</pre> -->
  <div class="text-3xl">Gyro Data</div>
  <div class="flex flex-row justify-center items-center gap-2">
    <div class="text-xl"><span class="text-xs text-gray-500">SPEED:</span> {{ speedIndicator }}</div>
    <div class="text-xl"><span class="text-xs text-gray-500">DEPTH:</span> {{ depthIndicator }}</div>
  </div>
  <div class="flex flex-row justify-around items-center w-full my-6">
    <div class="flex flex-col justify-start items-start">
      <div>
        <span class="text-md text-grey-600">&#945;: </span>
        <span class="text-xl">{{ accelDistance.toFixed(2) }}</span>
      </div>
      <div>
        <span class="text-md text-grey-600">Period: </span>
        <span class="text-xl">{{ duration?.toFixed(2) }}ms</span>
      </div>
      <div>
        <span class="text-md text-grey-600">Count: </span>
        <span class="text-xl">{{ count?.toFixed(0) }}</span>
      </div>
      <div>
        <span class="text-md text-grey-600">Sample Rate: </span>
        <span class="text-xl">{{ sampleFrequency?.toFixed(2) }}Hz</span>
      </div>
      <div class="flex flex-col">
        <table>
          <tr *ngFor="let percentile of percentiles; index as i" class="flex flex-row justify-between items-center">
            <td class="text-sm text-grey-600">{{percentile.percentile}}:</td>
            <div class="text-md">{{percentile.value?.toFixed(2)}} m/s<sup>2</sup></div>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>
