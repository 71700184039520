<div class="content-container" (click)="closeMenu()">
  <div class="flex flex-row justify-start items-center w-full h-full overflow-x-auto gap-12">
    <div class="flex flex-col justify-start items-start gap-2 overflow-y-auto flex-none">
      <div *ngFor="let scene of scenes" class="scene" (click)="selectScene($event, scene)">
        <span [ngClass]="{'selected-scene': selectedScene?.name === scene.name, 'not-selected-scene': selectedScene?.name !== scene.name}">{{scene.name}}</span>
      </div>
    </div>
    <div class="flex-grow flex flex-col justify-start items-start gap-4 overflow-y-auto h-full">
      <div *ngFor="let interaction of selectedScene?.interactions; let i = index" class="interaction" (click)="jumpToInteraction($event, interaction)">
        <div class="flex flex-row gap-2 items-center">
          <div class="rounded-full border border-white px-2 aspect-square text-sm flex flex-col justify-center items-center">{{i + 1}}</div>
          <div *ngIf="!!asQuestion(interaction)" [innerHTML]="asQuestion(interaction).question | cleanText"></div>
          <div *ngIf="!!asCprSession(interaction)">CPR Session: Group {{asCprSession(interaction).groupNumber}}</div>
          <div *ngIf="!!asResults(interaction)">Results</div>
          <div *ngIf="!!asTeamScore(interaction)">Team Score {{asTeamScore(interaction)?.teamName}}</div>
          <div *ngIf="!!asFinalResults(interaction)">Final Results</div>
          <div *ngIf="!!asSummary(interaction)">Summary</div>
        </div>
        <!-- <div class="marker__line">{{formatTime(getInteractionMarker(interaction, VideoMarkerType.START)?.timestamp) }}
        </div> -->
      </div>
    </div>
  </div>
</div>
