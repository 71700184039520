import * as uuid from 'uuid';

export enum VideoMarkerType {
  START = 'START',
  FADE_IN = 'FADE_IN',
  FADE_OUT = 'FADE_OUT',
  TICKER_START = 'TICKER_START',
  TICKER_END = 'TICKER_END',
  PAUSE_POINT = 'PAUSE_POINT',
  COMPLETE_SESSION = 'COMPLETE_SESSION',
  SESSION_STOP = 'SESSION_STOP',
  END = 'END',
  JUMP_FROM = 'JUMP_FROM',
  JUMP_TO = 'JUMP_TO',
  FINAL_RESULTS = 'FINAL_RESULTS',
}

export function getVideoMarkerTypeFromString(type: string): VideoMarkerType {
  switch (type.toUpperCase().trim()) {
    case 'START':
      return VideoMarkerType.START;
    case 'FADE_IN':
    case 'FADEIN':
    case 'FADE IN':
      return VideoMarkerType.FADE_IN;
    case 'FADE_OUT':
    case 'FADEOUT':
    case 'FADE OUT':
      return VideoMarkerType.FADE_OUT;
    case 'TICKER_START':
    case 'TICKER START':
    case 'START TICKER':
    case 'STARTTICKER':
    case 'TICKER':
      return VideoMarkerType.TICKER_START;
    case 'TICKER_END':
    case 'TICKER END':
    case 'END TICKER':
    case 'ENDTICKER':
      return VideoMarkerType.TICKER_END;
    case 'PAUSE_POINT':
    case 'PAUSE POINT':
    case 'PAUSE':
      return VideoMarkerType.PAUSE_POINT;
    case 'SESSION_STOP':
    case 'SESSION STOP':
    case 'SESSIONSTOP':
    case 'STOP':
      return VideoMarkerType.SESSION_STOP;
    case 'END':
      return VideoMarkerType.END;
    case 'JUMP_FROM':
    case 'JUMP FROM':
    case 'JUMPFROM':
      return VideoMarkerType.JUMP_FROM;
    case 'JUMP_TO':
    case 'JUMP TO':
    case 'JUMPTO':
      return VideoMarkerType.JUMP_TO;
    default:
      throw new Error(`Invalid VideoMarkerType: ${type}`);
  }
}

export enum InteractionDisplayType {
  INFO = 'INFO',
  MULTIPLE_CHOICE_TEXT = 'MULTIPLE_CHOICE_TEXT',
  MULTIPLE_CHOICE_IMAGE = 'MULTIPLE_CHOICE_IMAGE',
  IMAGE_AED = 'IMAGE_AED',
  CPR_SESSION = 'CPR_SESSION',
  JUMP = 'JUMP',
  VIDEO = 'VIDEO',
  RESULTS = 'RESULTS',
  FINAL_RESULTS = 'FINAL_RESULTS',
  TEAM_SCORE = 'TEAM_SCORE',
  SUMMARY = 'SUMMARY',
}


export class VideoMarker {
  constructor({id, timestamp, offset, type, value}: {id: string, timestamp: number, offset: number, type: VideoMarkerType, value?: string}) {
    this.id = id;
    this.timestamp = timestamp;
    this.offset = offset;
    this.type = type;
    this.value = value;
  }

  id: string;
  timestamp: number;
  offset: number;
  type: VideoMarkerType;
  value?: string;
}



export interface VideoInteraction {
  get id(): string;
  get markers(): Array<VideoMarker>;
  // set markers(markers: Array<VideoMarker>);
  setMarkers(markers: Array<VideoMarker>);
    // get timestamp(): number | null;
  // get teamIndex(): number | null;

  get scene(): string;
  get interactionComplete(): boolean;
  set interactionComplete(complete: boolean);

  // checkAnswer(answer: string): boolean;

}

// export interface VideoInteraction {
//   get id(): string;
//   get markers(): Array<VideoMarker>;
//   get timestamp(): number | null;
//   get teamIndex(): number | null;
//   get pointsValue(): number;

//   checkAnswer(answer: string): boolean;

// }

export class BaseInteraction implements VideoInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.INFO, scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, scene?: string}) {
    this.id = id || uuid.v4();
    this.setMarkers((markers || []));
    this.displayType = displayType;
    this.scene = scene;
  }

  id: string;
  private _markers?: Array<VideoMarker>;
  get markers(): Array<VideoMarker> {
    return this._markers || [];
  }

  setMarkers(markers: Array<VideoMarker> | Array<any>) {
    markers = (markers || []).map(m => new VideoMarker(m))
    // Sort the markers by timestamp
    try {
      (markers || []).sort((a, b) => {
        if (!a.timestamp) {
          return -1;
        }
        if (!b.timestamp) {
          return 1;
        }
        return (a.timestamp - b.timestamp);
      });
      this._markers = markers;
    }
    catch (error) {
      console.warn('Error setting markers', error);
    }
  }
  displayType: InteractionDisplayType = InteractionDisplayType.MULTIPLE_CHOICE_TEXT;
  scene: string;
  interactionComplete: boolean = false;

  update(interaction: BaseInteraction) {
    this.id = interaction.id;
    this.setMarkers(interaction.markers);
    this.displayType = interaction.displayType;
    this.scene = interaction.scene;
  }
}

export class CPRSession implements VideoInteraction {
  constructor({id, pointsValue = 50, markers, displayType = InteractionDisplayType.CPR_SESSION, groupNumber = 0, scene = ""}: {id?: string, pointsValue?: number, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, groupNumber?: number, scene?: string}) {
    this.id = id || uuid.v4();
    this.pointsValue = pointsValue;
    this.groupNumber = groupNumber;
    this.setMarkers(markers || []);
    this.displayType = displayType;
    this.scene = scene;
  }

  id: string;
  pointsValue: number;
  groupNumber: number = 0;
  private _markers?: Array<VideoMarker>;
  get markers(): Array<VideoMarker> {
    return this._markers || [];
  }

  setMarkers(markers: Array<VideoMarker>) {
    // Sort the markers by timestamp
    markers = (markers || []).map(m => new VideoMarker(m));
    markers.sort((a, b) => a.timestamp - b.timestamp);
    this._markers = markers;
  }
  displayType: InteractionDisplayType = InteractionDisplayType.CPR_SESSION;
  scene: string;
  interactionComplete: boolean = false;

  update(cprSession: CPRSession) {
    this.id = cprSession.id;
    this.pointsValue = cprSession.pointsValue;
    this.groupNumber = cprSession.groupNumber;
    this.setMarkers(cprSession.markers);
    this.displayType = cprSession.displayType;
    this.scene = cprSession.scene;
  }

}

export class JumpInteraction extends BaseInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.JUMP, scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, scene?: string}) {
    super({id, markers, displayType, scene});
  }
}

// RESULTS
export class ResultsInteraction extends BaseInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.RESULTS, scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, scene?: string}) {
    super({id, markers, displayType, scene});
  }
}

// FINAL_RESULTS
export class FinalResultsInteraction extends BaseInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.FINAL_RESULTS, scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, scene?: string}) {
    super({id, markers, displayType, scene});
  }
}

// TEAM_SCORE
export class TeamScoreInteraction extends BaseInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.TEAM_SCORE, teamName = "", scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, teamName?: string, scene?: string}) {
    super({id, markers, displayType, scene});
    this.teamName = teamName;
  }

  teamName: string = "";

  override update(teamScore: TeamScoreInteraction) {
    super.update(teamScore);
    this.teamName = teamScore.teamName;
  }
}

// SUMMARY
export class SummaryInteraction extends BaseInteraction {
  constructor({id, markers, displayType = InteractionDisplayType.SUMMARY, scene = ""}: {id?: string, markers?: Array<VideoMarker>, displayType?: InteractionDisplayType, scene?: string}) {
    super({id, markers, displayType, scene});
  }
}
