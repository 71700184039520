

export function titleCase(str: string): string {
  const spaceSplits = str.split(' ');
  if (spaceSplits.length > 1) {
    return spaceSplits.map((s) => titleCase(s)).join(' ');
  }
  let firstLetterIndex = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[a-zA-Z]/)) {
      firstLetterIndex = i;
      break;
    }
  }
  return str.slice(0, firstLetterIndex) + str[firstLetterIndex].toUpperCase() + str.slice(firstLetterIndex + 1).toLowerCase();
  // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function sentenceCase(str: string, onlyPerformIfAllCaps: boolean = false): string {
  // if the string is all caps, we don't want to sentence case it
  if (onlyPerformIfAllCaps && str === str.toUpperCase()) {
    return str;
  }
  // capitalize the first letter of the first word, and lowercase the rest
  // the first character may not be a letter, so we need to find the first letter
  let firstLetterIndex = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i].match(/[a-zA-Z]/)) {
      firstLetterIndex = i;
      break;
    }
  }
  return str.slice(0, firstLetterIndex) + str[firstLetterIndex].toUpperCase() + str.slice(firstLetterIndex + 1).toLowerCase();
  // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function equalsIgnoreCase(str1: string, str2: string): boolean {
  return str1.toLowerCase() === str2.toLowerCase();
}
