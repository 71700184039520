import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GameController } from 'src/app/controllers/game.controller';
import { Player } from 'src/app/models/team.model';
import { VideoMarkerType } from 'src/app/models/video-interaction.model';
import { HistogramData } from 'src/app/services/gyroscope.service';


export class PlayerFeedbackModel {
  depthPercent: number = 0.0;
  duration: number = 0;
  timestamp: number = 0;
}

@Component({
  selector: 'app-player-interaction',
  templateUrl: './player-interaction.component.html',
  styleUrl: './player-interaction.component.css'
})
export class PlayerInteractionComponent implements OnInit, OnDestroy {
  @Input() player: Player;
  playerFeedbackModel: PlayerFeedbackModel = new PlayerFeedbackModel();

  constructor(
    private readonly gameController: GameController
  ) {
    this.generateFibonacciStripes(10);
  }

  private _destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.stopAnimationLoop();
    this._destroy$.next();
    this._destroy$.complete();
  }

  playerIsConnected: boolean = false;

  subscribedToPlayerData: boolean = false;
  private _bufferedDuration: number = 0.0;
  private _bufferedDepthPercent: number = 0.0;

  private animationFrameId: number | null = null;
  private lastUpdateTime: number = 0;
  private updateInterval: number = 1000 / 30; // 30 fps update rate

  ngOnInit(): void {
    this.gameController.subscribedToPlayerData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(subscribed => {
        this.subscribedToPlayerData = subscribed;
        if (!subscribed) {
          this.playerIsConnected = false;
          this._bufferedDepthPercent = 0.0;
          this._bufferedDuration = 0.0;
          this.playerFeedbackModel.depthPercent = 0.0;
          this.playerFeedbackModel.duration = 0.0;
        }
      });

    this.gameController.playerData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(players => {
        if (!players || players.length === 0) {
          return;
        }
        const player = players.find(p => p.id === this.player?.id);
        if (!!player) {
          this.playerIsConnected = true;
          // Just update the buffered values, don't update UI directly
          this._bufferedDuration = (player.currentResults?.averageDuration || 0) * 1000.0;
          this._bufferedDepthPercent = player.currentResults?.depthScore || 0;
          // Start animation loop if not already running
          if (!this.animationFrameId) {
            this.startAnimationLoop();
          }
        }
      });

    // Start the animation loop
    this.startAnimationLoop();
  }

  // Create a smooth animation loop
  private startAnimationLoop(): void {
    if (this.animationFrameId !== null) return; // Already running

    const animate = (timestamp: number) => {
      // Control update rate (don't update UI on every frame)
      if (timestamp - this.lastUpdateTime > this.updateInterval) {
        this.lastUpdateTime = timestamp;
        this.updateVisualModel();
      }

      this.animationFrameId = requestAnimationFrame(animate);
    };

    this.animationFrameId = requestAnimationFrame(animate);
  }

  // Smoothly update the visual model with interpolation
  private updateVisualModel(): void {
    // Smoothly interpolate between current and target values
    this.playerFeedbackModel.depthPercent = this._bufferedDepthPercent;
    this.playerFeedbackModel.duration = this._bufferedDuration; // this.transitionSpeed;

    // Update timestamp for any views that need it
    this.playerFeedbackModel.timestamp = Date.now();

    if (!this.subscribedToPlayerData) {
      this.stopAnimationLoop();
    }
  }

  private stopAnimationLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  get isInPreliminaryCprSession(): boolean {
    if (!this.subscribedToPlayerData) {
      return false;
    }
    const currentCprSession = this.gameController?.currentCprSessions[0];
    if (!!currentCprSession) {
      // if the timestamp is past the FINAL_RESULTS timestamp, then include all scores
      const pausePointMarker = currentCprSession.markers.find(m => m.type === VideoMarkerType.PAUSE_POINT);
      if (!pausePointMarker || this.gameController?.game.currentTimestamp <= (pausePointMarker.timestamp + 250)) {
        return true;
      }
    }
    return false;
  }


  get opacity(): number {
    if (!!this.player) {
      // opacity is a value between 0.25 and 1, based on mas required duration
      const maxDuration = HistogramData.MAX_DURATION;
      const duration = this.playerFeedbackModel.duration || 0;
      if (duration > maxDuration) {
        const sigmoid = (x: number) => 1 / (1 + Math.exp(-x));
        const result = Math.max(0.25, sigmoid(1 - (duration - maxDuration) / maxDuration));
        return result;
      }
      return 1;
    }
    return 0.5;
  }

  static REQUIRED_TRAVEL_DISTANCE = 10000.0;
  // create an easing function to ease the depth percentage
  static sigmoid(x, k = 10, x0 = 0.5) {
    return 1 / (1 + Math.exp(-k * (x - x0)));
  }

  /*********************
   * Fibonacci sequence *
   */
  squares: { height: string, top: string, color: string }[] = [];

  private fibonacci(num: number): number {
    let a = 0, b = 1, temp;
    while (num >= 0){
        temp = a;
        a = a + b;
        b = temp;
        num--;
    }
    return b;
  }

  private generateFibonacciStripes(count: number) {
    let total = this.fibonacci(count); // Total height for normalization
    let offset = 0;
    for (let i = 0; i < count; i++) {
      const fib = this.fibonacci(i);
      const height = (fib / total) * 100; // Calculate height as a percentage
      this.squares.push({
        height: `${height}%`,
        top: `${offset}%`,
        color: i % 2 === 0 ? 'bg-red-500' : 'bg-black'
      });
      offset += height;
    }
  }
}
