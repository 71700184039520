import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GameStateDetails } from './player.service';

export interface TelemetryDto {
  gameId: string;
  gamePosition: string;
  timestamp: number;
  data: Array<{ z: number; timestamp: number }>;
}

export interface GameStateDto {
  id?: string;
  status?: string;
  timestamp?: number;
  paused?: boolean;
  teamScores?: number[];
}

@Injectable({
  providedIn: 'root'  // Ensure singleton instance
})
export class TelemetryService implements OnInit, OnDestroy {
  private _socket: Socket | null = null;
  private reconnectInterval = 2500;
  private connectionStatus = new BehaviorSubject<boolean>(false);
  private queuedMessages: {event: string, data: any}[] = [];
  private bufferEnabled = true;
  private bufferLimit = 50;
  // private serverUrl = 'https://local.api.cardiacstar.com:5001';
  private serverUrl = environment.api_ws;
  private connecting = false;
  private destroy$ = new Subject<void>();
  private connectionAttempts = 0;
  private maxConnectionAttempts = 25;
  private connectionTimeout: any;

  // Add handler for incoming player data
  private playerDataCallbacks: ((data: any) => void)[] = [];

  // Register callback for player data
  registerPlayerDataCallback(callback: (data: any) => void): void {
    this.playerDataCallbacks.push(callback);
  }

  unregisterPlayerDataCallbacks(): void {
    this.playerDataCallbacks = [];
  }

  // Expose connection status as an observable
  public connectionStatus$ = this.connectionStatus.asObservable();

  // Add subject for player data
  private playerDataSubject = new Subject<any[]>();
  public playerData$ = this.playerDataSubject.asObservable();

  // Add subject for timestamp updates
  private gameStateDetailsUpdateSubject = new Subject<GameStateDetails>();
  public gameStateDetailsUpdate$ = this.gameStateDetailsUpdateSubject.asObservable();

  // Add a property for heartbeat interval
  private heartbeatInterval: any;

  // Modify the existing inactivity timer
  private inactivityTimer: any = null;
  private inactivityTimeout = 30000; // 30 seconds of inactivity before disconnecting

  constructor() {
    // Don't connect automatically in constructor
    console.log('TelemetryService instance created');
  }

  ngOnInit(): void {
  }

  private connect(): Promise<boolean> {
    // Guard against multiple connection attempts
    if (this._socket?.connected) {
      console.log('Socket already connected, reusing connection');
      return Promise.resolve(true);
    }

    if (this.connecting) {
      console.log('Connection attempt already in progress');
      return new Promise((resolve) => {
        const sub = this.connectionStatus$
          .pipe(takeUntil(this.destroy$))
          .subscribe(isConnected => {
            if (isConnected) {
              resolve(true);
              sub.unsubscribe();
            }
          });

        // Timeout after 5 seconds
        setTimeout(() => {
          if (!this._socket?.connected) {
            resolve(false);
            sub.unsubscribe();
          }
        }, 5000);
      });
    }

    this.connecting = true;
    this.connectionAttempts++;

    console.log(`Connecting to WebSocket server at ${this.serverUrl} (Attempt ${this.connectionAttempts})`);

    return new Promise((resolve) => {
      // Close any existing connection
      if (this._socket) {
        this._socket.disconnect();
        this._socket = null;
      }

      this._socket = io(this.serverUrl, {
        path: '/socket.io/',
        reconnection: true,
        reconnectionDelay: this.reconnectInterval,
        reconnectionAttempts: 5,
        secure: true,
        rejectUnauthorized: false,
        // transports: ['websocket'],
        // withCredentials: true,
        withCredentials: false,
        timeout: 5000
      });

      // Add connection timeout
      this.connectionTimeout = setTimeout(() => {
        if (this.connecting) {
          console.log('Connection timeout');
          this.connecting = false;
          if (this._socket) {
            this._socket.disconnect();
          }
          resolve(false);
        }
      }, 5000);

      this._socket.on('connect', () => {
        clearTimeout(this.connectionTimeout);
        this.connecting = false;
        console.log('WebSocket connected with ID:', this._socket?.id);
        this.connectionStatus.next(true);
        this.connectionAttempts = 0;

        // Send any queued messages
        this.flushQueuedMessages();
        resolve(true);
      });

      this._socket.on('disconnect', (reason) => {
        console.log(`WebSocket disconnected: ${reason}`);
        this.connectionStatus.next(false);
      });

      this._socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        this.connecting = false;
        this.connectionStatus.next(false);
        resolve(false);
      });

      // Listen for messages
      this._socket.on('message', (data) => {
        console.log('Message from server:', data);
      });

      // Add specific handler for player data
      this._socket.on('playerData', (data) => {
        // console.log('Received player data via WebSocket:',
        //            Array.isArray(data) ? data.length : 'object');
        // Update the subject
        this.playerDataSubject.next(data);
        // Call any registered callbacks
        this.playerDataCallbacks.forEach(callback => callback(data));
      });

      // Add handler for video timestamp updates
      this._socket.on('gameStateDetails', (data) => {
        let gameStateDetails: GameStateDetails = data;
        console.log('Received video timestamp update:', data);
        if (data && data.id) {
          this.gameStateDetailsUpdateSubject.next(gameStateDetails);
        }
      });
    });
  }

  private flushQueuedMessages(): void {
    if (!this._socket?.connected || this.queuedMessages.length === 0) return;

    console.log(`Sending ${this.queuedMessages.length} queued messages`);
    this.queuedMessages.forEach(msg => {
      this._socket?.emit(msg.event, msg.data);
    });
    this.queuedMessages = [];
  }

  // Public method to send telemetry, connects if needed
  async sendTelemetry(data: TelemetryDto): Promise<boolean> {
    // Reset inactivity timer
    this.resetInactivityTimer();

    // Ensure connection exists before sending
    if (!this._socket || !this._socket.connected) {
      // Only try reconnecting if we haven't exceeded our limit
      if (this.connectionAttempts <= this.maxConnectionAttempts) {
        const connected = await this.connect();
        if (!connected) {
          console.log('Failed to connect, buffering message');
          this.bufferMessage('telemetry', data);
          return false;
        }
      }
      else if (this.connectionAttempts > this.maxConnectionAttempts) {
        // attempt to reconnect after an exponential backoff
        console.log('Exceeded maximum connection attempts, waiting before reconnecting');
        setTimeout(async () => {
          await this.connect();
        }, this.reconnectInterval * Math.pow(2, this.connectionAttempts - this.maxConnectionAttempts));
      }
      else {
        console.log('Maximum connection attempts reached, buffering message');
        this.bufferMessage('telemetry', data);
        return false;
      }
    }

    console.log('Sending telemetry via WebSocket:', data.gamePosition, data.data.length);
    this._socket.emit('telemetry', data);
    return true;
  }

  private bufferMessage(event: string, data: any): void {
    if (!this.bufferEnabled) return;

    console.log(`Buffering ${event} message`);
    if (this.queuedMessages.length < this.bufferLimit) {
      this.queuedMessages.push({event, data});
    } else {
      // Remove oldest message and add new one
      this.queuedMessages.shift();
      this.queuedMessages.push({event, data});
    }
  }

  // Create an observable to listen to specific events
  listen<T>(eventName: string): Observable<T> {
    // Auto-connect if needed when listening for events
    if (!this._socket?.connected) {
      this.connect();
    }

    return new Observable((subscriber) => {
      if (this._socket) {
        this._socket.on(eventName, (data: T) => {
          subscriber.next(data);
        });
      }

      return () => {
        this._socket?.off(eventName);
      };
    });
  }

  // Get connection state
  isConnected(): boolean {
    return this._socket?.connected || false;
  }

  // Enable or disable message buffering
  setBuffering(enabled: boolean): void {
    this.bufferEnabled = enabled;
  }

  // Reset connection attempts counter
  resetConnectionAttempts(): void {
    this.connectionAttempts = 0;
  }

  // Clean explicit disconnect
  disconnect(): void {
    if (this._socket) {
      console.log('Explicitly disconnecting socket');
      this._socket.disconnect();
      this._socket = null;
    }
    this._subscribedGameId = null;
    this.connectionStatus.next(false);
  }

  // Add a method to subscribe to player data
  private _subscribedGameId: string | null = null;
  private _subscribedCprSessionId: string | null = null;
  private _playerDataListener$: Observable<any> = null;
  async subscribeToPlayerData(gameId: string, cprSessionId?: string): Promise<void> {
    if (!gameId) return;

      // Only subscribe if not already subscribed
      if (this._subscribedGameId === gameId && this._subscribedCprSessionId === cprSessionId) {
        console.log('Already subscribed to player data for game', gameId);
        return;
      }

    const connected = await this.ensureConnection();
    if (connected) {
      this._subscribedGameId = gameId;
      this._subscribedCprSessionId = cprSessionId;
      console.log(`Subscribing to player data for game ${gameId}`);
      // this._socket.emit('joinGame', { gameId });
      this._playerDataListener$ = this.listen('playerData').pipe(takeUntil(this.destroy$));
      this._socket.emit('subscribeToPlayerData', { gameId, cprSessionId });

      // setup a re-subscribe
      this.connectionStatus.subscribe(connected => {
        if (connected) {
          this._socket.emit('subscribeToPlayerData', { gameId, cprSessionId });
        }
      });
    }
    else {
      console.warn('Could not subscribe to player data');
      return;
    }
  }

  // Add a method to unsubscribe from player data
  unsubscribeFromPlayerData(): void {
    if (!this._subscribedGameId) return;

    if (this._playerDataListener$) {
      this._playerDataListener$.subscribe().unsubscribe();
      this._playerDataListener$ = null;
    }

    this.ensureConnection().then(connected => {
      if (connected) {
        console.log(`Unsubscribing from player data for game ${this._subscribedGameId}`);
        // this._socket.emit('joinGame', { gameId });
        this._socket.emit('unsubscribeFromPlayerData', { gameId: this._subscribedGameId });
        this._subscribedGameId = null;
      }
    });
  }

  // Add method to update video timestamp
  async updateVideoTimestamp(gameId: string, timestamp: number, paused: boolean): Promise<boolean> {
    if (!this._socket?.connected) {
      const connected = await this.connect();
      if (!connected) return false;
    }

    this._socket.emit('updateVideoTimestamp', {
      gameId,
      timestamp,
      paused
    });

    return true;
  }

  // Send video timestamp update
  sendGameStateDetailsUpdate(data: GameStateDto): void {
    if (!data || !data.id) return;

    this.ensureConnection().then(connected => {
      if (connected) {
        this._socket.emit('gameStateDetailsUpdate', data);
      }
    });
  }

  private _joinedGameId: string | null = null;
  private _joinedPlayerId: string | null = null;
  private _joinedTeamId: string | null = null;
  private _joinGameListener$: Observable<any> = null;
  async joinGame(gameId: string, playerId: string, teamId?: string): Promise<void> {
    if (!gameId) return;
    if (this._joinedGameId === gameId) {
      console.log('Already subscribed to timestamp updates for game', gameId);
      return;
    }

    this._joinedGameId = gameId;
    this._joinedPlayerId = playerId;
    this._joinedTeamId = teamId;
    this._joinGameListener$ = this.listen('videoTimestampUpdated').pipe(takeUntil(this.destroy$));
    const connected = await this.ensureConnection();
    if (connected) {
      this._socket.emit('joinGame', { gameId, playerId, teamId });
    }

    // setup a re-subscribe
    this.connectionStatus.subscribe(connected => {
      if (connected) {
        this._socket.emit('joinGame', { gameId: this._joinedGameId, playerId: this._joinedPlayerId, teamId: this._joinedTeamId });
      }
    });
  }

  leaveGame(): void {
    if (!this._joinedGameId) return;

    if (this._joinGameListener$) {
      this._joinGameListener$.subscribe().unsubscribe();
      this._joinGameListener$ = null
    }

    this.ensureConnection().then(connected => {
      if (connected) {
        console.log(`Unsubscribing from timestamp updates for game ${this._joinedGameId}`);
        this._socket.emit('leaveGame', { gameId: this._joinedGameId });
        this._joinedGameId = null;
      }
    });
  }

  // Helper to ensure connection exists before sending
  public ensureConnection(): Promise<boolean> {
    if (this.isConnected()) {
      return Promise.resolve(true);
    }
    return this.connect();
  }

  // Add a method for the main game web app
  setupContinuousConnection(): void {
    // Connect immediately
    this.ensureConnection().then(connected => {
      if (connected) {
        console.log('Set up continuous WebSocket connection');

        // Set up a heartbeat to keep the connection alive
        this.startHeartbeat();
      }
    });
  }

  // Heartbeat implementation
  private startHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }

    this.heartbeatInterval = setInterval(() => {
      if (this._socket?.connected) {
        this._socket.emit('heartbeat', { timestamp: Date.now() });
      } else {
        // Reconnect if disconnected
        this.ensureConnection();
      }
    }, 30000); // Every 30 seconds
  }

  // Add this method to reset the timer
  private resetInactivityTimer(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }

    this.inactivityTimer = setTimeout(() => {
      console.log('Disconnecting due to inactivity');
      this.disconnect();
    }, this.inactivityTimeout);
  }

  // Clean up on service destroy
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
    if (this._socket) {
      this._socket.disconnect();
      this._socket = null;
    }
    clearTimeout(this.connectionTimeout);
  }

  // Add method to support testing connection quality
  public testConnection(durationMs: number = 5000): Observable<ConnectionTestResult> {
    const resultSubject = new Subject<ConnectionTestResult>();

    this.ensureConnection().then(connected => {
      if (!connected) {
        resultSubject.next({
          success: false,
          latency: 0,
          message: 'Failed to establish connection'
        });
        resultSubject.complete();
        return;
      }

      // Test latency
      const testStart = Date.now();
      this._socket.emit('ping', {}, () => {
        const latency = Date.now() - testStart;

        // Initial latency test passed
        let packetsSent = 0;
        let packetsReceived = 0;
        const testInterval = setInterval(() => {
          packetsSent++;
          this._socket.emit('ping', { id: packetsSent }, () => {
            packetsReceived++;
          });
        }, 100); // Send 10 packets per second

        // End the test after specified duration
        setTimeout(() => {
          clearInterval(testInterval);

          resultSubject.next({
            success: true,
            latency,
            packetLoss: packetsSent > 0 ? (1 - packetsReceived / packetsSent) : 0,
            message: `Connection test completed. Latency: ${latency}ms, Packet loss: ${Math.round((1 - packetsReceived / packetsSent) * 100)}%`
          });
          resultSubject.complete();
        }, durationMs);
      });
    });

    return resultSubject.asObservable();
  }

  // Add a public accessor method for the socket if needed
  public get socket(): any {
    return this._socket;
  }

  // Add this method to handle socket.emit indirectly
  public emitEvent(eventName: string, data: any): void {
    if (this.socket?.connected) {
      this.socket.emit(eventName, data);
    } else {
      console.warn(`Cannot emit ${eventName} event: Socket not connected`);
    }
  }
}

// Add interface for test results
export interface ConnectionTestResult {
  success: boolean;
  latency: number;
  packetLoss?: number;
  message: string;
}
