<div class="bg-white rounded-lg shadow-md p-4">
  <h2 class="text-lg font-bold mb-4">{{ team?.name }}</h2>
  <div class="grid grid-cols-2 gap-4">
    <div *ngFor="let teamPlayer of team?.players" class="flex bg-gray-100 rounded-lg p-4 min-w-[200px] items-center">
      <div class="text-lg flex-grow"
      [ngClass]="{'font-bold': isCurrentPlayer(teamPlayer)}"
      >{{ teamPlayer.name }}</div>
    </div>
  </div>
  <div class="flex justify-end mt-4">
    <p class="text-lg font-bold">Total: {{ team?.score }}</p>
  </div>
</div>
