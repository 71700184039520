import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GyroscopeService } from 'src/app/services/gyroscope.service';

interface DataPoint {
  timestamp: number;
  value: number;
}

export interface PlotPoint extends DataPoint {
  right: number;
  bottom: number;
}

@Component({
  selector: 'app-real-time-plot',
  templateUrl: './real-time-plot.component.html',
  styleUrls: ['./real-time-plot.component.css']
})
export class RealTimePlotComponent implements OnInit, OnDestroy {
  plotPoints: PlotPoint[] = this.gyroscopeService?.plotPoints || [];
  // private _plotPoints: PlotPoint[] = [];
  // get plotPoints(): PlotPoint[] {
  //   const now = Date.now();
  //   const maxWidth = this.chartWidth;
  //   // this.dataPoints = this.dataPoints.filter(dp => now - dp.timestamp < this.maxWindowMs);
  //   // this.plotPoints = this.plotPoints.filter(pp => now - pp.timestamp < this.maxWindowMs);

  //   // calculate the new lower and upper bounds based on the min/max values in the data points
  //   const minValue = Math.min(...this.plotPoints.map(dp => dp.value));
  //   const maxValue = Math.max(...this.plotPoints.map(dp => dp.value));
  //   const valueRange = maxValue - minValue;
  //   const newLowerBound = Math.min(minValue - valueRange * 0.1, RealTimePlotComponent.MIN_LOWER_BOUND);
  //   const newUpperBound = Math.max(maxValue + valueRange * 0.1, RealTimePlotComponent.MAX_UPPER_BOUND);

  //   // Only adjust they are either too small or are too big and have changed by more than 10%
  //   if (newLowerBound < this._lowerBound * 0.9 || newLowerBound > this._lowerBound * 1.1) {
  //     this._lowerBound = newLowerBound;
  //   }
  //   if (newUpperBound < this._upperBound * 0.9 || newUpperBound > this._upperBound * 1.1) {
  //     this._upperBound = newUpperBound;
  //   }

  //   // return this._plotPoints;
  //   this._plotPoints = this.gyroscopeService?.data.map((dataPoint) => {
  //     return {
  //       timestamp: dataPoint.timestamp,
  //       value: dataPoint.z,
  //       right: maxWidth,
  //       bottom: this.getBottomPosition(dataPoint.z)
  //     };
  //   });

  //   return this._plotPoints;
  // }
  maxWindowMs: number = 5000; // 5 seconds

  @ViewChild('chartContainer') chartContainer: ElementRef;

  constructor(
    private readonly gyroscopeService: GyroscopeService
  ) {}

  ngOnInit(): void {
    // // this.simulateDataStream();
    // // this.startUpdateCycle();
    // this.gyroscopeService.dataStream$
    // .pipe(
    //   takeUntil(this.destroy$)
    // )
    // .subscribe((dataPoint) => {
    //   if (!!dataPoint.timestamp && !!dataPoint.z) {
    //     this.addDataPoint({
    //       timestamp: dataPoint.timestamp,
    //       value: dataPoint.z
    //     });
    //   }
    // });
  }

  private destroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    console.log('RealTimePlotComponent destroyed');

  }

  // startUpdateCycle(): void {
  //   const updatePositions = () => {
  //     this.cleanOldDataPoints(); // Remove old data points outside the time window
  //     this.animationFrameId = requestAnimationFrame(updatePositions);
  //   };

  //   this.animationFrameId = requestAnimationFrame(updatePositions);
  // }

  static MIN_LOWER_BOUND = -5;
  static MAX_UPPER_BOUND = 5;
  private _lowerBound: number = 0;
  private _upperBound: number = 100;
  addDataPoint(dataPoint: DataPoint): void {
    const now = Date.now();
    const maxWidth = this.chartWidth;
    // this.dataPoints = this.dataPoints.filter(dp => now - dp.timestamp < this.maxWindowMs);
    // this.plotPoints = this.plotPoints.filter(pp => now - pp.timestamp < this.maxWindowMs);

    // calculate the new lower and upper bounds based on the min/max values in the data points
    const minValue = Math.min(...this.plotPoints.map(dp => dp.value));
    const maxValue = Math.max(...this.plotPoints.map(dp => dp.value));
    const valueRange = maxValue - minValue;
    const newLowerBound = Math.min(minValue - valueRange * 0.1, RealTimePlotComponent.MIN_LOWER_BOUND);
    const newUpperBound = Math.max(maxValue + valueRange * 0.1, RealTimePlotComponent.MAX_UPPER_BOUND);

    // Only adjust they are either too small or are too big and have changed by more than 10%
    if (newLowerBound < this._lowerBound * 0.9 || newLowerBound > this._lowerBound * 1.1) {
      this._lowerBound = newLowerBound;
    }
    if (newUpperBound < this._upperBound * 0.9 || newUpperBound > this._upperBound * 1.1) {
      this._upperBound = newUpperBound;
    }

    // setTimeout(() => {
      // // For the rest, update the position
      // this.plotPoints.forEach(pp => {
      //   pp.right = this.getRightPosition(pp.timestamp, maxWidth, now);
      //   pp.bottom = this.getBottomPosition(pp.value);
      // });

      // this.dataPoints.push(dataPoint);
      const newPlotPoint: PlotPoint = {
        ...dataPoint,
        right: this.getRightPosition(dataPoint.timestamp, maxWidth, now),
        bottom: this.getBottomPosition(dataPoint.value)
      };
      this.plotPoints.push(newPlotPoint);

      setTimeout(() => {
        newPlotPoint.right = 0;
      }, 1);

      // filter out the points that are outside the visible window based on the right position
      // this.plotPoints = this.plotPoints.filter(pp => pp.right >= 0 && pp.right <= maxWidth);
      for(let i = this.plotPoints.length-1; i >= 0; i--) {
        if (this.plotPoints[i].right < 0 || this.plotPoints[i].right > maxWidth) {
          this.plotPoints.splice(i, 1);
        }
      }

      if (this.plotPoints.length < 10) {
        console.log('No plot points');
      }
    // }, 1);

  }

  simulateDataStream(): void {
    setInterval(() => {
      const now = Date.now();
      const value = Math.random() * 100; // Random value for demonstration
      this.addDataPoint({ timestamp: now, value });
    }, 500); // New point every 500 ms
  }

  private getRightPosition(timestamp: number, maxWidth: number, now: number = Date.now()): number {
    const oldestAllowedTimestamp = now - this.maxWindowMs;
    let position = ((timestamp - oldestAllowedTimestamp) / this.maxWindowMs); // Calculate position as a percentage of the 5s window
    // position = Math.round(position / 100) * 100;
    // position = Math.min(100, position); // Ensure the position is not greater than 100
    // position = Math.max(0, position); // Ensure the position is not less than 0
    // now make it relative to the chart width
    position = position * (maxWidth || this.chartWidth);
    position = Math.round(position / 10) * 10;
    // console.log('Position:', position);
    return position;
  }

  get chartHeight(): number {
    return this.chartContainer?.nativeElement?.offsetHeight || 10;
  }
  get chartWidth(): number {
    return this.chartContainer?.nativeElement?.offsetWidth || 100;
  }

  getBottomPosition(value: number): number {
    // calculate the position based on the value and the lower and upper bounds
    const range = this._upperBound - this._lowerBound;
    const valueOffset = value - this._lowerBound;
    let position = valueOffset / range;
    position = 1 - position; // Invert the position
    position = Math.round(position * this.chartHeight);
    position = Math.round(position / 10) * 10;
    return position;
  }
}
