import { Component } from '@angular/core';
import { NavControlService } from 'src/app/services/nav-control.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(
    private readonly navControlService: NavControlService
  ) {}

  goToInstructor(): void {
    this.navControlService.goToInstructor();
  }

}

