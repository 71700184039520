import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoResizeText]',
})
export class AutoResizeTextDirective {

  constructor(private el: ElementRef) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeFont();
  }

  ngAfterViewInit() {
    this.resizeFont();
  }

  private resizeFont() {
    const hostElem = this.el.nativeElement;
    let fontSize = parseInt(window.getComputedStyle(hostElem).fontSize);
    while (hostElem.scrollWidth > hostElem.offsetWidth && fontSize > 1) {
      fontSize--;
      hostElem.style.fontSize = `${fontSize}px`;
    }
  }
}
