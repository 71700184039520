export const environment = {
  production: false,
  api: "https://api.cardiacstar.com",
  api_ws: "wss://api.cardiacstar.com",
  // api: "https://b13f1d16212c.ngrok.app",
  // api_ws: "wss://b13f1d16212c.ngrok.app",
  // api: "https://fe8c-2601-647-cb00-9e80-f1f8-74a1-afac-175f.ngrok-free.app",
  // api_ws: "https://fe8c-2601-647-cb00-9e80-f1f8-74a1-afac-175f.ngrok-free.app",
  // api: "https://local.api.cardiacstar.com:5001",
  // api_ws: "wss://local.api.cardiacstar.com:5001",

  // api: "http://192.168.125.196:5001",
  // api: "https://1a3pup9k57.execute-api.us-west-2.amazonaws.com/dev",
  // api: "https://35.165.56.19",
  // api: "https://api.cardiacstar.com",
  // api: "https://10.0.0.208:5001",
  // api: "https://10.0.0.94:5001",
  // api: "https://1a3pup9k57.execute-api.us-west-2.amazonaws.com/dev",
  // api_ws: "wss://192.168.125.197:5001",
  // api: "https://localhost:5001",
  // api_ws: "wss://localhost:5001",
  // api: "https://svrn4x5cd9.execute-api.us-east-2.amazonaws.com/dev",
  // api_ws: "wss://zvptvgm1hd.execute-api.us-east-2.amazonaws.com/dev",
};
