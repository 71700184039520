import { Component, OnInit, OnDestroy } from '@angular/core';
import { GyroscopeService, MotionData } from '../services/gyroscope.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cpr-performance',
  templateUrl: './cpr-performance.component.html',
  styleUrls: ['./cpr-performance.component.css']
})
export class CprPerformanceComponent implements OnInit, OnDestroy {
  private gyroscopeSubscription!: Subscription;
  cprStatus: 'green' | 'red' | 'unknown' = 'unknown';

  // Need to get 80-100Hz data from gyroscopeService

  constructor(private gyroscopeService: GyroscopeService) {}

  ngOnInit(): void {
    this.gyroscopeSubscription = this.gyroscopeService.getMotionDataStream().subscribe(
      (gyroscopeData: MotionData) => {
        this.evaluateCprPerformance(gyroscopeData);
      }
    );
  }

  ngOnDestroy(): void {
    this.gyroscopeSubscription.unsubscribe();
  }

  evaluateCprPerformance(gyroscopeData: MotionData): void {
    // Implement your logic here to evaluate the CPR performance based on the gyroscope data
    // and update the cprStatus accordingly. For example:
    if (gyroscopeData.z !== null && gyroscopeData.z > 10) {
      this.cprStatus = 'green';
    } else {
      this.cprStatus = 'red';
    }
  }
}
