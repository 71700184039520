import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GameController } from 'src/app/controllers/game.controller';
import { FetchState } from 'src/app/graphql.module';
import { Game } from 'src/app/models/game.model';

@Component({
  selector: 'app-pre-game',
  templateUrl: './pre-game.component.html',
  styleUrl: './pre-game.component.css'
})
export class PreGameComponent implements OnInit {

  gameTypeOptions = [
    {
      id: "athlete_v21",
      name: "Athlete v21"
    },
    {
      id: "athlete_v21_nospring",
      name: "Athlete v21 (No Spring)"
    },
    {
      id: "athlete_short",
      name: "Athlete Short (No Spring)"
    },
    {
      id: "athlete_short_spring",
      name: "Athlete Short"
    },
    {
      id: "family/family_v15",
      name: "Family (English)"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private readonly gameController: GameController,
    private readonly router: Router
  ) {
    this.existingGameId = localStorage.getItem('gameId');
  }

  existingGameId: string = null;

  gameType: string = 'athlete_v21';
  numberOfTeams: number = 2;

  gameForm: FormGroup;
  createState: FetchState = FetchState.NONE;

  FetchState = FetchState;

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.gameForm = this.fb.group({
      gameType: [this.gameType, [Validators.required]],
      numberOfTeams: [this.numberOfTeams, [Validators.required, Validators.min(1), Validators.max(4)]],
    });
  }

  async createGame() {
    // if the form is invalid, mark all fields as touched
    if (this.gameForm.invalid) {
      this.gameForm.markAllAsTouched();
      return;
    }

    try {
      this.createState = FetchState.LOADING;
      this.gameType = this.gameForm.value.gameType;
      this.numberOfTeams = parseInt(this.gameForm.value.numberOfTeams);
      let game = await this.gameController.initiateNewGame(null, this.gameType, this.numberOfTeams, true);
      // if we have a game, then navigate to the game session
      if (game) {
        this.createState = FetchState.LOADED_ALL;
        this.router.navigate(['/game', game.id, 'instructor']);
      }
      else {
        this.createState = FetchState.ERROR;
      }
    }
    catch (e) {
      this.createState = FetchState.ERROR;
    }
  }

  async goToGame(gameId: string) {
    this.router.navigate(['/game', gameId, 'instructor']);
  }

  async resetGame(gameId: string) {
    try {
      await this.gameController.resetGame(gameId);
    }
    catch (e) {
      console.error(e);
    }
    this.existingGameId = null;
  }
}
