import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GameController } from 'src/app/controllers/game.controller';
import { Player } from 'src/app/models/team.model';
import { VideoMarkerType } from 'src/app/models/video-interaction.model';
import { HistogramData } from 'src/app/services/gyroscope.service';

@Component({
  selector: 'app-player-interaction',
  templateUrl: './player-interaction.component.html',
  styleUrl: './player-interaction.component.css'
})
export class PlayerInteractionComponent implements OnInit, OnDestroy {
  @Input() player: Player;

  constructor(
    private readonly gameController: GameController
  ) {
    this.generateFibonacciStripes(10);
  }

  private _destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  playerIsConnected: boolean = false;

  subscribedToPlayerData: boolean = false;
  ngOnInit(): void {
      this.gameController.subscribedToPlayerData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(subscribed => {
        this.subscribedToPlayerData = subscribed;
        if (!subscribed) {
          this.playerIsConnected = false;
          this.depthPercent = 0.0;
          this.duration = 0.0;
        }
      });

      this.gameController.playerData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(players => {
        if (!players || players.length === 0) {
          return;
        }
        const player = players.find(p => p.id === this.player.id);
        if (!!player) {
          this.duration = player.histogramData?.duration || 0;
          this.calcDepthPercent(player.histogramData?.distance || 0);
        }
      });
  }

  get isInPreliminaryCprSession(): boolean {
    if (!this.subscribedToPlayerData) {
      return false;
    }
    const currentCprSession = this.gameController?.currentCprSessions[0];
    if (!!currentCprSession) {
      // if the timestamp is past the FINAL_RESULTS timestamp, then include all scores
      const pausePointMarker = currentCprSession.markers.find(m => m.type === VideoMarkerType.PAUSE_POINT);
      if (!pausePointMarker || this.gameController?.game.currentTimestamp <= (pausePointMarker.timestamp + 250)) {
        return true;
      }
    }
    return false;
  }


  get opacity(): number {
    if (!!this.player) {
      // opacity is a value between 0.25 and 1, based on mas required duration
      const maxDuration = HistogramData.MAX_DURATION;
      const duration = this.duration;
      if (duration > maxDuration) {
        const sigmoid = (x: number) => 1 / (1 + Math.exp(-x));
        const result = Math.max(0.25, sigmoid(1 - (duration - maxDuration) / maxDuration));
        return result;
      }
      return 1;
    }
    return 0.5;
  }

  static REQUIRED_TRAVEL_DISTANCE = 10000.0;
  // create an easing function to ease the depth percentage
  static sigmoid(x, k = 10, x0 = 0.5) {
    return 1 / (1 + Math.exp(-k * (x - x0)));
  }

  depthPercent: number = 0;
  duration: number = 0;
  private calcDepthPercent(distance: number): number {
    let d = Math.max(Math.min(PlayerInteractionComponent.REQUIRED_TRAVEL_DISTANCE, Math.abs(distance || 0.0)), 0);
    // console.log('d', d);

    // Apply the sigmoid to a percentage
    let depthPercentage = (d / PlayerInteractionComponent.REQUIRED_TRAVEL_DISTANCE);
    let scaledDepth = PlayerInteractionComponent.sigmoid(depthPercentage);
    // console.log('scaledDepth', scaledDepth);

    this.depthPercent = scaledDepth * 100.0;
    return this.depthPercent;

    /**
    // Calculate the depth percentage based on he histogram percentiles values
    // If the histogram is not available, return 0
    if (!this.histogram || !this.histogram.percentiles || this.histogram.percentiles.length < 2) {
      return 0;
    }
    const bottom = this.histogram.percentiles[0]?.value;
    const top = this.histogram.percentiles[this.histogram.percentiles.length-1]?.value;

    if (bottom === undefined || top === undefined) {
      return 0;
    }

    const distance = Math.abs(top - bottom);

    // The distance required to reach 100% is {REQUIRED_TRAVEL_DISTANCE}. Calculate the percentage based on this distance, accentuating the lower values
    const percent = Math.min(1.35, distance / PlayerInteractionComponent.REQUIRED_TRAVEL_DISTANCE);

    if (!this.playerIsConnected && this.subscribedToPlayerData) {
      if (percent > 0.50) {
        this.playerIsConnected = true;
      }
    }

    return percent * 100;
    */
  }

  // get speedIndicator(): string {
  //   if (!!this.histogram) {
  //     return `${this.histogram.duration} -> ${this.histogram.speedIndicator}`;
  //   }
  //   return 'Speed';
  // }

  // get depthIndicator(): string {
  //   if (!!this.histogram) {
  //     return `(${this.histogram.percentiles[0]?.value?.toFixed(0)}/${this.histogram.percentiles[1]?.value?.toFixed(0)}) / (${this.histogram.percentiles[this.histogram.percentiles.length-1]?.value?.toFixed(0)}/${this.histogram.percentiles[this.histogram.percentiles.length-2]?.value?.toFixed(0)}) -> ${this.histogram.depthIndicator}`;
  //   }
  //   return 'Depth';
  // }

  // get jsonHistogram(): string {
  //   return this.histogram?.jsonHistogram;
  // }

  /*********************
   * Fibonacci sequence *
   */
  squares: { height: string, top: string, color: string }[] = [];

  private fibonacci(num: number): number {
    let a = 0, b = 1, temp;
    while (num >= 0){
        temp = a;
        a = a + b;
        b = temp;
        num--;
    }
    return b;
  }

  private generateFibonacciStripes(count: number) {
    let total = this.fibonacci(count); // Total height for normalization
    let offset = 0;
    for (let i = 0; i < count; i++) {
      const fib = this.fibonacci(i);
      const height = (fib / total) * 100; // Calculate height as a percentage
      this.squares.push({
        height: `${height}%`,
        top: `${offset}%`,
        color: i % 2 === 0 ? 'bg-red-500' : 'bg-black'
      });
      offset += height;
    }
  }
}
