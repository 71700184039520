<div class="w-full h-screen flex flex-col justify-center items-center relative bg-stone-50">
  <div class="text-4xl font-bold">Game Setup</div>
  <!-- If there is an existing game, then give the option to continue -->
  <div *ngIf="!!existingGameId" class="flex flex-col items-center mt-4">
    <div class="text-lg">Game already exists. Do you want to continue?</div>
    <div class="flex flex-col justify-center items-center mt-4">
      <button (click)="goToGame(existingGameId)" class="blue-button">Continue Game</button>
      <button (click)="resetGame(existingGameId)" class="red-button">Reset Game</button>
    </div>
  </div>
  <form *ngIf="!existingGameId" [formGroup]="gameForm" class="flex flex-col items-center mt-4" (submit)="createGame()">
    <div class="flex flex-col justify-center items-start">
      <!-- <div class="text-md">Game Name</div> -->
      <!-- select game type from game type options-->
      <select formControlName="gameType" class="border rounded p-2 text-base w-full cursor-pointer"
          [disabled]="createState === FetchState.LOADING" [ngClass]="{
        'border-red-500': gameForm.controls['gameType']?.invalid && gameForm.controls['gameType']?.touched,
        'border-grey-800': gameForm.controls['gameType']?.valid,
        'text-grey-700 bg-stone-100 select-none pointer-events-none cursor-auto': createState === FetchState.LOADING,
        'text-grey-900 bg-white': createState !== FetchState.LOADING
      }">
        <option value="" disabled selected>Select Game Type</option>
        <option *ngFor="let gameType of gameTypeOptions" [value]="gameType.id">{{ gameType.name }}</option>
      </select>
    </div>
    <div class="flex flex-col justify-center items-start">
      <!-- <div class="text-md">Number of Teams:</div> -->
      <input formControlName="numberOfTeams" type="hidden" class="border rounded p-2 text-base w-full cursor-text"
          [disabled]="createState === FetchState.LOADING" [ngClass]="{
        'border-red-500': gameForm.controls['numberOfTeams']?.invalid && gameForm.controls['numberOfTeams']?.touched,
        'border-grey-800': gameForm.controls['numberOfTeams']?.valid,
        'text-grey-700 bg-stone-100 select-none pointer-events-none cursor-auto': createState === FetchState.LOADING,
        'text-grey-900 bg-white': createState !== FetchState.LOADING
      }" />
    </div>

    <div class="flex flex-row justify-center items-center">
      <button type="submit" class="blue-button mt-4" [disabled]="gameForm.invalid || createState === FetchState.LOADING">
        Create Game</button>
    </div>
  </form>

  <!-- Loading Cover -->
  <div *ngIf="createState === FetchState.LOADING" class="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <!-- <div class="loading-spinner"></div> -->
    <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</div>
