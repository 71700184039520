import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavControlService {

  constructor(
    private router: Router
  ) { }

  goToHome(): void {
    this.router.navigate(['']);
  }

  goToInstructor(gameId?: string): void {
    if (!!gameId) {
      this.router.navigate(['game', gameId, 'instructor']);
    }
    else {
      this.router.navigate(['game']);
    }
  }

  goToPlayer(gameId: string): void {
    this.router.navigate(['game', gameId, 'player']);
    // this.router.navigate(['game', gameId]);
  }

  private _showMenu: boolean = false;
  get showMenu(): boolean {
    return this._showMenu;
  }
  set showMenu(value: boolean) {
    this._showMenu = value;
  }

  closeMenu(): void {
    this.showMenu = false;
  }

  openMenu(): void {
    this.showMenu = true;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }


}
