<div class="w-full h-full flex flex-col justify-center items-center relative my-[-40px]">
    <!-- Speedometer -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 600 600">
    <!-- right side -->
    <!-- <path [attr.fill]="speedFillRightColor" d="m489.466,257.53c-28.402-28.402-62.058-49.639-98.775-62.811l-63.972,229.551h-27.084c.197.592-2.035,1.095-1.901,1.493h269.342v-.585c-5.071-63.346-32.179-122.216-77.61-167.648Z" /> -->
    <!-- <path [attr.opacity]="rightOpacity" fill="#ffd880" d="m489.466,257.53c-28.402-28.402-62.058-49.639-98.775-62.811l-63.972,229.551h-27.084c.197.592-2.035,1.095-1.901,1.493h269.342v-.585c-5.071-63.346-32.179-122.216-77.61-167.648Z" /> -->
    <!-- <path fill="#ffd880" d="m489.466,257.53c-28.402-28.402-62.058-49.639-98.775-62.811l-63.972,229.551h-27.084c.197.592-2.035,1.095-1.901,1.493h269.342v-.585c-5.071-63.346-32.179-122.216-77.61-167.648Z" /> -->
    <polygon class="transition-all duration-150 ease-in-out" [attr.opacity]="rightOpacity" points="350,440 565,440 580,325 395,270" fill="red" stroke="black" stroke-width="0"/>
    <!-- <polygon points="15,325 200,270 250,440 35,440" fill="#ffd880" stroke="black" stroke-width="0"/> -->

    <!-- left side -->
    <!-- <path [attr.fill]="speedFillLeftColor" d="m273.28,424.269l-63.972-229.55c-36.717,13.172-70.373,34.41-98.774,62.811-45.431,45.432-72.539,104.301-77.61,167.648v.585h267.056l-.036-1.493h-26.664Z" /> -->
    <!-- <path [attr.opacity]="leftOpacity" fill="#ffd880" d="m273.28,424.269l-63.972-229.55c-36.717,13.172-70.373,34.41-98.774,62.811-45.431,45.432-72.539,104.301-77.61,167.648v.585h267.056l-.036-1.493h-26.664Z" /> -->
    <!-- <path fill="#ffd880" d="m273.28,424.269l-63.972-229.55c-36.717,13.172-70.373,34.41-98.774,62.811-45.431,45.432-72.539,104.301-77.61,167.648v.585h267.056l-.036-1.493h-26.664Z" /> -->
    <polygon class="transition-all duration-150 ease-in-out" [attr.opacity]="leftOpacity" points="15,325 200,270 250,440 35,440" fill="red" stroke="black" stroke-width="0"/>

    <!-- tick marks -->
    <!-- <path stroke-width="0" fill="#939497" d="m483.309,373.47l-2.165-5.187,57.707-24.08,2.165,5.187-57.707,24.08Zm-422.051-29.561l-2.137,5.198,57.832,23.778,2.137-5.198-57.832-23.778Zm57.085-83.434l-3.96,3.988,44.369,44.06,3.96-3.988-44.369-44.06Zm367.194,3.874l-3.988-3.96-44.06,44.369,3.988,3.96,44.06-44.369Z" />
    <path fill="#4b4b4c" stroke-width="0" d="m343.585,425.92c-5.463-21.081-22.872-36.497-43.516-36.497s-38.053,15.417-43.516,36.497h87.031Z" />
    <path fill="#666" stroke-width="0" d="m339.45,425.92c-4.303-18.414-20.297-32.092-39.38-32.092s-35.078,13.678-39.38,32.092h78.761Z" /> -->
  </svg>

  <!-- Depth Bar -->
  <svg class="absolute inset-0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 600">
    <polygon fill="#4b4b4c" stroke-width="0" points="273.74 457.233 326.259 457.233 396.359 205.694 404.559 157.318 387.808 159.496 313.325 149.246 230.642 156.08 195.441 157.302 203.641 205.694 273.74 457.233"/>
    <path fill="#fff" stroke-width="0" d="m292.392,443.904l-75.671-278.279c27.209-6.113,55.132-9.203,83.279-9.203s56.07,3.09,83.278,9.203l-75.671,278.279h-15.216Z"/>
    <path class="transition-all duration-1000 ease-in-out" stroke-width="0" [attr.d]="pathData" fill="#14ff00"/>
    <!-- <path class="needle transition-transform duration-1000 ease-in-out" stroke-width="0px" fill="#1f1f1f" d="m305.825,424.352c6.904-2.736,11.567-7.92,11.567-13.869,0-8.798-10.189-15.931-22.758-15.931-.035,0-.069.002-.104.002-.004-.003-.008-.007-.012-.01l-.229.015c-.224.002-.448.007-.67.014-.786.024-148.804,11.336-195.063,14.872v2.088c34.003,2.597,122.982,9.392,168.031,12.819h39.238Z" -->
    [attr.transform]="needleTransform"/>
  </svg>
</div>
