import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameController } from 'src/app/controllers/game.controller';
import { FetchState } from 'src/app/graphql.module';
import { Game, GameStatus } from 'src/app/models/game.model';
import { Question } from 'src/app/models/question.model';
import { Team } from 'src/app/models/team.model';
import { VideoInteraction, VideoMarker, VideoMarkerType } from 'src/app/models/video-interaction.model';
import { GyroscopeService, MotionAnalysis } from 'src/app/services/gyroscope.service';
import { NavControlService } from 'src/app/services/nav-control.service';

@Component({
  selector: 'app-video-interaction',
  templateUrl: './video-interaction.component.html',
  styleUrls: ['./video-interaction.component.css']
})
export class VideoInteractionComponent implements OnInit {

  @ViewChild('gameElement') gameElement: ElementRef<any>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly gameController: GameController,
    private readonly navControl: NavControlService,
    // protected readonly gyroService: GyroscopeService,
  ) {
    // this.start = this.start.bind(this);
  }

  get game(): Game | null {
    return this.gameController?.game;
  }

  get currentInteractionAsQuestion(): Question | null {
    if (!this.gameController.game) {
      return null;
    }
    return this.gameController.game.currentInteractions.find(interaction => (interaction as any) instanceof Question) as Question;
  }

  ngOnInit(): void {
    // see if we have a game in the route
    const gameId = this.route.snapshot.paramMap.get('gameId');
    if (gameId) {
      console.log('gameId', gameId);
      this.gameController.initiateNewGame(gameId, null, null, true);
    }
    else if (!this.gameController?.game) {
      // see if we have a game in the local storage
      const game = localStorage.getItem('gameId');
      console.log('no game, initiating new game');
      this.gameController.initiateNewGame("1");
    }

  }

  private _fullscreenElem: any = null;
  start() {
    if (!!this.gameElement?.nativeElement && !this._fullscreenElem) {
      // this.navControl.toggleFullscreen(this.gameElement.nativeElement);
      const elem = this.gameElement.nativeElement;
      if (elem.requestFullscreen) {
        this._fullscreenElem = elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        this._fullscreenElem = elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        this._fullscreenElem = elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        this._fullscreenElem = elem.msRequestFullscreen();
      }
    }
    // console.log(`starting gyro`);
    // await this.startGyro();
  }

  // gyroState: FetchState = FetchState.NONE;
  // motionPermission = null;
  // get averages(): { averageDistance: number, cycleCount: number, averageCycleTime: number, averageFrequency: number } {
  //   return this.gyroService.averages;
  // }
  // get comparisons(): MotionAnalysis {
  //   return this.gyroService.comparisons
  // }
  // get ideal(): MotionAnalysis {
  //   return this.gyroService.ideal;
  // }
  // get tooShallow(): MotionAnalysis {
  //   return this.gyroService.tooShallow;
  // }

  // async startGyro() {
  //   this.gyroState = FetchState.LOADING;
  //   this.motionPermission = await this.gyroService.requestMotionPermission();
  //   console.log('motionPermission', this.motionPermission);
  //   // this.orientationPermission = await this.gyroService.requestOrientationPermission();
  //   // console.log('orientationPermission', this.orientationPermission);
  //   if (this.motionPermission) {
  //     this.gyroState = FetchState.LOADED_ALL;
  //     this.gyroService.startListening();
  //   }
  //   else {
  //     this.gyroState = FetchState.ERROR;
  //   }
  //   this.gyroService.startListening();
  // }

  // get distances(): Array<number> {
  //   return [];
  //   // return this.gyroService.distances;
  // }


  onSceneEnd(event: VideoInteraction): void {
    console.log(event);
  }

  submitNextQuestion(): void {
    console.log("submitNextQuestion");
  }
}
