import { Injectable } from "@angular/core";
import { Game, Scene } from "../models/game.model";
import { CPRCompleteSession, Question } from "../models/question.model";
import { Player, Team } from "../models/team.model";
import { CPRSession, FinalResultsInteraction, ResultsInteraction, SummaryInteraction, TeamScoreInteraction, VideoInteraction } from "../models/video-interaction.model";
import { GameService } from "../services/game.service";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
import { HistogramData, MotionData } from "../services/gyroscope.service";


@Injectable({
  providedIn: 'root'
})
export class GameController {

  static TIME_OFFSET = 50;

  seekVideo: Subject<number> = new Subject<number>();
  setVideoTime(time: number) {
    this.seekVideo.next(time);
  }

  constructor(
    private readonly gameService: GameService
  ) {
    // bind methods
    this.loadGame = this.loadGame.bind(this);
    this.initiateNewGame = this.initiateNewGame.bind(this);
    this.stopGame = this.stopGame.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.scorePoint = this.scorePoint.bind(this);
    this.handleCorrectAnswer = this.handleCorrectAnswer.bind(this);

  }

  game$: BehaviorSubject<Game | null> = new BehaviorSubject<Game | null>(null);

  get game(): Game {
    return this.game$.value;
  }
  private set game(game: Game) {
    if (!!game) {
      // save the game id to local storage
      localStorage.setItem('gameId', game.id);
      game.buildScenes();
    }
    else {
      localStorage.removeItem('gameId');
    }
    this.game$.next(game);
  }

  get scenes(): Scene[] {
    return this.game?.scenes || [];
    // if (!this.game) {
    //   return [];
    // }
    // let scenes: Scene[] = [];
    // let currentScene: Scene = null;
    // for (let interaction of this.game.interactions) {
    //   if (!currentScene) {
    //     currentScene = { start: scenes.length === 0 ? 0 : scenes[scenes.length-1].end, end: 0, name: interaction.scene, interactions: [interaction] };
    //     scenes.push(currentScene);
    //   }
    //   else if (currentScene.name !== interaction.scene) {
    //     currentScene = { start: scenes.length === 0 ? 0 : scenes[scenes.length-1].end, end: 0, name: interaction.scene, interactions: [interaction] };
    //     scenes.push(currentScene);
    //   }
    //   else {
    //     currentScene.interactions.push(interaction);
    //   }
    //   currentScene.end = Math.max(currentScene.end, interaction.markers.find(marker => marker.type === 'END')?.timestamp || 0);
    // }
    // return scenes;
  }

  get currentInteractions(): VideoInteraction[] {
    return this.game?.currentInteractions;
  }

  private _selectedPlayer: Player | null = null;
  get selectedPlayer(): Player | null {
    return this._selectedPlayer;
  }
  toggleSelectedPlayer(player: Player | null) {
    if (!player) {
      this._selectedPlayer = null;
      return;
    }
    if (this._selectedPlayer?.id === player.id) {
      this._selectedPlayer = null;
      return;
    }
    this._selectedPlayer = player;
  }

  addCurrentInteractions(interactions: VideoInteraction[]): void {
    if (!this.game) {
      return;
    }
    if (!interactions) {
      this.game.currentInteractionIndices = [];
      return;
    }
    let currentInteractionIndices = interactions.map(interaction => this.game.interactions.indexOf(interaction));
    for(let i = currentInteractionIndices.length - 1; i >= 0; i--) {
      if (currentInteractionIndices[i] >= 0 && !this.game.currentInteractionIndices.includes(currentInteractionIndices[i])) {
        this.game.currentInteractionIndices.push(currentInteractionIndices[i]);
      }
    }

    let question = interactions.find(interaction => interaction instanceof Question) as Question;

    if (!!question) {
      // If the video interaction has an assigned team index, then set the current team
      if (question.hasOwnProperty('teamIndex') && question['teamIndex'] >= 0) {
        this.game.currentTeamIndex = question['teamIndex'];
      }
      // Else if this is a question, then set the current team if it's not already set
      if (!!question) {
        if (!this.game.currentTeam) {
          this.game.currentTeam = this.game.teams[0];
        }
      }
    }
    else {
      this.game.currentTeam = null;
    }

    this.teamAnswering = this.game.currentTeam;

    // save the game
    this.saveGame();
  }

  removeCurrentInteraction(interaction: VideoInteraction) {
    if (!this.game.currentInteractions.length || !interaction) {
      return;
    }
    if (interaction instanceof Question) {
      console.log(`Removing question ${interaction.id} from current interactions`);
    }
    else if (interaction instanceof CPRSession) {
      console.log(`Removing CPR session ${interaction.id} from current interactions`);
    }
    for(let i = this.game.currentInteractionIndices.length - 1; i >= 0; i--) {
      if (this.game.currentInteractions[i].id === interaction.id) {
        this.game.currentInteractionIndices.splice(i, 1);
      }
    }

    // save the game
    this.saveGame();
  }

  async initiateNewGame(gameId?: string, gameType: string = "athlete_v21", numberOfTeams: number = 8, subscribeToPlayerData: boolean = false): Promise<Game> {
    this.game = await this.gameService.startGame(gameId, gameType, numberOfTeams);

    if (!this.game) {
      console.warn('Game not created');
      return null;
    }

    if (this.game.allowsPlayersToJoin && subscribeToPlayerData) {
      await this.subscribeToPlayerData();
    }
    return this.game;
  }

  playerData$: BehaviorSubject<Player[]> = new BehaviorSubject<Player[]>([]);

  subscribedToPlayerData$ = new BehaviorSubject<boolean>(false);
  private _playerDataSubscription: Subscription | null = null;
  async subscribeToPlayerData(): Promise<Game> {
    if (!this.game.allowsPlayersToJoin) {
      console.warn('subscribeToPlayerData -> Game has no players');
      return this.game;
    }
    if (!!this._playerDataSubscription && !this._playerDataSubscription.closed) {
      return this.game;
    }
    this.subscribedToPlayerData$.next(true);

    let currentCprSession = this.currentCprSessions[0];
    this._playerDataSubscription = this.gameService.subscribeToPlayerData(this.game.id, `${currentCprSession?.groupNumber || ''}`)
    .subscribe({
      next: (data) => {
        // console.log('Player data', data);
        // Map the player data back into the game object
        if (!!this.game) {
          (data || []).forEach(playerData => {
            let team = this.game.teams.find(team => team.id === playerData.teamId || team.players.find(player => player.id === playerData.playerId));
            if (!team) {
              console.warn(`Player ${playerData.playerId} not found in any team`);
              return;
            }
            // make sure the player is in the team
            let player = team.players.find(player => player.id === playerData.playerId);
            if (!player) {
              player = new Player({id: playerData.playerId, ...playerData});
              team.players.push(player);
            }

            // update the player data
            if (player.score !== playerData.score) {
              console.log(`Updating player ${player.id} score from ${player.score} to ${playerData.score}`);
              player.score = playerData.score;
            }
            if (!!playerData.histogramData) {
              player.histogramData = new HistogramData(playerData.histogramData);
            }
          });

          // update the player data
          let players = this.game.teams.reduce((players, team) => players.concat(team.players), []);
          this.playerData$.next(players);
        }
      },
      error: (error) => {
        console.error(error);
      }
    });

    return this.game;
  }

  unsubscribeFromPlayerData() {
    console.log('Unsubscribing from player data');
    this.subscribedToPlayerData$.next(false);
    if (!!this._playerDataSubscription) {
      this._playerDataSubscription.unsubscribe();
      this._playerDataSubscription = null;
    }
    this.gameService.unsubscribeFromPlayerData();
  }

  // subscribe to the game by setting up a recurring interval
  subscribeToGame(gameId: string) {
    this.gameService.loadAndSubscribeToGame(gameId).subscribe({
      next: (game) => {
        this.game = game;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private _gameKillSwitch$ = new BehaviorSubject<boolean>(false);
  async loadGame(gameId?: string) {
    // if (!gameId) {
      // Create a new game
      await this.initiateNewGame(gameId);
      return this.game;
    // }

    // Load the game
    this.gameService.loadAndSubscribeToGame(gameId).pipe(
      takeUntil(this._gameKillSwitch$)
    ).subscribe({
      next: (game) => {
        console.log('GameController game', game);
        this.game = game;
      },
      error: async (error) => {
        this.game$.error(error);
        // // If no game, then create a new one
        // this.game = this.initiateNewGame();
        // // console.error(error);
      }
    });
    return this.game;
  }

  async stopGame() {
    if (!!this.game) {
      await this.gameService.stopGame(this.game.id);
    }
  }

  async saveGame() {
    if (!!this.game) {
      await this.gameService.saveGame(this.game);
    }
  }

  submitQuestion(question: Question): void {
    if (!this.game) {
      return;
    }
    this.gameService.submitQuestion(this.game.id, question);
  }

  submitAnswer(playerId: string, answer: string, interaction: Question): void {
    if (!this.game?.id || !interaction.id || !interaction.answer) {
      return;
    }
    this.gameService.submitAnswer(this.game.id, interaction.id, playerId, answer);
  }

  scorePoint(playerId: string): void {
    if (!this.game) {
      return;
    }
    this.gameService.scorePoint(this.game.id, playerId);
  }

  private _teamAnswering: Team | null = null;
  get teamAnswering(): Team | null {
    return this._teamAnswering;
  }
  set teamAnswering(team: Team | null) {
    this._teamAnswering = team;
  }
  toggleTeamAnswering() {
    if (!this.game?.currentInteractions?.length) return;
    let index = this.game.teams.indexOf(this.teamAnswering);
    index = (index + 1) % this.game.teams.length;
    this.teamAnswering = this.game.teams[index];
  }

  toggleCurrentTeam() {
    if (!this.game?.currentInteractions?.length) return;
    let index = this.game.teams.indexOf(this.game.currentTeam);
    index = (index + 1) % this.game.teams.length;
    this.game.currentTeam = this.game.teams[index];
  }


  handleCorrectAnswer(answer: string): void {
    if (!this.game || !this.game?.currentInteractions?.length) {
      return;
    }

    // Set the current question to answered
    let question = this.game.currentQuestion;
    if (!!question) {
      // if this is a CPR Complete Session, then depending on the team index, one or both teams may get points
      if (question instanceof CPRCompleteSession) {
        question.checkAnswer(answer);
        if (question.teamIndex === 0) {
          this.game.teams[0].incrementScore(question.pointsValue || 40);
        }
        else if (question.teamIndex === 1) {
          this.game.teams[1].incrementScore(question.pointsValue || 40);
        }
        else {
          this.game.teams[0].incrementScore(question.pointsValue || 20);
          this.game.teams[1].incrementScore(question.pointsValue || 20);
        }
      }
      else {
        if (!this.teamAnswering) {
          if (this.game.currentTeamIndex < 0) {
            this.game.currentTeamIndex = 0;
          }
          this.teamAnswering = this.game.currentTeam;
        }
        if (this.teamAnswering) {
          question.answeredBy = this.teamAnswering.id;
          // Increment the score of the team that answered correctly
          this.teamAnswering.incrementScore(question.pointsValue || 20);
        }

        // Iterate to the next team
        this.toggleCurrentTeam();
      }
    }
    // this.sceneEnd.emit(this.game.currentQuestion);
    // this.game.currentQuestionIndex = -1;

    // // If the question has no end point, then end the question
    // if (!(this.game.currentInteraction!.markers || []).some(marker => marker.type === VideoMarkerType.END)) {
    //   this.sceneEnd.emit(this.gameController.game.currentInteraction);
    //   this.game.currentInteractionIndex = -1;
    //   this.showInteraction = false;
    // }
  }

  handleIncorrectAnswer(): void {
    let question = this.game.currentInteractions.find(interaction => (interaction as any) instanceof Question) as Question;
    if (!!question) {
      question.selectedAnswer = "";
    }
    this.toggleTeamAnswering();
  }


  /////////////////////////////
  // End Game
  /////////////////////////////

  async resetGame(gameId: string) {
    try {
      await this.gameService.stopGame(gameId);
    }
    catch (e) {
      console.error(e);
    }
    this.game = null;
    localStorage.removeItem('gameId');
  }


  /////////////////////////////
  // INTERACTIONS
  /////////////////////////////
  public currentInteractionsByType(type): VideoInteraction[] {
    return (this.currentInteractions || []).filter(interaction => interaction instanceof type) || [];
  }

  get currentQuestions(): Question[] {
    return this.currentInteractionsByType(Question) as Question[];
  }
  get currentCprSessions(): CPRSession[] {
    return this.currentInteractionsByType(CPRSession) as CPRSession[];
  }
  get currentResults(): ResultsInteraction[] {
    return this.currentInteractionsByType(ResultsInteraction) as ResultsInteraction[];
  }
  get currentFinalResults(): FinalResultsInteraction[] {
    return this.currentInteractionsByType(FinalResultsInteraction) as FinalResultsInteraction[];
  }
  get currentTeamScores(): TeamScoreInteraction[] {
    return this.currentInteractionsByType(TeamScoreInteraction) as TeamScoreInteraction[];
  }
  get currentSummaries(): SummaryInteraction[] {
    return this.currentInteractionsByType(SummaryInteraction) as SummaryInteraction[];
  }

}
