import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FetchState } from './graphql.module';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  baseUrl: string = '';
  private isProd: boolean = false;
  public configState: FetchState = FetchState.NONE;

  constructor(
    // private location: Location,
    private readonly apollo: Apollo
  ) {

    this.baseUrl = window.location.origin;

    this.configState = FetchState.LOADING;
    // this.apollo.query({
    //   query: GetZealsAppConfigQuery
    // }).subscribe({
    //   next: (result) => {
    //     if (result?.data && result.data['getGetZealsAppConfig']) {
      // let appConfig = result.data['getGetZealsAppConfig'];
        let appConfig: any = {
          // basuUrl: 'https://example.com',
          prodEnv: false
        }
          this.configState = FetchState.LOADED_ALL;
          this.baseUrl = appConfig.baseUrl || this.baseUrl;
          this.isProd = appConfig.prodEnv;
    //     }
    //   },
    //   error: (error) => {
    //     console.error(error);
    //     this.configState = FetchState.ERROR;
    //   }
    // });
  }
}
