export class RealTimePeakDetector {
  private buffer: number[] = [];
  private smoothedBuffer: number[] = [];
  private velocity: number = 0; // Current velocity
  private segmentDisplacement: number = 0; // Displacement for the current segment
  private alpha: number;
  private threshold: number;
  private deltaUnit?: string; // Unit of deltaT (seconds, milliseconds, or nanoseconds)
  private deltaNormalizer: number = 1; // Normalizer for converting to seconds
  private cumulativeTime: number = 0; // Total time elapsed
  private lastPeakTime: { max?: number; min?: number } = {}; // Time of the last detected max and min peaks
  private zValues: Partial<{ z: number; timestamp: number }>[] = []; // Stores z-values and timestamps for the current segment
  private valueCount: number = 0; // Total count of values in the current segment
  private currentTimestamp: number; // Tracks the current timestamp
  private lastPeakType?: 'max' | 'min'; // Type of the last detected peak

  constructor(alpha: number = 0.2, threshold: number = 0.2) {
    this.alpha = alpha; // Smoothing factor for EMA
    this.threshold = threshold; // Threshold for peak detection
    this.currentTimestamp = Date.now(); // Initialize the first timestamp
  }

  update(
    newDataPoint: number,
    deltaT: number
  ): {
    peakDetected: boolean;
    period?: number;
    displacement?: number;
    peakValue?: number;
    totalValues?: number;
    zValues?: Partial<{ z: number; timestamp: number }>[];
  } {
    // Determine the time unit for deltaT
    if (!this.deltaUnit) {
      if (deltaT > 1_000_000) {
        this.deltaUnit = 'nanoseconds';
        this.deltaNormalizer = 1_000_000_000;
      } else if (deltaT > 1_000) {
        this.deltaUnit = 'milliseconds';
        this.deltaNormalizer = 1_000;
      } else {
        this.deltaUnit = 'seconds';
        this.deltaNormalizer = 1;
      }
    }

    // Normalize deltaT to seconds
    const normalizedDeltaT = deltaT / this.deltaNormalizer;

    // Update cumulative time
    this.cumulativeTime += normalizedDeltaT;

    // Increment the current timestamp
    this.currentTimestamp += (deltaT / this.deltaNormalizer) * 1000; // Convert seconds to milliseconds

    // Add the current value and timestamp to zValues and increment the count
    this.zValues.push({ z: newDataPoint, timestamp: this.currentTimestamp });
    this.valueCount++;

    // Update buffer with the new data point
    this.buffer.push(newDataPoint);

    // Apply exponential moving average (EMA) for smoothing
    const smoothedPoint =
      this.buffer.length === 1
        ? newDataPoint // First point, no smoothing needed
        : this.alpha * newDataPoint +
          (1 - this.alpha) * this.smoothedBuffer[this.smoothedBuffer.length - 1];
    this.smoothedBuffer.push(smoothedPoint);

    // Keep the buffer limited to 3 for peak detection
    if (this.buffer.length > 3) {
      this.buffer.shift();
      this.smoothedBuffer.shift();
    }

    // Integrate acceleration to velocity
    this.velocity += smoothedPoint * normalizedDeltaT;

    // Integrate velocity to segment displacement
    this.segmentDisplacement += this.velocity * normalizedDeltaT;

    // Check for a peak when we have enough data points
    if (this.smoothedBuffer.length === 3) {
      const [prev, current, next] = this.smoothedBuffer;

      let peakType: 'max' | 'min' | undefined;

      // Detect maxima
      if (
        current > prev &&
        current > next &&
        current > this.threshold
      ) {
        peakType = 'max';
      }
      // Detect minima
      else if (
        current < prev &&
        current < next &&
        current < -this.threshold
      ) {
        peakType = 'min';
      }

      if (peakType) {
        let peakDisplacement: number | undefined = undefined;
        let period: number | undefined = undefined;

        // Report the displacement for this peak
        peakDisplacement = this.segmentDisplacement;

        // Calculate period if we have a previous peak of the same type
        if (this.lastPeakTime[peakType] !== undefined) {
          period = this.cumulativeTime - this.lastPeakTime[peakType]!;
        }

        // Update last peak time for this peak type
        this.lastPeakTime[peakType] = this.cumulativeTime;

        // Prepare z-values and count for the detected period
        const zValuesInPeriod = [...this.zValues];
        const totalValuesInPeriod = this.valueCount;

        // Reset displacement, zValues, and count for the next segment
        this.segmentDisplacement = 0;
        this.zValues = [];
        this.valueCount = 0;

        // Update last peak type
        this.lastPeakType = peakType;

        return {
          peakDetected: true,
          period: period,
          displacement: peakDisplacement,
          peakValue: current,
          totalValues: totalValuesInPeriod,
          zValues: zValuesInPeriod,
        };
      }
    }

    return { peakDetected: false };
  }
}
