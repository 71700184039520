<!-- animate size change -->
<!-- <div class="flex flex-col w-full h-full relative border-4 border-yellow-500 team-transition"
  [ngClass]="{'border-opacity-75': isCurrentTeam, 'border-opacity-0': !isCurrentTeam}"> -->
<!-- <div class="flex flex-col relative border-4 border-yellow-500 team-transition {{containerClass}}"> -->
<div class="flex flex-col justify-center relative border-4 border-yellow-500 team-transition {{containerClass}}"
[ngStyle]="containerStyle"
>
  <div class="flex flex-col justify-center items-center gap-0 mt-4 top-2 left-0 right-0 team-transition">
    <div class="text-center font-['Arial'] font-normal team-transition leading-none"
    [ngStyle]="teamStyle"
    [ngClass]="{
      'text-yellow-400 tracking-[3.38px] leading-[0.9]': displayState === DisplayState.ANSWERING,
      'text-white tracking-[2.50px]': displayState !== DisplayState.ANSWERING
    }">TEAM</div>
    <div class="text-center text-white font-bold font-['Arial'] uppercase w-full team-transition leading-none"
    [ngStyle]="nameStyle"
    [ngClass]="{
      'tracking-widest': displayState === DisplayState.ANSWERING,
      'tracking-wider': displayState !== DisplayState.ANSWERING
    }"
    >{{name}}</div>
    <div
    class="w-[70%] mt-2 h-[0px] border border-white team-transition"
    [ngClass]="{
      'border-opacity-100': displayState !== DisplayState.ANSWERING,
      'border-opacity-0': displayState === DisplayState.ANSWERING
    }"
    ></div>

    <div class="text-center text-opacity-80 font-normal font-scoreboard team-transition "
    [ngStyle]="scoreStyle"
    [ngClass]="{
      'text-amber-400 tracking-[5.40px] leading-[1]': displayState === DisplayState.ANSWERING,
      'text-yellow-400 tracking-[4px] text-opacity-80 leading-tight': displayState !== DisplayState.ANSWERING
    }"
    >{{teamScore}}</div>
    <!-- >{{team?.totalScore}}</div> -->
  </div>
  <!-- <img src="assets/Orb.svg" class="w-[50px] h-[50px] absolute top-[42px] team-transition" -->
  <img src="assets/Orb.svg" class="w-[3.5vw] absolute top-[25%] team-transition aspect-square"
  [ngClass]="{
    'opacity-0': displayState !== DisplayState.ANSWERING,
    'opacity-100': displayState === DisplayState.ANSWERING,
    '-right-[5vw]': teamIndex === 0,
    '-left-[5vw]': teamIndex === 1
  }"
  />
</div>
