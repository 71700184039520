import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../models/question.model';
import { Player } from '../models/team.model';
import { GameController } from '../controllers/game.controller';
import { InteractionDisplayType } from '../models/video-interaction.model';
import { sentenceCase, titleCase } from '../utils/string.utils';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {
  private _question?: Question | undefined = undefined;
  @Input()
  set question(value) {
    this._question = value;
  }
  get question() {
    return this._question;
  }
  @Input() interactable: boolean = true;
  @Output() answerSubmitted = new EventEmitter<string>();

  InteractiveDisplayType = InteractionDisplayType;

  constructor(
    private readonly gameController: GameController,
  ) {}

  showQuestion: boolean = true;
  get selectedAnswer(): string {
    return this.question?.selectedAnswer || '';
  }

  getStyle(index: number = -1): any {
    if (index === -1) {
      return {};
    }
    return this.question?.styles[index] || {};
  }

  submitAnswer(option: string): void {
    if (!this.question) {
      return;
    }
    this.question.selectedAnswer = option;
    this.answerSubmitted.emit(option);
    // this.showQuestion = false;
  }

  displayQuestion(): void {
    this.showQuestion = true;
  }

  getPlayerById(id: string): Player | undefined {
    return this.gameController.game?.teams?.flatMap(t => t.players).find(p => p.id === id);
  }

  cleanQuestion(question: string): string {
    return sentenceCase(question);
  }

  cleanAnswer(answer: string): string {
    return sentenceCase(answer);
  }

  get isImageQuestion(): boolean {
    return (this.question.displayType === this.InteractiveDisplayType.MULTIPLE_CHOICE_IMAGE) || (this.question.answer?.endsWith('.png') || this.question.answer?.endsWith('.jpg') || this.question.answer?.endsWith('.jpeg') || this.question.answer?.endsWith('.gif') || this.question.answer?.endsWith('.webp'));
  }

  getImageSrc(option: string): string {
    let result = `${option}`;
    let lastIndex = result.lastIndexOf('.');

    // if this is the selected answer
    if (this.selectedAnswer === option) {
      // if this is the correct answer
      if (this.question?.answer === option) {
        // append _correct to the image name, before the extension
        if (lastIndex !== -1) {
          // result already has the extension
          result = result.substring(0, lastIndex) + '_correct' + result.substring(lastIndex);
        }
        else {
          // result does not have the extension
          result += '_correct';
        }
      }
      // else if this is the incorrect answer
      else {
        // append _incorrect to the image name, before the extension
        if (lastIndex !== -1) {
          // result already has the extension
          result = result.substring(0, lastIndex) + '_incorrect' + result.substring(lastIndex);
        }
        else {
          // result does not have the extension
          result += '_incorrect';
        }
      }
    }

    return result;
  }

}
