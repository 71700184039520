import { GameController } from "../controllers/game.controller";
import { equalsIgnoreCase } from "../utils/string.utils";
import { CPRCompleteSession, Question } from "./question.model";
import { Team } from "./team.model";
import { BaseInteraction, CPRSession, FinalResultsInteraction, InteractionDisplayType, JumpInteraction, ResultsInteraction, SummaryInteraction, TeamScoreInteraction, VideoInteraction, VideoMarkerType } from "./video-interaction.model";


export type Scene = {
  start: number;
  end: number;
  name: string;
  interactions: VideoInteraction[];
}


export enum GameStatus {
  SETUP = 'SETUP',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

// Game model
export class Game {

  constructor({id, status, name, dateCreated, dateEnded, teams, maxNumPlayers = -1, interactions, videoUrl, lastTimestamp}: {id: string, status: GameStatus, name: string, dateCreated?: Date, dateEnded?: Date, teams: Team[], maxNumPlayers?: number, interactions?: Array<Partial<VideoInteraction>>, videoUrl: string, lastTimestamp?: number}) {
    this.id = id;
    this.status = status;
    this.name = name;
    this.dateCreated = dateCreated || new Date();
    this.dateEnded = dateEnded;
    this.videoUrl = videoUrl;
    this.maxNumPlayers = maxNumPlayers;
    this.teams = [];
    for(const team of teams) {
      this.teams.push(new Team(team));
    }
    if (interactions) {
      this.interactions = [];
      for (const interaction of interactions) {
        if (interaction['__typename'] === 'Question' || interaction instanceof Question) {
          this.interactions.push(new Question(interaction as any));
        }
        else if (interaction['__typename'] === 'CPRSession') {
          this.interactions.push(new CPRSession(interaction as any));

          // if there is a COMPLETE_SESSION marker, add a question to the end of the session
          let completeSessionPoints = (interaction.markers || []).filter(marker => marker.type === VideoMarkerType.COMPLETE_SESSION);
          for(let finishPoint of completeSessionPoints) {
            this.interactions.push(new CPRCompleteSession({timestamp: finishPoint.timestamp}));
          }
        }
        else if (interaction['__typename'] === 'JumpInteraction') {
          this.interactions.push(new JumpInteraction(interaction as any));
        }
        else if (interaction['__typename'] === 'ResultsInteraction') {
          this.interactions.push(new ResultsInteraction(interaction as any));
        }
        else if (interaction['__typename'] === 'FinalResultsInteraction') {
          this.interactions.push(new FinalResultsInteraction(interaction as any));
        }
        else if (interaction['__typename'] === 'TeamScoreInteraction') {
          this.interactions.push(new TeamScoreInteraction(interaction as any));
        }
        else if (interaction['__typename'] === 'SummaryInteraction') {
          this.interactions.push(new SummaryInteraction(interaction as any));
        }
        else if (interaction['__typename'] === 'BaseInteraction') {
          this.interactions.push(new BaseInteraction(interaction as any));
        }
      }
      this.lastTimestamp = lastTimestamp || 0;
    }
    else {
      this.interactions = [];
    }
    this.buildScenes();
  }

  get numberOfPlayers(): number {
    return (this.teams || []).reduce((acc, team) => acc + (team.players || []).length, 0);
  }

  currentInteractionIndices: number[] = [];
  completeCprQuestion: Question = new Question({
    id: 'COMPLETE_CPR_SESSION_QUESTION',
    answer: '',
    question: 'Who won that CPR Session?',
    displayType: InteractionDisplayType.MULTIPLE_CHOICE_TEXT,
    options: ['Team Cardiac', 'Team Star', 'Tie'],
    pointsValue: 40,
    teamIndex: -1
  });
  get currentInteractions(): VideoInteraction[] | [] {
    return (this.currentInteractionIndices || []).map(index => this.interactions[index]).filter(interaction => !!interaction);
    // return this.currentInteractionIndices >= 0 && this.interactions?.length > this.currentInteractionIndices ? this.interactions[this.currentInteractionIndices] : null;
  }
  get currentQuestion(): Question | null {
    let results = (this.currentInteractions || []).filter(interaction => (interaction as any) instanceof Question) as Question[];
    if (results.length > 0) {
      return results[0];
    }

    // see if we are in the middle of a CPR session at the COMPLETE_SESSION marker
    let cprCompleteSessions = (this.currentInteractions || []).filter(interaction => (interaction as any) instanceof CPRCompleteSession) as CPRCompleteSession[];
    for(let currentInteraction of cprCompleteSessions) {
      let completeSessionPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.COMPLETE_SESSION);
      for(let finishPoint of completeSessionPoints) {
        if (this.currentTimestamp <= finishPoint.timestamp && finishPoint.timestamp <= (this.currentTimestamp + GameController.TIME_OFFSET)) {
          // return the CPRCompleteSession
          return currentInteraction;
        }
      }
    }

    return null;
  }

  lastTimestamp: number = 0;
  private _currentTimestamp: number = 0;
  get currentTimestamp(): number {
    return this._currentTimestamp;
  }
  set currentTimestamp(timestamp: number) {
    this._currentTimestamp = Math.round(timestamp);
  }
  currentPaused: boolean = false;

  currentTeamIndex: number = -1;
  get currentTeam(): Team | null {
    return this.currentTeamIndex >= 0 && this.teams?.length > this.currentTeamIndex ? this.teams[this.currentTeamIndex] : null;
  }
  set currentTeam(team: Team | null) {
    if (team) {
      this.currentTeamIndex = this.teams.indexOf(team);
    }
    else {
      this.currentTeamIndex = -1;
    }
  }

  maxNumPlayers: number = -1;
  get allowsPlayersToJoin(): boolean {
    // return this.maxNumPlayers === -1;
    return this.maxNumPlayers !== 0;
  }

  scenes: Scene[] = [];
  buildScenes(): Scene[] {
    this._firstInteractionTimestamp = -1;

    let scenes: Scene[] = [];
    let currentScene: Scene = null;
    for (let interaction of this.interactions.filter(interaction => interaction.markers.length > 0)) {
      if (interaction instanceof JumpInteraction) {
        continue;
      }
      // if ((interaction instanceof Question) || (interaction instanceof CPRSession)) {
        this._firstInteractionTimestamp = this.firstInteractionTimestamp === -1 ? interaction.markers[0].timestamp : Math.min(this.firstInteractionTimestamp, interaction.markers[0].timestamp);
      // }
      let interactionScene = !!interaction.scene ? interaction.scene : (currentScene.name || 'Default');
      currentScene = scenes.find(scene => equalsIgnoreCase(scene.name, interactionScene));
      if (!currentScene) {
        currentScene = { start: scenes.length === 0 ? 0 : scenes[scenes.length-1].end, end: 0, name: interactionScene, interactions: [interaction] };
        scenes.push(currentScene);
      }
      else {
        currentScene.interactions.push(interaction);
      }
      currentScene.end = Math.max(currentScene.end, interaction.markers.find(marker => marker.type === 'END')?.timestamp || 0);
    }
    this.scenes = scenes;
    return scenes;
  }


  overwrite(game: Game) {
    this.id = game.id;
    this.status = game.status;
    this.name = game.name;
    this.dateCreated = game.dateCreated;
    this.dateEnded = game.dateEnded;
    this.videoUrl = game.videoUrl;
    this.maxNumPlayers = game.maxNumPlayers;

    // Overwrite teams, update any existing teams, add new teams, remove any teams that don't exist anymore
    const newTeams: Team[] = [];
    for (const team of game.teams) {
      const existingTeam = this.teams.find(t => t.id === team.id);
      if (existingTeam) {
        existingTeam.overwrite(team);
        newTeams.push(existingTeam);
      }
      else {
        newTeams.push(new Team(team));
      }
    }
    this.teams = newTeams;

    if (game.interactions) {
      this.interactions = [];
      for (const question of game.interactions) {
        if (question instanceof Question) {
          this.interactions.push(new Question(question));
        }
        else if (question instanceof CPRSession) {
          this.interactions.push(new CPRSession(question));
        }
        else if (question instanceof JumpInteraction) {
          this.interactions.push(new JumpInteraction(question));
        }
        else if (question instanceof ResultsInteraction) {
          this.interactions.push(new ResultsInteraction(question));
        }
        else if (question instanceof FinalResultsInteraction) {
          this.interactions.push(new FinalResultsInteraction(question));
        }
        else if (question instanceof TeamScoreInteraction) {
          this.interactions.push(new TeamScoreInteraction(question));
        }
        else if (question instanceof SummaryInteraction) {
          this.interactions.push(new SummaryInteraction(question));
        }
        else if (question instanceof BaseInteraction) {
          this.interactions.push(new BaseInteraction(question));
        }
      }
    }
    else {
      this.interactions = [];
    }
    this.buildScenes();
  }

  id: string;
  status: GameStatus;
  name: string;
  dateCreated: Date;
  dateEnded?: Date;
  teams: Team[];
  interactions?: Array<VideoInteraction>;
  videoUrl: string;

  // @Transient
  private _firstInteractionTimestamp: number = -1;
  get firstInteractionTimestamp(): number {
    return this._firstInteractionTimestamp;
  }
}
