import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GAME_STATE, JOIN_GAME, UPDATE_PLAYER_GYRO_AND_ORIENTATION } from '../graphql/game.graphql';
import { Game, GameStatus } from '../models/game.model';
import { BehaviorSubject, catchError, map, Observable, Subject, takeUntil, throwError, of } from 'rxjs';
import { Player } from '../models/team.model';
import { TelemetryService } from './telemetry.service';
import { GyroscopeService } from './gyroscope.service';
import { BatchingService } from './batching.service';
import { switchMap } from 'rxjs/operators';

export type GameStateDetails = {
  id: string;
  status: GameStatus;
  lastTimestamp: number;
  teams: Array<{
    id: string
    name: string
    score: number
  }>
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  playerId: string;
  game: Game;
  player$: BehaviorSubject<Player> = new BehaviorSubject<Player | null>(null);


  constructor(
    private readonly apollo: Apollo,
    private telemetryService: TelemetryService,
    private gyroscopeService: GyroscopeService,
    private batchingService: BatchingService
  ) {
    const cachedPlayerId = localStorage.getItem('player_id');
    if (!!cachedPlayerId) {
      this.playerId = cachedPlayerId;
    }
    else {
      this.playerId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('player_id', this.playerId);
    }

    // Subscribe to gyroscope readings and apply smart batching
    this.gyroscopeService.motionReading$
      .pipe(
        // Apply dynamic batching based on connection status
        switchMap(reading => {
          const connectionType = this.telemetryService.isConnected() ? 'websocket' : 'graphql';
          return this.batchingService.applyBatching(
            of(reading), // Create observable from single reading
            connectionType
          );
        })
      )
      .subscribe(batch => {
        if (batch.length === 0) return;

        const gameId = this.game?.id;
        const gamePosition = this.getPlayerPosition();

        if (!gameId || !gamePosition) return;

        this.updatePlayerGyroAndOrientation(gameId, gamePosition, batch);
      });

    // Also update batching strategy when connection status changes
    this.telemetryService.connectionStatus$
      .subscribe(isConnected => {
        const connectionType = isConnected ? 'websocket' : 'graphql';
        this.batchingService.updateBatchingStrategy(connectionType);
      });
  }

  private _playerPosition: string;
  getPlayerPosition(): string {
    if (!this.game || !this.game.teams || !this.game.teams.length) {
      return '';
    }

    if (!!this._playerPosition) {
      return this._playerPosition;
    }

    this.game.teams.forEach(team => {
      if (team.players) {
        team.players.forEach(player => {
          if (player.id === this.playerId) {
            this._playerPosition = player.name;
          }
        });
      }
    });

    return this._playerPosition;
  }

  // Track websocket connection status
  get telemetryConnectionStatus$() {
    return this.telemetryService.connectionStatus$;
  }

  async updatePlayerGyroAndOrientation(gameId: string, gamePosition: string, zData: Partial<{ z: number; timestamp: number }>[]): Promise<void> {
    if (!gameId || gameId.length === 0 || !gamePosition || gamePosition.length === 0 || !zData?.length) {
      return;
    }

    const telemetryData = {
      gameId: gameId,
      gamePosition: gamePosition,
      timestamp: Date.now(),
      data: zData.map(d => ({
        z: d.z,
        timestamp: d.timestamp
      }))
    };

    try {
      // Check if WebSocket is connected
      // if (this.telemetryService.isConnected()) {
        // Send via WebSocket for real-time updates
        console.log('Sending telemetry via WebSocket');
        this.telemetryService.sendTelemetry(telemetryData);
      // } else {
      //   // WebSocket not connected, use GraphQL as backup
      //   console.log('WebSocket not connected, using GraphQL as backup');
      //   this.sendViaGraphQL(telemetryData);
      // }
    }
    catch (error) {
      console.warn('Error sending telemetry via WebSocket, falling back to GraphQL:', error);
      this.sendViaGraphQL(telemetryData);
    }
  }

  // Helper method to send telemetry via GraphQL
  private sendViaGraphQL(telemetryData: any): void {
    this.apollo.mutate({
      mutation: UPDATE_PLAYER_GYRO_AND_ORIENTATION,
      variables: {
        gameId: telemetryData.gameId,
        gamePosition: telemetryData.gamePosition,
        timestamp: telemetryData.timestamp,
        data: telemetryData.data
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Successfully sent telemetry via GraphQL');
        let playerData = (data as any)?.updatePlayerGyroAndOrientation;
        if (!!playerData) {
          this.player$.next(new Player(playerData));
        }
      },
      error: (error) => {
        console.error('GraphQL error updating player data:', error);
      }
    });
  }

  joinGame(gameId: string): Promise<Game> {
    if (!gameId || gameId.length === 0) {
      return Promise.reject('Invalid game ID');
    }

    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: JOIN_GAME,
        variables: {
          gameId: gameId,
          playerInput: {
            id: this.playerId
          },
        }
      }).subscribe({
        next: async ({data}) => {
          console.log('Joined game', data);
          const joinGameData = (data as any)?.joinGame;
          if (!!joinGameData) {
            this.game = new Game(joinGameData);

            // Initialize WebSocket connection when joining game
            try {
              await this.telemetryService.joinGame(gameId, this.playerId);
            } catch (err) {
              console.warn('Could not establish WebSocket connection, will use GraphQL fallback', err);
            }

            resolve(this.game);
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  private _gameSubscription: BehaviorSubject<GameStateDetails | null> = new BehaviorSubject<GameStateDetails | null>(null);
  private _gameKillSwitch$ = new Subject<void>();
  subscribeToGameState(game: Game | string): Observable<GameStateDetails> {
    let gameId: string = '';
    if (!game) {
      return this._gameSubscription;
    }
    if (game instanceof Game) {
      gameId = game.id;
      // this.game$.next(game);
    }
    else {
      gameId = game;
    }
    if (!!this._gameSubscription) {
      if (this._gameSubscription.getValue()?.id === gameId) {
        return this._gameSubscription;
      }
    }

    return this._subscribeToGameState(gameId);
  }

  private _gameInterval: any;
  private _subscribeToGameState(gameId: string): BehaviorSubject<GameStateDetails | null> {
    if (!!this._gameInterval) {
      clearInterval(this._gameInterval);
      this._gameInterval = null;
    }
    // Unsubscribe from the previous game
    if (!!this._gameSubscription) {
      // this.game$.next(null);
      this._gameKillSwitch$.next();
    }

    this.apollo
    .query({
      query: GAME_STATE,
      variables: {
        id: gameId,
      },
      fetchPolicy: 'no-cache',
    })
    .pipe(
      takeUntil(this._gameKillSwitch$),
      map(({ data }) => {
        console.log('Got game data', data);
        let gameData: any = (data as any)['fetchGame'];
        if (!!gameData) {
          return gameData;
        } else {
          console.error('Game not found');
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        // Handle the error here, such as displaying an error message or performing any necessary cleanup
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    )
    .subscribe({
      next: (game) => {
        console.log('Got game data', game);
        this._gameSubscription.next(game);

        // Poll the game every few seconds
        this._gameInterval = setInterval(() => {
          this.apollo.query({
            query: GAME_STATE,
            variables: {
              id: gameId,
            },
            fetchPolicy: 'no-cache',
          }).subscribe({
            next: ({data}) => {
              console.log('Got game data', data);
              let gameData: any = (data as any)['fetchGame'];
              if (!!gameData) {
                this._gameSubscription.next(gameData);
              }
            },
            error: (error) => {
              console.error(error);
            }
          });
        }, 2500);
      },
      error: (error) => {
        console.error(error);
      }
    });

    return this._gameSubscription;
  }
}
