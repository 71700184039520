import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { Game } from '../models/game.model';
import {
  SCORE_POINT, START_GAME, STOP_GAME, SUBMIT_QUESTION, SUBMIT_ANSWER,
  GAME, UPDATE_GAME
} from '../graphql/game.graphql';
import { Question } from '../models/question.model';
import { HistogramData } from './gyroscope.service';
import { PlayerResults } from '../models/team.model';
import { FetchState } from '../graphql.module';

export class PlayerData {

  constructor({playerId, teamId, name, score, histogramData, currentResults, finalResults}: {playerId: string, teamId: string, name: string, score?: number, histogramData: HistogramData, currentResults: any, finalResults: any}) {
    this.playerId = playerId;
    this.teamId = teamId;
    this.name = name;
    this.score = score;
    this.histogramData = new HistogramData(histogramData || {} as any);
    this.finalResults = finalResults ? new PlayerResults(finalResults) : null;
    this.currentResults = currentResults ? new PlayerResults(currentResults) : null;
  }

  playerId: string;
  teamId: string;
  name: string;
  score: number;
  histogramData: HistogramData;
  currentResults?: PlayerResults;
  finalResults?: PlayerResults;
}


@Injectable({
  providedIn: 'root'
})
export class GameService {

  private _t: string = 'diknvr_rki5n3i';

  constructor(
    private readonly apollo: Apollo
  ) {
    // bind methods
    this.fetchGame = this.fetchGame.bind(this);
    this.startGame = this.startGame.bind(this);
    this.stopGame = this.stopGame.bind(this);
    this.scorePoint = this.scorePoint.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  fetchGame(gameId: string): Observable<Game | null> {
    return this.apollo.query({
      query: GAME,
      variables: {
        id: gameId,
      }
    }).pipe(
      map(({ data }) => {
        console.log('Got game data', data);
        let gameData: any = (data as any)['fetchGame'];
        if (!!gameData) {
          const result = new Game(gameData);
          return result;
        } else {
          console.error('Game not found');
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        // Handle the error here, such as displaying an error message or performing any necessary cleanup
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    );
  }

  private _gameKillSwitch$ = new Subject<void>();
  private _destroyPlayerData$ = new Subject<void>();

  async startGame(gameId: string, gameType: string = "athlete_v21", numberOfTeams: number = 2): Promise<Game> {
    const that = this;

    return new Promise<Game>((resolve, reject) => {
      // Create subscription by starting the game
      that.apollo.mutate({
        mutation: START_GAME,
        variables: {
          id: gameId,
          numberOfTeams,
          gameType: gameType
        },
        context: {
          headers: {
            Authorization: `Bearer ${this._t}`
          }
        }
      }).subscribe({
        next: ({data}) => {
          let startGameData = (data as any)?.startGame;
          const gameId: string = startGameData?.id;
          if (!!gameId) {
            const game = new Game(startGameData);

            // Store the game in local storage
            localStorage.setItem('game_id', gameId);
            resolve(game);
          }
          else {
            console.error('Game ID not found');
            reject('Game ID not found');
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  async stopGame(gameId: string): Promise<boolean> {

    // Unsubscribe from the previous game
    if (!!this._gameKillSwitch$) {
      this._gameKillSwitch$.next();
      this._gameKillSwitch$.complete();
      this._gameKillSwitch$ = new Subject<void>();
    }

    // Remove the game from local storage
    localStorage.removeItem('game');

    if (!gameId) {
      return false;
    }

    // Stop the game
    return new Promise<boolean>((resolve, reject) => {
      this.apollo.mutate({
        mutation: STOP_GAME,
        variables: {
          id: gameId,
        }
      }).subscribe({
        next: ({data}) => {
          console.log('Stopped game', data);
          // this.game$.next(null);
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  saveGameState: FetchState = FetchState.NONE;
  get isSavingGame(): boolean {
    return this.saveGameState === FetchState.LOADING;
  }
  async saveGame(game: Game): Promise<Game> {
    if (!game) {
      return null;
    }

    // console.log('Saving game', game);

    this.saveGameState = FetchState.LOADING;

    // Save the game
    return new Promise<Game>((resolve, reject) => {
      this.apollo.mutate({
        mutation: UPDATE_GAME,
        variables: {
          id: game.id,
          status: game.status,
          timestamp: game.currentTimestamp,
          paused: game.currentPaused,
          teamScores: game.teams.map((team) => team.score),
        }
      }).subscribe({
        next: ({data}) => {
          // console.log('Saved game', data);
          resolve(new Game(data['updateGame']));
          this.saveGameState = FetchState.LOADED_ALL;
        },
        error: (error) => {
          console.error(error);
          this.saveGameState = FetchState.ERROR;
          reject(error);
        }
      });
    });
  }

  scorePoint(gameId: string, playerId: string): void {
    if (!gameId || !playerId) {
      return;
    }

    this.apollo.mutate({
      mutation: SCORE_POINT,
      variables: {
        id: gameId,
        playerId: playerId,
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Scored point', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  async submitQuestion(gameId: string, question: Question) {
    if (!gameId || !question) {
      return;
    }

    // Submit the question
    this.apollo.mutate({
      mutation: SUBMIT_QUESTION,
      variables: {
        gameId: gameId,
        id: question.id,
        question: question.question,
        answer: question.answer,
        options: question.options,
        styles: question.styles,
        timestamp: Date.now(),
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Question Submitted', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  async submitAnswer(gameId: string, interactionId: string,  playerId: string, answer: string) {
    if (!gameId || !interactionId || !playerId) {
      return;
    }

    // Submit the answer
    this.apollo.mutate({
      mutation: SUBMIT_ANSWER,
      variables: {
        gameId: gameId,
        questionId: interactionId,
        playerId: playerId,
        answer: answer,
      }
    }).subscribe({
      next: ({data}) => {
        console.log('Answer Submitted', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  ngOnDestroy() {
    this._destroyPlayerData$.next();
    this._destroyPlayerData$.complete();
  }

}
