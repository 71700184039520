import { Injectable } from '@angular/core';
import { Observable, bufferTime } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BatchingService {
  private _currentBatchSize: 'small' | 'medium' | 'large' = 'medium';
  private _batchConfigs = {
    small: { size: 5, interval: 500 },    // 5 readings every 500ms
    medium: { size: 10, interval: 1000 },  // 10 readings every 1s
    large: { size: 20, interval: 2000 }    // 20 readings every 2s
  };

  // Configurable batching strategies
  private batchingConfig = {
    websocket: {
      maxSize: 25,
      interval: 200,
      maxAge: 1000  // Maximum age of readings in a batch
    },
    graphql: {
      maxSize: 75,
      interval: 1000,
      maxAge: 3000
    },
    currentStrategy: 'websocket'
  };

  constructor() {}

  setBatchSize(size: 'small' | 'medium' | 'large'): void {
    this._currentBatchSize = size;
  }

  // Update batching settings
  updateBatchingStrategy(connectionType: 'websocket' | 'graphql'): void {
    this.batchingConfig.currentStrategy = connectionType;
    // Adjust batching based on connection type
    if (connectionType === 'websocket') {
      // WebSockets can handle more frequent updates
      this._currentBatchSize = 'small';
    } else {
      // GraphQL should batch more to reduce API calls
      this._currentBatchSize = 'large';
    }
  }

  // Apply batching to an observable stream
  applyBatching<T>(
    source$: Observable<T>,
    connectionType: 'websocket' | 'graphql' = 'websocket'
  ): Observable<T[]> {
    this.batchingConfig.currentStrategy = connectionType;
    const config = this._batchConfigs[this._currentBatchSize];

    // Use RxJS operators for efficient batching
    // This will emit batches either when we hit max count or when the time interval elapses
    return source$.pipe(
      bufferTime(config.interval, null, config.size),
      filter(batch => batch.length > 0)
    );
  }
}
