import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sentenceCase } from '../utils/string.utils';

@Pipe({
  name: 'cleanText'
})
export class CleanTextPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) return '';
    if (typeof value !== 'string') return value;
    // let result: any = sentenceCase(value, true);
    let result: any = value;
    result = this.sanitizer.bypassSecurityTrustHtml(result.replace(/\n/g, '<br>'));
    return result;
  }

}
