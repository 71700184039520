<div #chartContainer class="real-time-plot bg-gray-200 w-96 h-64 relative">
  <ng-container *ngFor="let point of plotPoints">
    <div class="data-point bg-blue-500 rounded-full w-2 h-2 transition-all duration-75"
    [ngStyle]="{
      'bottom': point.bottom + 'px',
      'right': point.right + 'px'
    }"></div>
    <!-- 'right': point.right + 'px' -->
  </ng-container>
</div>
