import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlayerGameComponent } from './components/player-game/player-game.component';
import { HomeComponent } from './components/home/home.component';
import { VideoInteractionComponent } from './components/video-interaction/video-interaction.component';
import { PreGameComponent } from './components/game/pre-game/pre-game.component';

const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'test', component: VideoInteractionComponent },
  { path: 'game', component: PreGameComponent },
  // { path: 'game/:gameId/instructor', component: SessionComponent },
  { path: 'game/:gameId/instructor', component: VideoInteractionComponent },
  { path: 'game/:gameId/player', component: PlayerGameComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

