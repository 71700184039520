import { Injectable } from "@angular/core";
import { Game, Scene } from "../models/game.model";
import { CPRCompleteSession, Question } from "../models/question.model";
import { Player, Team } from "../models/team.model";
import { CPRSession, FinalResultsInteraction, ResultsInteraction, SummaryInteraction, TeamScoreInteraction, VideoInteraction } from "../models/video-interaction.model";
import { GameService, PlayerData } from "../services/game.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { GameStateDto, TelemetryService } from "../services/telemetry.service";


@Injectable({
  providedIn: 'root'
})
export class GameController {

  static TIME_OFFSET = 50;

  seekVideo: Subject<number> = new Subject<number>();
  setVideoTime(time: number) {
    this.seekVideo.next(time);
  }

  constructor(
    private readonly gameService: GameService,
    private readonly telemetryService: TelemetryService
  ) {
    // bind methods
    this.initiateNewGame = this.initiateNewGame.bind(this);
    this.stopGame = this.stopGame.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.scorePoint = this.scorePoint.bind(this);
    this.handleCorrectAnswer = this.handleCorrectAnswer.bind(this);

  }


  private _game: Game | null = null;
  get game(): Game {
    return this._game;
  }
  private set game(game: Game) {
    if (!!game) {
      // save the game id to local storage
      localStorage.setItem('gameId', game.id);
      game.buildScenes();
    }
    else {
      localStorage.removeItem('gameId');
    }
    this._game = game;
  }

  get scenes(): Scene[] {
    return this.game?.scenes || [];
    // if (!this.game) {
    //   return [];
    // }
    // let scenes: Scene[] = [];
    // let currentScene: Scene = null;
    // for (let interaction of this.game.interactions) {
    //   if (!currentScene) {
    //     currentScene = { start: scenes.length === 0 ? 0 : scenes[scenes.length-1].end, end: 0, name: interaction.scene, interactions: [interaction] };
    //     scenes.push(currentScene);
    //   }
    //   else if (currentScene.name !== interaction.scene) {
    //     currentScene = { start: scenes.length === 0 ? 0 : scenes[scenes.length-1].end, end: 0, name: interaction.scene, interactions: [interaction] };
    //     scenes.push(currentScene);
    //   }
    //   else {
    //     currentScene.interactions.push(interaction);
    //   }
    //   currentScene.end = Math.max(currentScene.end, interaction.markers.find(marker => marker.type === 'END')?.timestamp || 0);
    // }
    // return scenes;
  }

  get currentInteractions(): VideoInteraction[] {
    return this.game?.currentInteractions;
  }

  private _selectedPlayer: Player | null = null;
  get selectedPlayer(): Player | null {
    return this._selectedPlayer;
  }
  toggleSelectedPlayer(player: Player | null) {
    if (!player) {
      this._selectedPlayer = null;
      return;
    }
    if (this._selectedPlayer?.id === player.id) {
      this._selectedPlayer = null;
      return;
    }
    this._selectedPlayer = player;
  }

  private _updateGameDetailsTimeout: any;
  updateGameTimestamp(timestamp: number) {
    if (!this.game) {
      return;
    }
    this.game.currentTimestamp = timestamp;
    if (!this._updateGameDetailsTimeout) {
      this._updateGameDetailsTimeout = setTimeout(() => {
        this.saveGame();
        const gameStateDto: GameStateDto = {
          id: this.game.id,
          timestamp: this.game.currentTimestamp,
          paused: this.game.currentPaused,
          status: this.game.status,
          teamScores: this.game.teams.map((team) => team.score)
        };
        this.telemetryService.sendGameStateDetailsUpdate(gameStateDto);
        this._updateGameDetailsTimeout = null;
      }, 1000);
    }
  }

  addCurrentInteractions(interactions: VideoInteraction[]): void {
    if (!this.game) {
      return;
    }
    if (!interactions) {
      this.game.currentInteractionIndices = [];
      return;
    }
    let currentInteractionIndices = interactions.map(interaction => this.game.interactions.indexOf(interaction));
    for(let i = currentInteractionIndices.length - 1; i >= 0; i--) {
      if (currentInteractionIndices[i] >= 0 && !this.game.currentInteractionIndices.includes(currentInteractionIndices[i])) {
        this.game.currentInteractionIndices.push(currentInteractionIndices[i]);
      }
    }

    let question = interactions.find(interaction => interaction instanceof Question) as Question;

    if (!!question) {
      // If the video interaction has an assigned team index, then set the current team
      if (question.hasOwnProperty('teamIndex') && question['teamIndex'] >= 0) {
        this.game.currentTeamIndex = question['teamIndex'];
      }
      // Else if this is a question, then set the current team if it's not already set
      if (!!question) {
        if (!this.game.currentTeam) {
          this.game.currentTeam = this.game.teams[0];
        }
      }
    }
    else {
      this.game.currentTeam = null;
    }

    this.teamAnswering = this.game.currentTeam;

    // save the game
    this.saveGame();
  }

  removeCurrentInteraction(interaction: VideoInteraction) {
    if (!this.game.currentInteractions.length || !interaction) {
      return;
    }
    if (interaction instanceof Question) {
      console.log(`Removing question ${interaction.id} from current interactions`);
    }
    else if (interaction instanceof CPRSession) {
      console.log(`Removing CPR session ${interaction.id} from current interactions`);
    }
    for(let i = this.game.currentInteractionIndices.length - 1; i >= 0; i--) {
      if (this.game.currentInteractions[i].id === interaction.id) {
        this.game.currentInteractionIndices.splice(i, 1);
      }
    }

    // save the game
    this.saveGame();
  }

  async initiateNewGame(gameId?: string, gameType: string = "athlete_v21", numberOfTeams: number = 8, subscribeToPlayerData: boolean = false): Promise<Game> {
    this.game = await this.gameService.startGame(gameId, gameType, numberOfTeams);

    if (!this.game) {
      console.warn('Game not created');
      return null;
    }

    if (this.game.allowsPlayersToJoin && subscribeToPlayerData) {
      await this.subscribeToPlayerData();
    }
    return this.game;
  }

  playerData$: BehaviorSubject<Player[]> = new BehaviorSubject<Player[]>([]);

  subscribedToPlayerData$ = new BehaviorSubject<boolean>(false);
  private _playerDataKillSwitch$ = new Subject<void>();
  async subscribeToPlayerData(): Promise<Game> {
    if (!!this.subscribedToPlayerData$.value) {
      return this.game;
    }
    if (!this.game.allowsPlayersToJoin) {
      console.warn('subscribeToPlayerData -> Game has no players');
      return this.game;
    }
    this.subscribedToPlayerData$.next(true);

    // Subscribe to telemetry service player data updates
    this.telemetryService.subscribeToPlayerData(this.game.id);
    this.telemetryService.playerData$
      .pipe(takeUntil(this._playerDataKillSwitch$))
      .subscribe(data => {
        const playerDatas: Array<PlayerData> = [];
        if (data && Array.isArray(data)) {
          // Update the game with player data
          playerDatas.push(...data.map(d => new PlayerData(d)));
        }
        else if (data?.['data'] && Array.isArray(data['data'])) {
          // Update the game with player data
          playerDatas.push(...data['data'].map(d => new PlayerData(d)));
        }

        if (playerDatas.length > 0 && !!this.game) {
          let updatedPlayers: Array<Player> = [];
          (data || []).forEach(playerData => {
            // update the currentResults and finalResults for each player included in the data
            let player = this.game.teams.reduce((players, team) => players.concat(team.players), []).find(p => p.id === playerData.playerId);
            if (!!player) {
              player.currentResults = playerData.currentResults;
              player.finalResults = playerData.finalResults;
              updatedPlayers.push(player);
            }
            else {
              // create a new player based on the player data
              let team = this.game.teams.find(t => t.id === playerData.teamId);
              if (!!team) {
                let player = new Player({id: playerData.playerId, ...playerData});
                player.currentResults = playerData.currentResults;
                player.finalResults = playerData.finalResults;
                team.players.push(player);
                updatedPlayers.push(player);
              }
            }
          });

          // update the player data - THIS TRIGGERS UI UPDATES
          this.playerData$.next(updatedPlayers);
        }
      });

    // // setup an interval to mock player data
    // setInterval(() => {
    //   let players = this.game.teams.reduce((players, team) => players.concat(team.players), []);
    //   // for each player, update the currentResults slightly one way or the other
    //   players.forEach(player => {
    //     if (!player.currentResults) {
    //       player.currentResults = new PlayerResults({activePercentage: 85, averageCompressionDepth: 85, averageDuration: 500, compressionScore: 85, depthScore: 85, overallScore:70, qualityScore: 85});
    //     }
    //     else {
    //       player.currentResults.activePercentage = Math.min(100, Math.max(10, player.currentResults.activePercentage + Math.random() * 10 - 5));
    //       player.currentResults.averageCompressionDepth = Math.min(100, Math.max(10, player.currentResults.averageCompressionDepth + Math.random() * 10 - 5));
    //       player.currentResults.averageDuration = Math.min(1000, Math.max(200, player.currentResults.averageDuration + Math.random() * 100 - 50));
    //       player.currentResults.compressionScore = Math.min(100, Math.max(0, player.currentResults.compressionScore + Math.random() * 10 - 5));
    //       player.currentResults.depthScore = Math.min(100, Math.max(0, player.currentResults.depthScore + Math.random() * 10 - 5));
    //       player.currentResults.overallScore = Math.min(100, Math.max(0, player.currentResults.overallScore + Math.random() * 10 - 5));
    //       player.currentResults.qualityScore = Math.min(100, Math.max(0, player.currentResults.qualityScore + Math.random() * 10 - 5));
    //     }
    //   });
    //   this.playerData$.next(players);
    // }, 16);

    return this.game;
  }

  private _gameKillSwitch$ = new Subject<void>();

  async stopGame() {
    if (!!this.game) {
      await this.gameService.stopGame(this.game.id);
    }
    if (this._playerDataKillSwitch$) {
      this._playerDataKillSwitch$.next();
      this._playerDataKillSwitch$.complete();
      this._playerDataKillSwitch$ = new Subject<void>();
    }
    if (this._gameKillSwitch$) {
      this._gameKillSwitch$.next();
      this._gameKillSwitch$.complete();
      this._gameKillSwitch$ = new Subject<void>();
    }
  }

  async saveGame(forceSave: boolean = false) {
    if (!!this.game) {
      if (forceSave || !this.gameService.isSavingGame) {
        await this.gameService.saveGame(this.game);
      }
    }
  }

  submitQuestion(question: Question): void {
    if (!this.game) {
      return;
    }
    this.gameService.submitQuestion(this.game.id, question);
  }

  submitAnswer(playerId: string, answer: string, interaction: Question): void {
    if (!this.game?.id || !interaction.id || !interaction.answer) {
      return;
    }
    this.gameService.submitAnswer(this.game.id, interaction.id, playerId, answer);
  }

  scorePoint(playerId: string): void {
    if (!this.game) {
      return;
    }
    this.gameService.scorePoint(this.game.id, playerId);
  }

  private _teamAnswering: Team | null = null;
  get teamAnswering(): Team | null {
    return this._teamAnswering;
  }
  set teamAnswering(team: Team | null) {
    this._teamAnswering = team;
  }
  toggleTeamAnswering() {
    if (!this.game?.currentInteractions?.length) return;
    let index = this.game.teams.indexOf(this.teamAnswering);
    index = (index + 1) % this.game.teams.length;
    this.teamAnswering = this.game.teams[index];
  }

  toggleCurrentTeam() {
    if (!this.game?.currentInteractions?.length) return;
    let index = this.game.teams.indexOf(this.game.currentTeam);
    index = (index + 1) % this.game.teams.length;
    this.game.currentTeam = this.game.teams[index];
  }


  handleCorrectAnswer(answer: string): void {
    if (!this.game || !this.game?.currentInteractions?.length) {
      return;
    }

    // Set the current question to answered
    let question = this.game.currentQuestion;
    if (!!question) {
      // if this is a CPR Complete Session, then depending on the team index, one or both teams may get points
      if (question instanceof CPRCompleteSession) {
        question.checkAnswer(answer);
        if (question.teamIndex === 0) {
          this.game.teams[0].incrementScore(question.pointsValue || 40);
        }
        else if (question.teamIndex === 1) {
          this.game.teams[1].incrementScore(question.pointsValue || 40);
        }
        else {
          this.game.teams[0].incrementScore(question.pointsValue || 20);
          this.game.teams[1].incrementScore(question.pointsValue || 20);
        }
      }
      else {
        if (!this.teamAnswering) {
          if (this.game.currentTeamIndex < 0) {
            this.game.currentTeamIndex = 0;
          }
          this.teamAnswering = this.game.currentTeam;
        }
        if (this.teamAnswering) {
          question.answeredBy = this.teamAnswering.id;
          // Increment the score of the team that answered correctly
          this.teamAnswering.incrementScore(question.pointsValue || 20);
        }

        // Iterate to the next team
        this.toggleCurrentTeam();
      }
    }
    // this.sceneEnd.emit(this.game.currentQuestion);
    // this.game.currentQuestionIndex = -1;

    // // If the question has no end point, then end the question
    // if (!(this.game.currentInteraction!.markers || []).some(marker => marker.type === VideoMarkerType.END)) {
    //   this.sceneEnd.emit(this.gameController.game.currentInteraction);
    //   this.game.currentInteractionIndex = -1;
    //   this.showInteraction = false;
    // }
  }

  handleIncorrectAnswer(): void {
    let question = this.game.currentInteractions.find(interaction => (interaction as any) instanceof Question) as Question;
    if (!!question) {
      question.selectedAnswer = "";
    }
    this.toggleTeamAnswering();
  }


  /////////////////////////////
  // End Game
  /////////////////////////////

  async resetGame(gameId: string) {
    try {
      await this.gameService.stopGame(gameId);
    }
    catch (e) {
      console.error(e);
    }
    this.game = null;
    localStorage.removeItem('gameId');
  }


  /////////////////////////////
  // INTERACTIONS
  /////////////////////////////
  public currentInteractionsByType(type): VideoInteraction[] {
    return (this.currentInteractions || []).filter(interaction => interaction instanceof type) || [];
  }

  get currentQuestions(): Question[] {
    return this.currentInteractionsByType(Question) as Question[];
  }
  get currentCprSessions(): CPRSession[] {
    return this.currentInteractionsByType(CPRSession) as CPRSession[];
  }
  get currentResults(): ResultsInteraction[] {
    return this.currentInteractionsByType(ResultsInteraction) as ResultsInteraction[];
  }
  get currentFinalResults(): FinalResultsInteraction[] {
    return this.currentInteractionsByType(FinalResultsInteraction) as FinalResultsInteraction[];
  }
  get currentTeamScores(): TeamScoreInteraction[] {
    return this.currentInteractionsByType(TeamScoreInteraction) as TeamScoreInteraction[];
  }
  get currentSummaries(): SummaryInteraction[] {
    return this.currentInteractionsByType(SummaryInteraction) as SummaryInteraction[];
  }

}
