<div class="w-full h-full flex flex-col justify-center items-center text-5xl bg-black min-h-screen">
  <div class="w-full py-24 flex flex-col justify-center items-center text-5xl bg-deep-red text-black">
  CardiacST&#11089;R
  </div>
  <!-- <div class="flex flex-row justify-center items-center gap-24 my-8">
    <div class="text-white text-2xl border-2 border-deep-red rounded-3xl p-4 cursor-pointer" (click)="goToInstructor()">
      Instuctor
    </div>
  </div> -->
</div>
