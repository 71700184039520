import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as d3 from 'd3-interpolate';
import { PlotPoint } from '../components/team-interaction/player-interaction/real-time-plot/real-time-plot.component';
import { RealTimePeakDetector } from './realtime-peak-detector';


export type MotionAnalysis = {
  correlation: number,
  rmsd: number,
  percentileValid?: boolean,
  invalidReason?: string,
  p1: number,
  p5: number,
  p25: number,
  p40?: number,
  p50: number,
  p60?: number,
  p75: number,
  p95: number,
  p99: number,
  cycleFrequency?: number,
  cycleCount?: number,
};

export class HistogramData {

  static STALE_DATA_THRESHOLD = 6000; // 6 seconds

  constructor({duration, percentiles, count, distance = 0, distance2 = 0, sampleFrequency, zValues}: {duration: number, percentiles?: any[], count: number, distance?: number, distance2?: number, sampleFrequency: number, zValues: Partial<{ z: number; timestamp: number }>[]}) {
    this.duration = duration || 0;
    this.percentiles = (percentiles || []).map((p) => new PercentileData(p));
    this.count = count || 0;
    this.distance = distance || 0;
    this.distance2 = distance2 || 0;
    this.sampleFrequency = sampleFrequency || 0;
    this.zValues = zValues;
  }

  get accelDistance(): number {
    if (this.percentiles.length === 0) return 0;
    return (this.percentiles[this.percentiles.length-1].value - this.percentiles[0].value)
  }

  zValues: Partial<{ z: number; timestamp: number }>[];
  sampleFrequency: number;
  distanceInInches: number;
  private _distance: number;
  set distance(distance: number) {
    this._distance = distance;
    this.distanceInInches = distance * 39.37;
  }
  get distance(): number {
    return this._distance;
  }

  distance2InInches: number;
  private _distance2: number;
  set distance2(distance: number) {
    this._distance2 = distance;
    this.distance2InInches = distance * 39.37;
  }
  get distance2(): number {
    return this._distance2;
  }

  static MIN_DURATION = 1000 / 2.4; // 416 ~> 400
  static MAX_DURATION = 1000 / 1.7; // 588 ~> 600
  // static MIN_DURATION = 1000 / 2.2; // 454 ~> 400
  // static MAX_DURATION = 1000 / 1.7; // 588 ~> 600

  private _duration: number;
  set duration(duration: number) {
    // desired frequency is 2.2Hz, with some tolerance. calculate the min and max duration in ms
    this._duration = duration;
    // Duration of the last cycle should be between 0.4 and 0.6 seconds
  }
  get duration(): number {
    return this._duration;
  }

  get speedIndicator(): string {
    if (this.duration <= 0) {
      return 'None';
    }
    else if (this.duration < HistogramData.MIN_DURATION) {
      return 'Too Fast';
    }
    else if (this.duration > HistogramData.MAX_DURATION) {
      return 'Too Slow';
    }
    else {
      return 'Good';
    }
  }

  depthIndicator: string = 'None';
  private _percentiles: PercentileData[];
  set percentiles(percentiles: PercentileData[]) {
    this._percentiles = percentiles;
    if (percentiles.length === 0) {
      this.depthIndicator = 'None';
      return;
    }

    // The spread between the 5th and 95th percentiles should be at least 10
    if ((percentiles[percentiles.length-1].value - percentiles[0].value) < 12) {
      this.depthIndicator = 'Too Shallow';
    }
    else {
      if ((percentiles[percentiles.length-2].value - percentiles[1].value) < 10) {
        this.depthIndicator = 'Too Shallow';
      }
      else {
        this.depthIndicator = 'Good';
      }
    }

    // // Either: 5% should be below -2 and 95% should be above 12
    // // Or: 5% should be below -12 and 95% should be above 2
    // if (percentiles[0].value < -2 && percentiles[percentiles.length-1].value > 12) {
    //   this.depthIndicator = 'Good';
    // }
    // else if (percentiles[0].value < -12 && percentiles[percentiles.length-1].value > 2) {
    //   this.depthIndicator = 'Good';
    // }
    // else {
    //   this.depthIndicator = 'Too Shalllow';
    // }


    // // 5% should be below -2
    // if (percentiles[0].value > -2) {
    //   this.depthIndicator = 'Too Shallow';
    // }
    // // 95% should be above 12
    // else if (percentiles[percentiles.length-1].value < 12) {
    //   this.depthIndicator = 'Too Shallow';
    // }


    // // 25% should be below -3
    // else if (percentiles[2].value > -3) {
    //   this.depthIndicator = 'Too Shallow';
    // }
    // // 75% should be above 5
    // else if (percentiles[3].value < 5) {
    //   this.depthIndicator = 'Too Shallow';
    // }
    // else {
    //   this.depthIndicator = 'Good';
    // }
  }
  get percentiles(): PercentileData[] {
    return this._percentiles;
  }

  count: number;
  histogramLabels: string[] = [];
  histogramData: number[] = [];

  get percentileLabels(): string[] {
    return this.percentiles.map((p) => `${p.percentile}%`);
  }

  get percentileValues(): number[] {
    return this.percentiles.map((p) => p.value);
  }

  get jsonHistogram(): string {
    let result = {
      accelDistance: this.accelDistance.toFixed(2),
      duration: this.duration.toFixed(2),
      percentiles: {},
      count: this.count.toFixed(2),
      // distance: this.distanceInInches?.toFixed(2),
      // distance2: this.distance2InInches?.toFixed(2),
      sampleFrequency: this.sampleFrequency?.toFixed(2),
    };

    this.percentiles.forEach((p) => {
      result.percentiles[p.percentile] = p.value.toFixed(2);
    });

    return JSON.stringify(result, null, 2);
  }

}

export class PercentileData {

  constructor({percentile, value}: {percentile: number, value: number}) {
    this.percentile = percentile;
    this.value = value;
  }

  percentile: number;
  value: number;
}

export interface MotionData {
  x: number | null;
  y: number | null;
  z: number | null;
  timestamp: number;
}

export interface PeriodStats {
  startTime: number;
  endTime: number;
  minDepth: number;
  maxDepth: number;
}

@Injectable({
  providedIn: 'root',
})
export class GyroscopeService implements OnDestroy {
  private motionDataStream = new Subject<MotionData>();
  private deviceMotionHandler: ((event: DeviceMotionEvent) => void) | null = null;
  // private depthAccelChangeThreshold = 1.2; // Define your threshold
  // private isInMotion = false;
  // private currentPeriod: PeriodStats | null = null;
  // private periods: PeriodStats[] = [];

  // private histogramDuration = 3000; // Duration for histogram data collection in milliseconds
  // private histogramBins: number[] = new Array(10).fill(0); // Adjust the size based on required resolution

  constructor(
  ) {
    // this.startListening();
  }

  private standingZeroZ: number = 0.0;
  private standingZeroTolerance: number = 10.5; //3;
  private lastZCrossingTime: number = 0;
  private lastZCrossingCount: number = 0;
  static ZERO_CROSSINGS_PER_CYCLE: number = 2;
  private pendingZValues: number[] = [];
  private pendingMotionData: Partial<{ z: number; timestamp: number }>[] = [];
  private currentZValues: number[] = [];
  private currentMotionData: Partial<{ z: number; timestamp: number }>[] = [];
  // private historgramDatas: Array<{duration: number, percentiles: number[]}> = [];

  histogramDataStream$: Subject<HistogramData> = new BehaviorSubject<HistogramData>(null);

  private smoothMovingAverage(zValues: number[], windowSize: number = 5): number[] {
    if (zValues.length < windowSize) return zValues;

    const smoothedValues = [];
    for (let i = 0; i < zValues.length; i++) {
        const start = Math.max(0, i - windowSize + 1);
        const end = i + 1;
        const window = zValues.slice(start, end);
        const average = window.reduce((sum, value) => sum + value, 0) / window.length;
        smoothedValues.push(average);
    }
    return smoothedValues;
}

private isZeroCrossing_MOVING_AVERAGE(zValues: number[]): boolean {
    if (zValues.length < 2) return false;

    const smoothedZValues = this.smoothMovingAverage(zValues);
    const lastValue = smoothedZValues[smoothedZValues.length - 1];
    const varianceThreshold = this.standingZeroZ + this.standingZeroTolerance;
    const zeroThreshold = this.standingZeroZ;

    let maxZ = Math.max(...smoothedZValues);
    let minZ = Math.min(...smoothedZValues);

    // Apply zero-crossing detection on smoothed data
    if (maxZ > varianceThreshold && lastValue <= varianceThreshold) return true;
    if (minZ < -varianceThreshold && lastValue >= -varianceThreshold) return true;

    return false;
}
  private isZeroCrossing(zValues: number[]): boolean {
    if (zValues.length < 2) return false;

    // TODO: If it has been more than X seconds, then we need to reset the standing zero
    // TODO: Can we somehow capture the min and max values in the last 5 seconds and use that to dynamically adjust the standingZeroTolerance value?

    const lastValue = zValues[zValues.length-1];
    const varianceThreshold = (this.standingZeroZ + this.standingZeroTolerance)
    const zeroThreshold = this.standingZeroZ

    // if the max value is over the threshold, and the current value is under the threshold, then we have a zero crossing
    let maxZ = Math.max(...zValues);
    if (maxZ > varianceThreshold && lastValue <= varianceThreshold) return true;
    // if (maxZ > varianceThreshold && lastValue <= zeroThreshold) return true;

    // if the min value is under the threshold, and the current value is over the threshold, then we have a zero crossing
    let minZ = Math.min(...zValues);
    if (minZ < -varianceThreshold && lastValue >= -varianceThreshold) return true;
    // if (minZ < -varianceThreshold && lastValue >= -zeroThreshold) return true;

    return false;
  }

  private isZeroCrossing_OLD(z: number, lastZ: number): boolean {
    // console.log(`MAX Z: ${Math.max(...this.currentZValues).toFixed(2)}`);
    if (Math.abs(z) <= (this.standingZeroZ + this.standingZeroTolerance)) {
      // console.log(`GyroscopeService: Zero crossing Accel TOO SMALL: ${z}`)
      return false;
    }

    if (Math.sign(lastZ) !== Math.sign(z)) {
      if (Math.abs(z) > (this.standingZeroZ + this.standingZeroTolerance)) {
        lastZ = z;
        return true;
      }
    }

    lastZ = z;
    return false;
  }


  private calculatePercentiles(arr, percentiles) {
    const sorted = arr.slice().sort((a, b) => a - b);
    const results = [];

    percentiles.forEach(percentile => {
        const index = (percentile / 100) * (sorted.length - 1);
        const lower = Math.floor(index);
        const upper = Math.ceil(index);

        let value;
        if (lower === upper) {
            value = sorted[lower];
        } else {
            // Interpolate between lower and upper values
            value = sorted[lower] + (sorted[upper] - sorted[lower]) * (index - lower);
        }

        results.push(new PercentileData({percentile, value}));
    });

    return results;
  }

  // distance traveled is the sum of the absolute values of the z values multipled by the time between each reading squared
  private calculateDistance(zValues: number[], sampleFrequency: number): number {
    let distance = 0;
    for (let i = 1; i < zValues.length; i++) {
      distance += Math.abs(zValues[i]);
    }
    distance *= sampleFrequency ^ 2;
    return distance;
  }


  plotPoints: PlotPoint[] = [];
  static MIN_LOWER_BOUND = -5;
  static MAX_UPPER_BOUND = 5;
  private _lowerBound: number = 0;
  private _upperBound: number = 100;
  // addDataPoint(dataPoint: MotionData): void {
  //   const now = Date.now();
  //   const maxWidth = 100;
  //   // this.dataPoints = this.dataPoints.filter(dp => now - dp.timestamp < this.maxWindowMs);
  //   // this.plotPoints = this.plotPoints.filter(pp => now - pp.timestamp < this.maxWindowMs);

  //   // calculate the new lower and upper bounds based on the min/max values in the data points
  //   const minValue = Math.min(...this.plotPoints.map(dp => dp.value));
  //   const maxValue = Math.max(...this.plotPoints.map(dp => dp.value));
  //   const valueRange = maxValue - minValue;
  //   const newLowerBound = Math.min(minValue - valueRange * 0.1, GyroscopeService.MIN_LOWER_BOUND);
  //   const newUpperBound = Math.max(maxValue + valueRange * 0.1, GyroscopeService.MAX_UPPER_BOUND);

  //   // Only adjust they are either too small or are too big and have changed by more than 10%
  //   if (newLowerBound < this._lowerBound * 0.9 || newLowerBound > this._lowerBound * 1.1) {
  //     this._lowerBound = newLowerBound;
  //   }
  //   if (newUpperBound < this._upperBound * 0.9 || newUpperBound > this._upperBound * 1.1) {
  //     this._upperBound = newUpperBound;
  //   }

  //   const newPlotPoint: PlotPoint = {
  //     timestamp: dataPoint.timestamp,
  //     value: dataPoint.z,
  //     // right: this.getRightPosition(dataPoint.timestamp, maxWidth, now),
  //     right: maxWidth,
  //     bottom: this.getBottomPosition(dataPoint.z)
  //   };
  //   this.plotPoints.push(newPlotPoint);

  //   setTimeout(() => {
  //     newPlotPoint.right = 0;
  //   }, 1);

  //   // filter out the points that are outside the visible window based on the right position
  //   // this.plotPoints = this.plotPoints.filter(pp => pp.right >= 0 && pp.right <= maxWidth);
  //   for(let i = this.plotPoints.length-1; i >= 0; i--) {
  //     if (this.plotPoints[i].right < 0 || this.plotPoints[i].right > maxWidth) {
  //       this.plotPoints.splice(i, 1);
  //     }
  //   }

  //   if (this.plotPoints.length < 10) {
  //     console.log('No plot points');
  //   }
  // }

  getBottomPosition(value: number, maxHeight: number = 100): number {
    // calculate the position based on the value and the lower and upper bounds
    const range = this._upperBound - this._lowerBound;
    const valueOffset = value - this._lowerBound;
    let position = valueOffset / range;
    position = 1 - position; // Invert the position
    position = Math.round(position * maxHeight);
    position = Math.round(position / 10) * 10;
    return position;
  }


  private _historgrams: HistogramData[] = [];
  // ???: 2024-08-23
  // dataStream$: Subject<MotionData> = new BehaviorSubject<MotionData>(null);
  data: Array<MotionData> = [];

  peakDetector = new RealTimePeakDetector(0.8, 0.2);
  private handlePeakDepthAccelChange(curr: Partial<{ z: number; timestamp: number }>) {
    let currAny = curr as any;
    // if (currAny.interval > 17 || currAny.interval < 16) {
    //   console.log('GyroscopeService: Interval is too high', currAny.interval);
    // }
    const {peakDetected, period, displacement, peakValue, zValues, totalValues} = this.peakDetector.update(curr.z, currAny.interval);

    if (peakDetected) {
      console.log('GyroscopeService: Peak detected', curr);

      const nextHistogram = new HistogramData({duration: period, count: totalValues, sampleFrequency: currAny.interval, distance: displacement, zValues});
      this.histogramDataStream$.next(nextHistogram);
      this._historgrams.push(nextHistogram);
    }
  }
  // private handleDepthAccelChange(prev: { depth: number; timestamp: number }, curr: { depth: number; timestamp: number }) {
  private handleDepthAccelChange(curr: Partial<{ z: number; timestamp: number }>) {
    // console.log('GyroscopeService: Received motion data', curr);
    // Remove all data older than {STALE_DATA_THRESHOLD}ms
    const oldestAllowedTimestamp = Date.now() - HistogramData.STALE_DATA_THRESHOLD;
    let indexToShift = 0;
    while (this.data?.length > indexToShift && this.data[indexToShift].timestamp < oldestAllowedTimestamp) {
      indexToShift++;
    }
    if (indexToShift > 0) {
      // console.log('GyroscopeService: Removing stale data', indexToShift);
      this.data = this.data.splice(indexToShift);
    }

    let currAny = curr as any;
    // if (currAny.interval > 17 || currAny.interval < 16) {
    //   console.log('GyroscopeService: Interval is too high', currAny.interval);
    // }
    if (this.data.length > 0) {
      if (currAny.interval > 10000) {
        currAny.interval /= 1000;
      }
      curr.timestamp = this.data[this.data.length-1].timestamp + currAny.interval;
      // console.log('GyroscopeService: Interval', currAny.interval, curr.timestamp);
    }
    // else {
    //   curr.timestamp = Date.now();
    //   // console.log('GyroscopeService (INIT): Interval', currAny.interval, curr.timestamp);
    // }

    // // Make sure we haven't already received this data
    // if (this.data.length > 0 && this.data[this.data.length-1].timestamp === curr.timestamp) {
    //   // console.log('GyroscopeService: Received duplicate data');
    //   return;
    // }
    // Round z to 2 decimal places
    // curr.z = Math.round(curr.z * 100) / 100;
    this.data.push(curr as MotionData);

    // if the gap between the last two readings is greater than 1.25 times the sample frequency, then we have a gap
    if (this.data.length > 1 && curr.timestamp - this.data[this.data.length-2].timestamp > 1.25 * 17) {
      console.log('GAP', curr.timestamp - this.data[this.data.length-2].timestamp);
    }

    // the first few readings are usually garbage, so we need to wait until we have at least 75 readings
    if (this.data.length < 75) {
      return;
    }

    // this.addDataPoint(curr as MotionData);
    // ???: 2024-08-23
    // this.dataStream$.next(curr as MotionData);
    // this.runComparisons(this.data);
    // this.analyzeAverageMovement(this.data);
    // TODO: zero crossing
    const isZeroCrossing = this.isZeroCrossing([...this.currentZValues, curr.z]);
    // const isZeroCrossing = this.isZeroCrossing(curr.z, this.data[this.data.length-2]?.z || 0);
    if (isZeroCrossing) {
      // console.log('GyroscopeService: Zero crossing detected', curr);
      // let distance = 0;
      // let distance2 = 0;
      // // let fftData;
      // if (this._latestDatas.length > 0) {
      //   distance = DistanceCalculator.calculateDistance(this._latestDatas, DistanceIntegrationMethod.Luca);
      //   distance2 = DistanceCalculator.calculateDistance(this._latestDatas, DistanceIntegrationMethod.Trapezoidal);

      //   // // Spline interpolate the data to 300Hz and then calculate the distance again so we can compare
      //   // let targetInterval = 1 / 300; // 300Hz
      //   // let interpolated = this.splineInterpolate(this._latestDatas.map(d => d.z), this._latestDatas.map(d => d.timestamp), targetInterval);
      //   // // Now we need to convert the interpolated data back into MotionData objects and set filteredReadings to that
      //   // const interpolatedData = [];
      //   // for(let i=0; i<interpolated.data.length; i++) {
      //   //   interpolatedData.push({
      //   //     x: null,
      //   //     y: null,
      //   //     z: interpolated.data[i],
      //   //     timestamp: interpolated.timestamps[i],
      //   //   });
      //   // }
      //   // distance2 = DistanceCalculator.calculateDistance(interpolatedData, DistanceIntegrationMethod.Trapezoidal);
      //   // // fftData = this.calculateFft(interpolated.data);
      // }
      // this._latestDatas = [curr as MotionData];

      this.lastZCrossingCount++;

      this.pendingZValues = this.pendingZValues.concat(this.currentZValues);
      this.pendingMotionData = this.pendingMotionData.concat(this.currentMotionData);

      if (this.lastZCrossingCount % GyroscopeService.ZERO_CROSSINGS_PER_CYCLE === 0) {
        if (this.pendingZValues.length > 0 && this.lastZCrossingTime > 0) {
          // Calculate statistics for the histogram
          // let percentiles = this.calculatePercentiles(this.pendingZValues, [5, 10, 25, 50, 75, 90, 95]);
          // this.historgramDatas.push({duration: curr.timestamp - this.lastZCrossingTime, percentiles});

          // Calculate the sample frequency
          let timeDiffInSeconds = (curr.timestamp - this.data[0].timestamp) / 1000.0;
          const sampleFrequency = this.data.length / timeDiffInSeconds;
          // console.log(`Frequency (${this.data.length})`, sampleFrequency);
          // const numSamples = Math.min(this.data.length, 15);
          // const sampleFrequency = numSamples / ((curr.timestamp - this.data[this.data.length-numSamples-1].timestamp) / 1000.0);
          // const avgSampleFrequency = this.data.length / ((curr.timestamp - this.data[0].timestamp) / 1000.0);
          // console.log(`Frequency (${numSamples} :: ${this.data.length}) -> ${sampleFrequency} :: ${avgSampleFrequency}`);

          // // print out the last 5 timestamp differences
          // for (let i = this.data.length-6; i < this.data.length; i++) {
          //   console.log(`${this.data[i].timestamp - this.data[i-1].timestamp}`);
          // }

          // // see if there are any gaps in the data where timestamp differences are greater than 1.25 times the sample frequency
          // for (let i = 1; i < this.data.length; i++) {
          //   // if (this.data[i].timestamp - this.data[i-1].timestamp > 1.25 * (1000 / sampleFrequency)) {
          //   if (this.data[i].timestamp - this.data[i-1].timestamp > 1.25 * (17)) {
          //     console.log(`GAP at ${i}: ${this.data[i].timestamp - this.data[i-1].timestamp}`);
          //   }
          // }
          // console.log(`${this.data[1].timestamp - this.data[0].timestamp}`);
          // console.log(`${this.data[2].timestamp - this.data[1].timestamp}`);
          // console.log(`${this.data[3].timestamp - this.data[2].timestamp}`);
          // console.log(`${this.data[4].timestamp - this.data[3].timestamp}`);
          // console.log(`${curr.timestamp - this.data[this.data.length-2].timestamp}`);

          // distance traveled is the sum of the absolute values of the z values multipled by the time between each reading squared
          const distance = this.calculateDistance(this.pendingZValues, sampleFrequency);

          // const nextHistogram = new HistogramData({duration: (curr.timestamp - this.lastZCrossingTime) * 2, percentiles, count: this.currentZValues.length, distance, distance2, sampleFrequency});
          // const nextHistogram = new HistogramData({duration: (curr.timestamp - this.lastZCrossingTime), percentiles, count: this.pendingZValues.length, sampleFrequency, distance});
          const nextHistogram = new HistogramData({duration: (curr.timestamp - this.lastZCrossingTime), count: this.pendingZValues.length, sampleFrequency, distance, zValues: this.pendingMotionData});

          // ???: 2024-08-23
          // const histogram = this.createHistogram(this.pendingZValues, 10); // 10 bins for example
          // nextHistogram.histogramLabels = histogram.labels;
          // nextHistogram.histogramData = histogram.data;
          // // nextHistogram.fftData = fftData;
          this.histogramDataStream$.next(nextHistogram);
          this._historgrams.push(nextHistogram);


        }
        this.lastZCrossingTime = curr.timestamp;
        this.pendingZValues = [];
        this.pendingMotionData = [];
      }
      this.currentZValues = [];
      this.currentMotionData = [];
    } else {
      // this._latestDatas.push(curr as MotionData);
      this.currentZValues.push(curr.z);
      this.currentMotionData.push(curr);
    }
  }

  // private calculateDepthStats(): { duration: number; min: number; max: number } {
  //   const totalDuration = this.periods.reduce((acc, period) => acc + (period.endTime - period.startTime), 0);
  //   const avgDuration = this.periods.length > 0 ? totalDuration / this.periods.length : 0;
  //   const avgMinDepth = this.periods.reduce((acc, period) => acc + period.minDepth, 0) / this.periods.length;
  //   const avgMaxDepth = this.periods.reduce((acc, period) => acc + period.maxDepth, 0) / this.periods.length;
  //   return { duration: avgDuration, min: avgMinDepth, max: avgMaxDepth };
  // }

  requestMotionPermission(): Promise<PermissionStatus | void> {
    if (typeof DeviceMotionEvent['requestPermission'] === 'function') {
      return (DeviceMotionEvent as any).requestPermission();
    }
    return Promise.resolve({} as any);
  }

  startListening(): void {
    if (!this.motionDataStream.observed) {
      console.log('GyroscopeService: Starting to listen for motion events');
      if ('DeviceMotionEvent' in window) {
        this.deviceMotionHandler = (event) => this.motionDataStream.next({
          x: event.acceleration?.x || null,
          y: event.acceleration?.y || null,
          z: event.acceleration?.z || null,
          interval: Math.floor(event.interval * 1000),
          timeStamp: event.timeStamp,
          timestamp: Date.now(),
          // timestamp: event.timeStamp || Date.now(),
          // timestamp: Date.now(),
        } as any);

        window.addEventListener('devicemotion', this.deviceMotionHandler);

        this.motionDataStream.pipe(
          takeUntil(this.destroy$),
        ).subscribe({
          next: (curr) => {
            // this.handlePeakDepthAccelChange(curr);
            this.handleDepthAccelChange(curr);
          }
        });
      }
    }
    else {
      console.log('GyroscopeService: Already listening for motion events');
    }
  }

  stopListening(): void {
    console.log('GyroscopeService: Stopping listening for motion events');
    if (this.deviceMotionHandler) {
      window.removeEventListener('devicemotion', this.deviceMotionHandler);
      this.deviceMotionHandler = null;
    }
    this.destroy$.next();
    this.destroy$.complete();

    // Clear out data
    this.data = [];
    // this._latestDatas = [];
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.stopListening();
  }

  // getDepthStatsStream(): Observable<{ duration: number; min: number; max: number }> {
  //   const depth$ = this.motionDataStream.pipe(
  //     map(motionData => ({ depth: motionData.z || 0, timestamp: motionData.timestamp }))
  //   );
  //   return this.processDepthData(depth$);
  // }

  getMotionDataStream(): Observable<MotionData> {
    return this.motionDataStream.asObservable();
  }

  // private _latestDatas: Array<MotionData> = [];
  // distances: Array<number> = [];
  // calculateDistance(readings: Array<Partial<{z: number, timestamp: number}>>) {
  //   let velocity = { z: 0 };
  //   let distance = { z: 0 };

  //   for (let i = 1; i < readings.length; i++) {
  //       const deltaTime = (readings[i].timestamp - readings[i - 1].timestamp) / 1000; // Convert to seconds

  //       // Integrate acceleration to get velocity
  //       // velocity.x += readings[i].x * deltaTime;
  //       // velocity.y += readings[i].y * deltaTime;
  //       velocity.z += readings[i].z * deltaTime;

  //       // Integrate velocity to get distance
  //       // distance.x += velocity.x * deltaTime;
  //       // distance.y += velocity.y * deltaTime;
  //       distance.z += velocity.z * deltaTime;
  //   }

  //   this.distances.push(distance.z);

  //   // Calculate total distance using Pythagorean theorem
  //   // return Math.sqrt(distance.x ** 2 + distance.y ** 2 + distance.z ** 2);
  //   return Math.abs(distance.z);
  // }

  calculateAlpha(cutoffFrequency: number, samplingRate: number): number {
    const deltaTime = 1 / samplingRate; // Δt, time between samples
    const tau = 1 / (2 * Math.PI * cutoffFrequency); // Time constant τ
    return deltaTime / (tau + deltaTime);
  }


  applyLowPassFilter_OLD(readings: MotionData[], cutoffFrequency: number): MotionData[] {
    if (readings.length < 2) return readings; // Not enough readings to filter
    const deltaTime = (readings[1].timestamp - readings[0].timestamp) / 1000; // Assuming consistent deltaTime
    const alpha = this.calculateAlpha(cutoffFrequency, 1 / deltaTime);
    let filteredReadings: MotionData[] = [];
    let previousFilteredValue = readings[0].z;

    readings.forEach(reading => {
        let filteredValue = alpha * reading.z + (1 - alpha) * previousFilteredValue;
        previousFilteredValue = filteredValue;
        filteredReadings.push({ ...reading, z: filteredValue });
    });

    return filteredReadings;
  }

  applyLowPassFilter(readings: MotionData[], cutoffFrequency: number): MotionData[] {
    let filteredReadings: MotionData[] = [];
    let previousFilteredValue = readings[0].z;

    for (let i = 1; i < readings.length; i++) {
        const deltaTime = (readings[i].timestamp - readings[i - 1].timestamp) / 1000;
        const alpha = this.calculateAlpha(cutoffFrequency, 1 / deltaTime); // Recalculate alpha for each reading
        let filteredValue = alpha * readings[i].z + (1 - alpha) * previousFilteredValue;
        previousFilteredValue = filteredValue;
        filteredReadings.push({ ...readings[i], z: filteredValue });
    }

    return filteredReadings;
  }

  isSignificantMovement(acceleration: number, threshold: number): boolean {
      return Math.abs(acceleration) > threshold;
  }

  averages: { averageDistance: number, cycleCount: number, averageCycleTime: number, averageFrequency: number } = {averageDistance: 0, cycleCount: 0, averageCycleTime: 0, averageFrequency: 0};
  analyzeAverageMovement(readings: MotionData[], movementThreshold: number = 0.1, cutoffFrequency: number = 50): { averageDistance: number, cycleCount: number, averageCycleTime: number, averageFrequency: number } {
    if (!readings || readings.length < 25) return { averageDistance: 0, cycleCount: 0, averageCycleTime: 0, averageFrequency: 0 };
    let filteredReadings = this.applyLowPassFilter(readings, cutoffFrequency);

    // // We want our data to be at 300Hz, so we need to interpolate it to that frequency
    // let targetInterval = 1 / 300; // 300Hz
    // let interpolated = this.splineInterpolate(filteredReadings.map(d => d.z), filteredReadings.map(d => d.timestamp), targetInterval);
    // // Now we need to convert the interpolated data back into MotionData objects and set filteredReadings to that
    // filteredReadings = [];
    // for(let i=0; i<interpolated.data.length; i++) {
    //   filteredReadings.push({
    //     x: null,
    //     y: null,
    //     z: interpolated.data[i],
    //     timestamp: interpolated.timestamps[i],
    //   });
    // }

    let velocityZ = 0;
    let distancePerCycle = 0;
    let cycleDistances: number[] = [];
    let cycleTimes: number[] = [];
    // let lastVelocitySign = 0; // Initial velocity sign is unknown
    let cycleStartTime = filteredReadings[0].timestamp;
    let zeroCrossingCount = 0;
    let totalDuration = 0;

    let isCycleComplete: boolean = false;

    for (let i = 1; i < filteredReadings.length; i++) {
        if (Math.abs(filteredReadings[i].z) < movementThreshold) continue;

        const deltaTime = (filteredReadings[i].timestamp - filteredReadings[i - 1].timestamp) / 1000;
        velocityZ += filteredReadings[i].z * deltaTime;
        distancePerCycle += Math.abs(velocityZ * deltaTime);

        // let currentVelocitySign = Math.sign(velocityZ);

        // Detect zero-crossing in velocity
        // TODO: zero crossing
        if (this.isZeroCrossing(filteredReadings.map(d => d.z))) {
        // if (this.isZeroCrossing(filteredReadings[i].z, i > 1 ? filteredReadings[i-1].z : 0)) {
        // if (currentVelocitySign !== lastVelocitySign && currentVelocitySign !== 0) {
            // zeroCrossingCount++;
            // // lastVelocitySign = currentVelocitySign;

            // if (zeroCrossingCount % 2 === 0) { // A full cycle is completed every two zero-crossings
                cycleDistances.push(distancePerCycle);
                distancePerCycle = 0;

                let cycleEndTime = filteredReadings[i].timestamp;
                let cycleDuration = (cycleEndTime - cycleStartTime) / 1000; // Cycle time in seconds
                cycleTimes.push(cycleDuration);
                totalDuration += cycleDuration;

                cycleStartTime = cycleEndTime;
                isCycleComplete = true;
            // }
        }
    }

    let averageDistanceInches = 0, averageCycleTime = 0, averageFrequency = 0;
    let cycleCount = cycleDistances.length;

    if (cycleCount > 0) {
        const totalDistance = cycleDistances.reduce((a, b) => a + b, 0);
        averageDistanceInches = (totalDistance / cycleCount) * 39.37; // Convert average distance to inches

        const totalTime = cycleTimes.reduce((a, b) => a + b, 0);
        averageCycleTime = totalTime / cycleCount;
    }

    const sampleFrequency = filteredReadings.length / ((filteredReadings[filteredReadings.length-1].timestamp - filteredReadings[0].timestamp) / 1000.0);

    const result = { averageDistance: averageDistanceInches, cycleCount, averageCycleTime, averageFrequency: sampleFrequency };
    if (isCycleComplete) {
      this.averages = result;
    }
    return result;
  }

  calculateCycles(readings: MotionData[], movementThreshold: number = 0.1, cutoffFrequency: number = 50): {cycleCount: number, averageCycleTime: number, averageFrequency: number} {
    if (!readings || readings.length < 2) return {cycleCount: 0, averageCycleTime: 0, averageFrequency: 0};
    const filteredReadings = this.applyLowPassFilter(readings, cutoffFrequency);
    let velocityZ = 0;
    let distancePerCycle = 0;
    let cycleDistances: number[] = [];
    let cycleTimes: number[] = [];
    let lastVelocitySign = 0; // Initial velocity sign is unknown
    let cycleStartTime = filteredReadings[0].timestamp;
    let zeroCrossingCount = 0;
    let totalDuration = 0;

    for (let i = 1; i < filteredReadings.length; i++) {
        if (Math.abs(filteredReadings[i].z) < movementThreshold) continue;

        const deltaTime = (filteredReadings[i].timestamp - filteredReadings[i - 1].timestamp) / 1000;
        velocityZ += filteredReadings[i].z * deltaTime;
        distancePerCycle += Math.abs(velocityZ * deltaTime);

        // Handle flutter and floating point errors by resetting velocity to zero if it's close enough
        if (Math.abs(velocityZ) < 0.0001) velocityZ = 0;
        let currentVelocitySign = Math.sign(velocityZ);

        // Detect zero-crossing in velocity
        if (currentVelocitySign !== lastVelocitySign && currentVelocitySign !== 0) {
            zeroCrossingCount++;
            lastVelocitySign = currentVelocitySign;

            if (zeroCrossingCount % 2 === 0) { // A full cycle is completed every two zero-crossings
                cycleDistances.push(distancePerCycle);
                distancePerCycle = 0;

                let cycleEndTime = filteredReadings[i].timestamp;
                let cycleDuration = (cycleEndTime - cycleStartTime) / 1000; // Cycle time in seconds
                cycleTimes.push(cycleDuration);
                totalDuration += cycleDuration;

                cycleStartTime = cycleEndTime;
            }
        }
    }

    return {
      cycleCount: cycleDistances.length,
      averageCycleTime: cycleTimes.reduce((a, b) => a + b, 0) / cycleDistances.length,
      averageFrequency: filteredReadings.length / ((filteredReadings[filteredReadings.length-1].timestamp - filteredReadings[0].timestamp) / 1000.0),
    };
  }

  comparisons: MotionAnalysis = {correlation: 0, rmsd: 0, p1: 0, p5: 0, p25: 0, p50: 0, p75: 0, p95: 0, p99: 0, percentileValid: false};
  ideal: MotionAnalysis = {correlation: 0, rmsd: 0, p1: 0, p5: 0, p25: 0, p50: 0, p75: 0, p95: 0, p99: 0, percentileValid: true};
  tooShallow: MotionAnalysis = {correlation: 0, rmsd: 0, p1: 0, p5: 0, p25: 0, p50: 0, p75: 0, p95: 0, p99: 0, percentileValid: false};
  runComparisons(readings: MotionData[]) {
    if (!readings || readings.length < 2) return;

    // Run percentiles comparison as sliding window, then average up the results
    // Sliding window size is 500ms
    // Only go back 2 seconds
    const windowSize = 2000;
    const slidingWindowSize = 500;
    let currentEndIndex = -1;
    let currentEndTimestamp = -1;
    const windowPercentiles = [];
    const windowData = [];
    for(let i=readings.length-1; i>=0; i--) {
      if (readings[i].timestamp < (readings[readings.length-1].timestamp - windowSize)) {
        break;
      }
      if (currentEndIndex < 0 || readings[i].timestamp < (currentEndTimestamp - slidingWindowSize)) {
        if (currentEndIndex >= 0) {
          // Calculate the percentiles for this window
          const windowData = readings.slice(i+1, currentEndIndex+1);
          const percentiles = this.runPercentilesAnalysis(windowData.map((d) => d.z));
          windowPercentiles.push(percentiles);

        }
        currentEndIndex = i;
        currentEndTimestamp = readings[i].timestamp;
      }
      windowData.unshift(readings[i]);
    }

    // Calculate number of cycles in this window
    const movementAnalysis = this.analyzeAverageMovement(windowData);
    // Cycle frequency should be roughly 2 Hz
    const cycleFrequency = movementAnalysis.averageFrequency;
    const cycleCount = movementAnalysis.cycleCount;

    // Calculate the average percentiles
    const averagePercentiles: MotionAnalysis = windowPercentiles.reduce((acc, p) => {
      acc.p1 += p.p1;
      acc.p5 += p.p5;
      acc.p25 += p.p25;
      acc.p40 += p.p40;
      acc.p50 += p.p50;
      acc.p60 += p.p60;
      acc.p75 += p.p75;
      acc.p95 += p.p95;
      acc.p99 += p.p99;
      return acc;
    }, {p1: 0, p5: 0, p25: 0, p40: 0, p50: 0, p60: 0, p75: 0, p95: 0, p99: 0});
    averagePercentiles.p1 /= windowPercentiles.length;
    averagePercentiles.p5 /= windowPercentiles.length;
    averagePercentiles.p25 /= windowPercentiles.length;
    averagePercentiles.p40 /= windowPercentiles.length;
    averagePercentiles.p50 /= windowPercentiles.length;
    averagePercentiles.p60 /= windowPercentiles.length;
    averagePercentiles.p75 /= windowPercentiles.length;
    averagePercentiles.p95 /= windowPercentiles.length;
    averagePercentiles.p99 /= windowPercentiles.length;

    // Get only the last 4 seconds of data
    let actualData = [];
    const endTime = readings[readings.length-1].timestamp;
    const startTime = endTime - 500;
    for(let i=readings.length-1; i>=0; i--) {
      if (readings[i].timestamp >= startTime) {
        actualData.unshift(readings[i].z);
      }
      else {
        break;
      }
    }

    if (actualData.length < 50) return;


    // const segmentSize = /* Number of data points in 500ms */;
    // const complianceResults = this.segmentDataAndAnalyze(actualData, segmentSize);

    // // Process the results to determine overall compliance
    // const overallCompliance = complianceResults.every(result => result);


    // // Histogram
    // const histogram = this.createHistogram(actualData, 10); // 10 bins for example
    // // console.log(histogram);

    // // Percentiles
    // const p5 = this.calculatePercentile(actualData, 5); // 5th percentile
    // const p25 = this.calculatePercentile(actualData, 25); // 25th percentile
    // const p50 = this.calculatePercentile(actualData, 50); // 50th percentile (median)
    // const p75 = this.calculatePercentile(actualData, 75); // 75th percentile
    // const p95 = this.calculatePercentile(actualData, 95); // 95th percentile
    // // console.log(`25th percentile: ${p25}, 50th percentile: ${p50}, 75th percentile: ${p75}`);

    const idealProfile = this.generateIdealAccelerationProfile();
    const tooShallowProfile = this.generateIdealAccelerationProfile(1.5, 2, 500, 120);

    // Interpolate the actual data to match the ideal profile length
    actualData = this.linearInterpolate(actualData, idealProfile.length);

    // Example usage:
    const correlation = this.calculateCorrelation(actualData, idealProfile);
    const rmsd = this.calculateRMSD(actualData, idealProfile);

    // // console.log(`Correlation: ${correlation}, RMSD: ${rmsd}`);
    // // Only report the results if the p95 is at least the minimum threshold
    // if (p95 >= GyroscopeService.minAcceleration) {
    //   this.comparisons = { correlation, rmsd, p5, p25, p50, p75, p95 };
    // }
    this.ideal = {
      correlation: 1,
      rmsd: 0,
      p1: this.calculatePercentile(idealProfile, 1),
      p5: this.calculatePercentile(idealProfile, 5),
      p25: this.calculatePercentile(idealProfile, 25),
      p40: this.calculatePercentile(idealProfile, 40),
      p50: this.calculatePercentile(idealProfile, 50),
      p60: this.calculatePercentile(idealProfile, 60),
      p75: this.calculatePercentile(idealProfile, 75),
      p95: this.calculatePercentile(idealProfile, 95),
      p99: this.calculatePercentile(idealProfile, 99),
    };

    this.tooShallow = {
      correlation: 1,
      rmsd: 0,
      p1: this.calculatePercentile(tooShallowProfile, 1),
      p5: this.calculatePercentile(tooShallowProfile, 5),
      p25: this.calculatePercentile(tooShallowProfile, 25),
      p40: this.calculatePercentile(tooShallowProfile, 40),
      p50: this.calculatePercentile(tooShallowProfile, 50),
      p60: this.calculatePercentile(tooShallowProfile, 60),
      p75: this.calculatePercentile(tooShallowProfile, 75),
      p95: this.calculatePercentile(tooShallowProfile, 95),
      p99: this.calculatePercentile(tooShallowProfile, 99),
    };

    let percentileValid = true;
    let invalidReason = '';
    const p50Tolerance = 0.5;
    if (cycleFrequency < 100 || cycleFrequency > 140) {
      percentileValid = false;
      invalidReason = `Cycle frequency is ${cycleFrequency.toFixed(2)} Hz`;
    }
    // p50 should be somewhere around 0, within some tolerance
    else if (averagePercentiles.p99 > GyroscopeService.maxAcceleration) {
      percentileValid = false;
      invalidReason = `p99 is ${averagePercentiles.p99.toFixed(2)}`;
    }
    else if (averagePercentiles.p1 < (-1 * GyroscopeService.maxAcceleration)) {
      percentileValid = false;
      invalidReason = `p1 is ${averagePercentiles.p1.toFixed(2)}`;
    }
    // 75 should be at least as high as min acceleration
    else if (averagePercentiles.p75 < GyroscopeService.minAcceleration) {
      percentileValid = false;
      invalidReason = `p75 is ${averagePercentiles.p75.toFixed(2)}`;
    }
    // 25 should be at least as low as max acceleration
    else if (averagePercentiles.p25 > (-1 * GyroscopeService.minAcceleration)) {
      percentileValid = false;
      invalidReason = `p25 is ${averagePercentiles.p25.toFixed(2)}`;
    }
    // 40 should be no higher than the min acceleration
    else if (averagePercentiles.p40 > GyroscopeService.minAcceleration) {
      percentileValid = false;
      invalidReason = `p40 is ${averagePercentiles.p40.toFixed(2)}`;
    }
    // 60 should be no lower than the max acceleration
    else if (averagePercentiles.p60 < (-1 * GyroscopeService.minAcceleration)) {
      percentileValid = false;
      invalidReason = `p60 is ${averagePercentiles.p60.toFixed(2)}`;
    }
    // else if (Math.abs(averagePercentiles.p50) > p50Tolerance) {
    //   percentileValid = false;
    // }
    // p95 should be at least as high as p95 from ideal profile
    else if (averagePercentiles.p95 < this.ideal.p95) {
      percentileValid = false;
      invalidReason = `p95 is ${averagePercentiles.p95.toFixed(2)}`;
    }
    // p5 should be at least as low as p5 from ideal profile
    else if (averagePercentiles.p5 > this.ideal.p5) {
      percentileValid = false;
      invalidReason = `p5 is ${averagePercentiles.p5.toFixed(2)}`;
    }

    // Now run a comparison against the ideal profile, by percentiles
    const idealPercentiles = this.runPercentilesAnalysis(idealProfile);
    // Comprae the average percentiles to the ideal percentiles
    const averagePercentilesArray = [averagePercentiles.p5, averagePercentiles.p25, averagePercentiles.p40, averagePercentiles.p50, averagePercentiles.p60, averagePercentiles.p75, averagePercentiles.p95];
    const idealPercentilesArray = [idealPercentiles.p5, idealPercentiles.p25, idealPercentiles.p40, idealPercentiles.p50, idealPercentiles.p60, idealPercentiles.p75, idealPercentiles.p95];
    const percentileCorrelation = this.calculateCorrelation(averagePercentilesArray, idealPercentilesArray);
    const percentileRMSD = this.calculateRMSD(averagePercentilesArray, idealPercentilesArray);

    if (percentileValid) {
      // Now use correlation and RMSD to determine if the percentiles are valid
      // If the correlation is high and the RMSD is low, then the percentiles are valid
      const percentileCorrelationThreshold = 0.9;
      const percentileRMSDThreshold = 0.1;
      if (percentileCorrelation < percentileCorrelationThreshold) {
        percentileValid = false;
        invalidReason = `Correlation is ${percentileCorrelation.toFixed(2)}`;
      }
      // else if (percentileRMSD > percentileRMSDThreshold) {
      //   percentileValid = false;
      // }

    }

    this.comparisons = { correlation: percentileCorrelation, rmsd: percentileRMSD, ...averagePercentiles, percentileValid, cycleFrequency, invalidReason, cycleCount };
  }

  runPercentilesAnalysis(actualData: number[]) {
    // Percentiles
    const p1 = this.calculatePercentile(actualData, 1); // 1st percentile
    const p5 = this.calculatePercentile(actualData, 5); // 5th percentile
    const p25 = this.calculatePercentile(actualData, 25); // 25th percentile
    const p40 = this.calculatePercentile(actualData, 40); // 40th percentile
    const p50 = this.calculatePercentile(actualData, 50); // 50th percentile (median)
    const p60 = this.calculatePercentile(actualData, 60); // 60th percentile
    const p75 = this.calculatePercentile(actualData, 75); // 75th percentile
    const p95 = this.calculatePercentile(actualData, 95); // 95th percentile
    const p99 = this.calculatePercentile(actualData, 99); // 99th percentile

    return { p1, p5, p25, p40, p50, p60, p75, p95, p99 };
  }


  calculateCorrelation(actualData: number[], idealData: number[]): number {
    let meanActual = actualData.reduce((a, b) => a + b, 0) / actualData.length;
    let meanIdeal = idealData.reduce((a, b) => a + b, 0) / idealData.length;

    let numerator = 0;
    let denominatorActual = 0;
    let denominatorIdeal = 0;

    for (let i = 0; i < actualData.length; i++) {
        numerator += (actualData[i] - meanActual) * (idealData[i] - meanIdeal);
        denominatorActual += (actualData[i] - meanActual) ** 2;
        denominatorIdeal += (idealData[i] - meanIdeal) ** 2;
    }

    return numerator / Math.sqrt(denominatorActual * denominatorIdeal);
  }

  calculateRMSD(actualData: number[], idealData: number[]): number {
      let sumOfSquares = 0;

      for (let i = 0; i < actualData.length; i++) {
          sumOfSquares += (actualData[i] - idealData[i]) ** 2;
      }

      return Math.sqrt(sumOfSquares / actualData.length);
  }

  // createHistogram(data: number[], binCount: number): { binLimits: number[], counts: number[] } {
  //   const minValue = Math.min(...data);
  //   const maxValue = Math.max(...data);
  //   const binSize = (maxValue - minValue) / binCount;

  //   let counts = new Array(binCount).fill(0);
  //   let binLimits = new Array(binCount).fill(0).map((_, i) => minValue + i * binSize);

  //   data.forEach(value => {
  //       let index = Math.min(Math.floor((value - minValue) / binSize), binCount - 1);
  //       counts[index]++;
  //   });

  //   return { binLimits, counts };
  // }

  createHistogram(data: number[], binCount: number): { labels: string[], data: number[] } {
    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);
    const binSize = (maxValue - minValue) / binCount;

    let counts = new Array(binCount).fill(0);
    let labels = new Array(binCount);

    for (let i = 0; i < binCount; i++) {
        // Calculate the bin limits
        const lowerLimit = minValue + i * binSize;
        const upperLimit = lowerLimit + binSize;

        // Format the bin range as a label
        labels[i] = `${lowerLimit.toFixed(2)} to ${upperLimit.toFixed(2)}`;
    }

    data.forEach(value => {
        let index = Math.min(Math.floor((value - minValue) / binSize), binCount - 1);
        counts[index]++;
    });

    return { labels, data: counts };
  }

  calculatePercentile(data: number[], percentile: number): number {
    const sortedData = [...data].sort((a, b) => a - b);
    const index = (percentile / 100) * (sortedData.length - 1);
    const lower = Math.floor(index);
    const upper = Math.ceil(index);
    return sortedData[lower] + (sortedData[upper] - sortedData[lower]) * (index - lower);
  }


  splineInterpolate(data: number[], timestamps: number[], targetInterval: number): {data: number[], timestamps: number[]} {
    // Create an interpolator function
    const interpolator = d3.interpolateBasis(data);

    // Create a new time series with consistent intervals
    const startTime = timestamps[0];
    const endTime = timestamps[timestamps.length - 1];
    const interpolatedData = [];
    const interpolatedTimestamps = [];

    for (let t = startTime; t <= endTime; t += targetInterval) {
        interpolatedData.push(interpolator((t - startTime) / (endTime - startTime)));
        interpolatedTimestamps.push(t);
    }

    return {
      data: interpolatedData,
      timestamps: interpolatedTimestamps,
    };
  }


  linearInterpolate(data: number[], targetLength: number): number[] {
    let interpolatedData = [];
    let scaleFactor = (data.length - 1) / (targetLength - 1);

    for (let i = 0; i < targetLength; i++) {
        let index = i * scaleFactor;
        let lowerIndex = Math.floor(index);
        let upperIndex = Math.ceil(index);
        let t = index - lowerIndex;

        interpolatedData[i] = (1 - t) * data[lowerIndex] + t * data[Math.min(upperIndex, data.length - 1)];
    }

    return interpolatedData;
  }



  generateIdealAccelerationProfile(amplitudeInches: number = 2.5, frequencyHz: number = 2, durationMs: number = 500, intervals: number = 120): number[] {
    const amplitudeMeters = amplitudeInches * 0.0254;  // Convert inches to meters
    const omega = 2 * Math.PI * frequencyHz;  // Angular frequency in rad/s
    const idealProfile = [];

    for (let i = 0; i <= intervals; i++) {
        const timeSeconds = (i / intervals) * (durationMs / 1000);  // Convert time to seconds
        const acceleration = -amplitudeMeters * omega ** 2 * Math.sin(omega * timeSeconds);
        idealProfile.push(acceleration);
    }

    return idealProfile;
  }



  static minAcceleration = 10.03 /* Calculated Minimum Acceleration */;
  static maxAcceleration = 5 * 9.81 /* Estimated Maximum Acceleration - e.g., 3g-5g */;

  analyzeMovementSegment(dataSegment: number[]): boolean {
    const peakAcceleration = Math.max(...dataSegment.map(a => Math.abs(a)));
    return peakAcceleration >= GyroscopeService.minAcceleration && peakAcceleration <= GyroscopeService.maxAcceleration;
  }

  segmentDataAndAnalyze(data: number[], segmentSize: number): boolean[] {
      let results = [];
      for (let i = 0; i < data.length; i += segmentSize) {
          const dataSegment = data.slice(i, i + segmentSize);
          results.push(this.analyzeMovementSegment(dataSegment));
      }
      return results;
  }

  // calculateFft(data: number[], frequencyHz: number = 300): { frequencies: number[], magnitudes: number[] } {
  //   // Apply FFT
  //   try {
  //     const _fft = fft;
  //     const zValues = data.map(item => [item, 0]);
  //     const phasors = _fft(zValues);
  //     const frequencies = fftUtil.fftFreq(phasors, frequencyHz); // Sample rate and coef is just used for length, and frequency step
  //     const magnitudes = fftUtil.fftMag(phasors);

  //     return {
  //       frequencies,
  //       magnitudes,
  //     };
  //   }
  //   catch (err) {
  //     console.error(err);
  //     return {
  //       frequencies: [],
  //       magnitudes: [],
  //     };
  //   }
  // }

}

enum DistanceIntegrationMethod {
  Luca,
  Trapezoidal,
  Simpson,
  RungeKutta
}

export class DistanceCalculator {

  static calculateDistance(readings: Array<Partial<{ z: number, timestamp: number }>>,
    method: DistanceIntegrationMethod = DistanceIntegrationMethod.Trapezoidal): number {
    let velocity = { z: 0 };
    let distance = { z: 0 };

    if (method === DistanceIntegrationMethod.Luca) {
      // Luca's method
      // Assumes a 1ms sampling rate, with exactly 1 second of data
      const interpolated = DistanceCalculator.splineInterpolate(readings.map(r => r.z), readings.map(r => r.timestamp), 1, 1000);
      const {distMaxMin, compressionsFrequency} = this.calculateDistanceAndFrequency(readings.map(r => r.z));
      // console.log(`Luca's method: ${distMaxMin} cm, ${compressionsFrequency} compressions per minute`);
      return distMaxMin;
    }

    // if (method === DistanceIntegrationMethod.Simpson) {
    //   // Simpson's Rule requires an even number of intervals
    //   if (readings.length % 2 !== 0) {
    //     readings.push({ z: readings[readings.length - 1].z, timestamp: readings[readings.length - 1].timestamp });
    //   }
    //   distance.z = DistanceCalculator.applySimpsonsRule(readings.map(r => r.z), (readings[readings.length - 1].timestamp - readings[0].timestamp) / 1000);
    //   return Math.abs(distance.z);
    // }

    for (let i = 1; i < readings.length; i++) {
      const deltaTime = (readings[i].timestamp - readings[i - 1].timestamp - 2) / 1000;

      switch (method) {
        case DistanceIntegrationMethod.Trapezoidal:
          DistanceCalculator.applyTrapezoidalRule(readings[i], readings[i - 1], velocity, distance, deltaTime);
          break;
        case DistanceIntegrationMethod.Simpson:
          // Apply Simpson's Rule (requires even number of intervals, more complex to implement)
          // Not implemented here due to complexity and context
          DistanceCalculator.applySimpsonsRule([readings[i - 1].z, readings[i].z], deltaTime);
          break;
        case DistanceIntegrationMethod.RungeKutta:
          DistanceCalculator.applyRungeKuttaMethod(readings[i], velocity, distance, deltaTime);
          break;
      }
    }

    // Calculate distance along z axis only
    return Math.abs(distance.z);
    // return Math.sqrt(distance.x ** 2 + distance.y ** 2 + distance.z ** 2); // Total distance
  }

  static applyTrapezoidalRule(current: any, previous: any, velocity: any, distance: any, deltaTime: number) {
    ['z'].forEach(axis => {
      velocity[axis] += 0.5 * (current[axis] + previous[axis]) * deltaTime;
      distance[axis] += velocity[axis] * deltaTime;
    });
  }

  static applyRungeKuttaMethod(current: any, velocity: any, distance: any, deltaTime: number) {
    ['z'].forEach(axis => {
      const k1 = deltaTime * current[axis];
      const k2 = deltaTime * (current[axis] + k1 / 2);
      const k3 = deltaTime * (current[axis] + k2 / 2);
      const k4 = deltaTime * (current[axis] + k3);
      velocity[axis] += (k1 + 2 * k2 + 2 * k3 + k4) / 6;
      distance[axis] += velocity[axis] * deltaTime;
    });
  }

  static applySimpsonsRule(data: number[], deltaTime: number): number {
    if (data.length % 2 !== 0) {
      // throw new Error("Simpson's rule requires an even number of data points.");
      return 0;
    }

    let total = data[0] + data[data.length - 1];
    for (let i = 1; i < data.length - 1; i++) {
        if (i % 2 === 0) {
            total += 2 * data[i];
        } else {
            total += 4 * data[i];
        }
    }
    return total * deltaTime / 3;
  }

  static calculateDistanceAndFrequency(values: number[]): { compressionsFrequency: number, distMaxMin: number } {
    let compressionsFrequency = 0;
    let distMaxMin = 0;

    // Positive Peak Detector
    let peaksPositive: number[] = [];
    for (let i = 1; i < values.length - 1; i++) {
        if (values[i - 1] < values[i] && values[i + 1] < values[i]) {
            if (values[i] > 5) {
                peaksPositive.push(i);
            }
        }
    }

    // Negative Peak Detector
    let peaksNegative: number[] = [];
    for (let i = 1; i < values.length - 1; i++) {
        if (values[i - 1] > values[i] && values[i + 1] > values[i]) {
            if (values[i] < -5) {
                peaksNegative.push(i);
            }
        }
    }

    console.log(peaksNegative);
    console.log(peaksPositive);

    if (peaksPositive.length > 0) {
        let realPeaksNegative = peaksNegative.filter(i => i > peaksPositive[0]);

        if (realPeaksNegative.length > 0) {
            let diffTime = Math.abs((peaksPositive[0] - realPeaksNegative[0]) / values.length);

            // Calculate frequency per minute
            compressionsFrequency = Math.round(60 / (diffTime * 2));

            // Amplitude difference between the first positive and negative peak
            distMaxMin = Math.round(Math.abs(values[peaksPositive[0]] - values[realPeaksNegative[0]]) * 100) / 100;
        }
    }

    return { compressionsFrequency, distMaxMin };
  }

  static splineInterpolate(data: number[], timestamps: number[], targetInterval: number, maxTimeDiff?: number): {data: number[], timestamps: number[]} {
    // Create a new time series with consistent intervals
    const startTime = timestamps[0];
    const endTime = timestamps[timestamps.length - 1];
    const startWindow = maxTimeDiff ? Math.max(startTime, endTime - maxTimeDiff) : startTime;

    // Filter out any timestamps that are too old
    const filteredData = [];
    const filteredTimestamps = [];
    for (let i = 0; i < data.length; i++) {
        if (timestamps[i] >= startWindow) {
            filteredData.push(data[i]);
            filteredTimestamps.push(timestamps[i]);
        }
    }

    // Create an interpolator function
    const interpolator = d3.interpolateBasis(filteredData);

    const interpolatedData = [];
    const interpolatedTimestamps = [];

    for (let t = startTime; t <= endTime; t += targetInterval) {
        interpolatedData.push(interpolator((t - startTime) / (endTime - startTime)));
        interpolatedTimestamps.push(t);
    }

    return {
      data: interpolatedData,
      timestamps: interpolatedTimestamps,
    };
  }

}
