<!-- If Game exists, then show details -->
<div *ngIf="game">
  <div *ngIf="!game?.dateEnded" class="game-details">
    <!-- Big button to go to the game -->
    <div class="game-details__button">
      <button (click)="goToGame()" class="blue-button">
        Go to Game
      </button>
    </div>
    <div class="game-details__title">{{ game?.name }}</div>
    <div class="game-details__teams">
      <div class="game-details__teams__title">Teams</div>
      <div class="game-details__teams__list">
        <div *ngFor="let team of game?.teams" class="game-details__teams__list__item">
          <div class="game-details__teams__list__item__name">{{ team.name }}</div>
          <div class="game-details__teams__list__item__members">
            <div *ngFor="let player of team.players" class="game-details__teams__list__item__members__member">
              {{ player.name }} --> {{ player.score }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button (click)="scorePoint()" class="session-toggle-button">
      Score!
    </button> -->
  </div>
  <div *ngIf="!!game?.dateEnded" class="game-details__game-over">
    <div class="game-details__title">Game Over!</div>
    <div class="game-details__teams">
      <div class="game-details__teams__title">Final Scores</div>
      <div class="game-details__teams__list">
        <div *ngFor="let team of game?.teams" class="game-details__teams__list__item">
          <div class="game-details__teams__list__item__name">{{ team.name }}</div>
          <div class="game-details__teams__list__item__members">
            <div *ngFor="let player of team.players" class="game-details__teams__list__item__members__member">
              {{ player.name }}: {{ player.score }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="game-container">
    <div class="game-container__title">Game</div>
    <div class="game-container__content">
      <div class="game-container__content__video">
        <app-video-player [videoSrc]="videoSrc" (sceneEnd)="onSceneEnd($event)"></app-video-player>
      </div>
      <div class="game-container__content__question">
        <app-question *ngIf="currentQuestion" [question]="currentQuestion" (answerSubmitted)="onAnswerSubmitted($event)"></app-question>
      </div>
    </div>
  </div> -->
</div>
<div *ngIf="!game">
  <!-- Show loading -->
  <div class="loading-container">
    <div class="loading-container__title">Loading...</div>
  </div>
</div>
