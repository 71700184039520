<!-- Center horizontally and veritically -->
<div class="flex flex-col justify-center items-center h-[100vh] relative">
  <!-- Connection Status Indicator -->
  <div class="absolute top-4 right-4 z-50 px-3 py-1 rounded-full text-sm font-semibold transition-colors duration-300 flex items-center gap-2"
       [ngClass]="isWebSocketConnected ? (isActiveCprSession ? 'bg-green-500 text-black' : 'bg-blue-400 text-black') : 'bg-orange-300 text-black'">
    <div *ngIf="isActiveCprSession || !isWebSocketConnected" class="w-2 h-2 rounded-full"
         [ngClass]="{'bg-black animate-pulse': isWebSocketConnected, 'bg-black': !isWebSocketConnected}"></div>
    <span *ngIf="isActiveCprSession && isWebSocketConnected">Transmitting</span>
    <span *ngIf="!isActiveCprSession && isWebSocketConnected">Ready</span>
    <span *ngIf="!isWebSocketConnected">Standby</span>
  </div>

  <!-- Game Timer - visible when game is loaded -->
  <div *ngIf="gameState?.lastTimestamp !== undefined && gyroState === FetchState.LOADED_ALL"
       class="absolute top-4 left-4 transform z-50 px-3 py-1 bg-black bg-opacity-50 opacity-75 rounded-full text-white text-lg font-mono font-semibold flex items-center gap-2">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
    </svg>
    <span>{{ formatTime(gameState?.lastTimestamp) }}</span>
  </div>

  <!-- Add test mode button in setup screen -->
  <div *ngIf="gyroState === FetchState.LOADED_ALL && !isActiveCprSession && !game?.dateEnded" class="absolute bottom-8 right-4">
    <button class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg text-sm"
            [class.bg-red-500]="!!_testModeInterval"
            (click)="_testModeInterval ? stopTestMode() : startTestMode()">
      {{ !!_testModeInterval ? 'Stop Test' : 'Test Connection' }}
    </button>
  </div>

  <div class="absolute inset-x-0 bottom-0 w-full h-full bg-[#1f1f1f] flex justify-center items-center">
    <div class="absolute inset-0 bg-gradient-to-b from-[#1f1f1f] to-[rgba(20,255,0,0.5)] bg-[size:100%_35%] bg-no-repeat bg-bottom"></div>
    <!-- <img src="assets/cardiac_star.svg" class="w-full h-full" alt="logo" > -->
  </div>
  <!-- <div class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
  </div> -->

  <!-- <div class="absolute inset-0" style="transform-origin: 0px 0px;">
    <svg class="aab">
      <radialGradient id="aab" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
        <stop offset="0" stop-color="#14ff00" stop-opacity="1"></stop>
        <stop offset="0.7488" stop-color="#1f1f1f" stop-opacity="1"></stop>
        <stop offset="1" stop-color="#1f1f1f" stop-opacity="1"></stop>
      </radialGradient>
      <ellipse id="aab" rx="612" ry="612" cx="612" cy="612">
      </ellipse>
    </svg>
  </div> -->
  <div *ngIf="gyroState !== FetchState.LOADED_ALL" class="relative flex flex-col justify-center items-center">
    <img src="assets/cardiac_star.svg" class="w-full" alt="logo" >
    <button *ngIf="gyroState === FetchState.GOOD || gyroState === FetchState.NONE" (click)="startGyro()" class="green-button">
      Grant Gyro Access
    </button>
    <div *ngIf="gyroState === FetchState.LOADING"
      class="absolute inset-0 bg-gray-100 bg-opacity-25 flex justify-center items-center">
      <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
    </div>
    <div *ngIf="gyroState === FetchState.ERROR"
      class="absolute inset-0 bg-red-500 bg-opacity-25 flex justify-center items-center">
      <div class="text-2xl text-white">Error: {{gyroError}}</div>
    </div>
  </div>
  <div *ngIf="gyroState === FetchState.LOADED_ALL && !isActiveCprSession && !game?.dateEnded" class="relative flex flex-col justify-center items-center w-full h-full">
    <!-- Display the player's name, which is a number, promintently -->
    <div *ngIf="!!player && !!team" class="flex flex-col justify-center items-center">
      <div class="flex flex-col items-center justify-center py-6">
        <div class="text-4xl text-white">You're on</div>
        <div class="text-6xl font-bold text-green-500">{{team.name}}</div>
      </div>
      <div class="flex flex-col items-center justify-center py-6">
        <div class="text-4xl text-white">You are</div>
        <div class="text-4xl text-orange-500">Number</div>
        <div class="text-8xl font-bold text-orange-500">{{player.name}}</div>
        <p *ngIf="hasScore" class="text-4xl font-bold italic text-white font-barlow">Your Score</p>
      </div>
      <div *ngIf="hasScore" class="flex flex-col items-center justify-center py-6">
        <div class="text-2xl font-bold text-white">Depth: {{depthScore}}%</div>
        <div class="text-2xl font-bold text-white">Rate: {{compressionScore}}%</div>
        <div class="text-2xl font-bold text-white">Quality: {{qualityScore}}%</div>
        <div class="text-4xl font-bold text-white">Overall: {{overallScore}}%</div>
      </div>

      <div class="flex flex-col items-center justify-center p-10">
        <div class="text-4xl text-white">Sit on the {{playerSide}} side when facing the screen</div>
      </div>

    </div>
  </div>

  <!-- Gyro Data -->
  <div *ngIf="isActiveCprSession && !game?.dateEnded && gyroState === FetchState.LOADED_ALL" class="relative bg-green-500 w-full h-full p-4 flex flex-col justify-around items-center">
    <!-- <app-gyro-data [gyroData]="gyroData"></app-gyro-data> -->
    <div class="flex flex-col items-center justify-center py-6 gap-6">
      <div class="text-6xl text-black">Time To Do</div>
      <div class="text-8xl font-bold text-black">CPR!</div>
    </div>
    <img src="assets/cardiac_star_only.svg" class="w-full max-w-[211px] ml-[20px]" alt="logo" >
    <div class="flex flex-col items-center justify-center py-6">
      <div class="text-4xl text-black">Go To Spring</div>
      <div class="text-8xl font-bold text-black">{{player.name}}</div>
    </div>
    <!-- <app-interactive-results [histogram]="histogram"></app-interactive-results> -->
  </div>

  <!-- <div *ngIf="!game?.dateEnded" class="rounded-xl bg-gray-100 p-4 flex flex-col">
    <app-team-scoreboard [team]="team" [player]="player"></app-team-scoreboard>
  </div> -->
  <div *ngIf="gyroState === FetchState.LOADED_ALL && !!game?.dateEnded" class="relative flex flex-col justify-center items-center w-full h-full">
    <div class="w-full flex flex-col items-center justify-center h-screen bg-orange-500 gap-4">
      <div *ngIf="hasScore" class="w-full text-center">
        <p class="text-4xl font-bold italic text-gray-900 font-barlow">Your Score</p>
        <div class="text-2xl font-bold text-black">Depth: {{depthScore}}%</div>
        <div class="text-2xl font-bold text-black">Rate: {{compressionScore}}%</div>
        <div class="text-2xl font-bold text-black">Quality: {{qualityScore}}%</div>
        <div class="text-4xl font-bold text-black">Overall: {{overallScore}}%</div>
      </div>
      <div class="w-full flex justify-center">
        <img src="assets/cardiac_star_only.svg" class="w-full max-w-[211px] ml-[20px]" alt="logo" >
      </div>
      <div class="w-full text-center">
        <p class="text-4xl font-black italic text-gray-900 font-barlow">{{scoreMessage}}</p>
      </div>
      <!-- Take Questionnaire Button -->
      <button (click)="showQuestionnaire = true" class="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 font-barlow">
        Take Questionnaire
      </button>
    </div>
  </div>

  <div *ngIf="showQuestionnaire" class="absolute inset-0">
    <app-questionnaire></app-questionnaire>
  </div>
</div>
