import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  title = 'CPR Training App';

  /**
    Whenever a new route is activated
    @param _event
  */
    private _scrollToTop: NodeJS.Timeout | undefined;
    onActivate(_event: any): void {
      if (this.mainContentDiv) {
        if (this._scrollToTop) clearInterval(this._scrollToTop);
        this._scrollToTop = setInterval(() => {
          // Get scroll position of mainContentDiv
          let pos = (this.mainContentDiv.nativeElement as HTMLElement).scrollTop;
          if (pos > 0) {
            (this.mainContentDiv.nativeElement as HTMLElement).scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
            if (this._scrollToTop) {
              clearInterval(this._scrollToTop);
              this._scrollToTop = undefined;
            }
          }
      }, 16);
      }
    }

}
