<!-- scoreboard.component.html -->
<div class="grid grid-cols-2 gap-4">
  <div *ngFor="let team of teams" class="rounded-xl bg-gray-100 p-4">
    <!-- <div class="flex justify-center items-center">
      <button (click)="scorePoint()" class="blue-button">
        Score!
      </button>
    </div> -->
    <app-team-scoreboard [team]="team"></app-team-scoreboard>
  </div>
</div>
