import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GameController } from 'src/app/controllers/game.controller';
import { Player, Team } from 'src/app/models/team.model';

@Component({
  selector: 'app-team-interaction',
  templateUrl: './team-interaction.component.html',
  styleUrl: './team-interaction.component.css'
})
export class TeamInteractionComponent {

  constructor(
    protected readonly gameController: GameController
  ) { }


  private _team: Team;
  @Input()
  set team(team: Team) {
    this._team = team;
    this.selectedPlayer = team?.players[0];
  }
  get team(): Team {
    return this._team;
  }

  selectedPlayer: Player;

  get currentPlayers(): Player[] {
    // current players are the players of the selected team where the first character of the name matches the current session number
    const currentCprSession = this.gameController?.currentCprSessions[0];
    if (!currentCprSession) {
      return [];
    }
    const result = this.team?.players.filter(p => p.name.length > 0 && p.name[0] === `${currentCprSession.groupNumber}`);
    return result;
    // return this.team?.players;
  }

  selectPlayer(player: Player) {
    this.gameController.toggleSelectedPlayer(player);
  }
}
