<div #slidingContainer class="w-full h-full bg-none flex flex-col justify-center items-center relative">
  <!-- Cross hairs for the diamond to target -->
  <!-- Vertical -->
  <div *ngIf="movementDirection === 'vertical'" class="absolute inset-0">
    <!-- 1/4 from the top -->
    <!-- <div class="absolute left-[40%] right-[40%] top-1/3 h-0.5 bg-white"></div> -->
    <!-- half way -->
    <!-- <div class="absolute inset-x-2 top-1/3 h-0.5 bg-white"></div> -->
    <!-- half way -->
    <div class="absolute left-0 right-1/2 top-1/2 h-0.5 bg-white"></div>
    <!-- 3/4 from the top -->
    <!-- <div class="absolute left-[40%] right-[40%] bottom-1/4 h-0.5 bg-white"></div> -->
    <!-- <div class="absolute left-[40%] right-[40%] bottom-1/3 h-0.5 bg-white"></div> -->
  </div>
  <!-- Horizontal -->
  <div *ngIf="movementDirection === 'horizontal'" class="absolute inset-0">
    <!-- 1/4 from the left -->
    <div class="absolute top-[40%] bottom-[40%] left-1/4 w-0.5 bg-white"></div>
    <!-- half way -->
    <div class="absolute inset-y-2 left-1/2 w-0.5 bg-white"></div>
    <!-- 3/4 from the left -->
    <div class="absolute top-[40%] bottom-[40%] right-1/4 w-0.5 bg-white"></div>
  </div>

  <div #diamondContainer class="flex flex-col justify-center items-center aspect-square absolute top-0 transition-all duration-500 ease-in-out"
  [ngClass]="{
    'h-full': movementDirection === 'horizontal',
    'w-full': movementDirection === 'vertical'
  }"
  [ngStyle]="styleObject"
  >
    <!-- <div class="diamond-wrapper relative w-24 h-24 overflow-clip"> -->
    <div class="diamond-wrapper relative w-[66.7%] h-[66.7%] overflow-clip">
      <!-- <div class="fill absolute rotate-45 bottom-0 -left-24 w-[272px] h-0 bg-red-500 transition-all duration-500 text-white" [style.height]="(fillHeight * 136/100) + 'px'" [style.backgroundColor]="fillColor">{{fillHeight.toFixed(1)}}</div> -->
      <div class="diamond absolute top-0 left-0 w-full h-full bg-transparent border-none border-black flex flex-col justify-center items-center"
      [ngStyle]="{
        'border-color': fillColor,
      }"
      >
      <!-- [ngClass]="{
        'border-red-500 bg-red-500': fillHeight < 50,
        'border-green-500 bg-green-500': fillHeight >= 50
      }" -->
        <!-- fill div that grows from middle out -->
        <!-- <div class="fill bg-red-500 fill-container text-white flex justify-center items-center" [style.height]="fillHeight + '%'" [style.width]="fillHeight + '%'" [style.backgroundColor]="fillColor"> -->
        <div class="fill bg-red-500 fill-container text-white flex justify-center items-center" [style.height]="'100%'" [style.width]="'100%'" [style.backgroundColor]="fillColor">
          <!-- <div class="fill-text text-[20%] rotate-45">{{fillHeight.toFixed(0)}}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>
