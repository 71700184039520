<div class="question-container">
  <div *ngFor="let option of question?.options; index as i" (click)="submitAnswer(option)"
  [ngStyle]="getStyle(i)"
  >
    <!-- Image question -->
    <div *ngIf="isImageQuestion" class="cursor-pointer">
      <img class="w-[100%] h-[100%] max-w-[17.1975vw] max-h-[20.83vw]" [src]="getImageSrc(option)" />
    </div>
    <!-- Basic, multiple choice question -->
    <button *ngIf="!isImageQuestion"
    class="question-button flex flex-row justify-center items-center" [ngClass]="{
      'correct': option === selectedAnswer && question.checkAnswer(option),
      'incorrect': option === selectedAnswer && !question.checkAnswer(option),
      'unanswered': !selectedAnswer
      }" [disabled]="!interactable">

    <div appAutoResizeText class="fit-text" [innerHTML]="option | cleanText"></div>
  </button>
  </div>
</div>
