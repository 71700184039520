import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FetchState } from 'src/app/graphql.module';
import { Game, GameStatus } from 'src/app/models/game.model';
import { Question } from 'src/app/models/question.model';
import { Team } from 'src/app/models/team.model';
import { CPRSession } from 'src/app/models/video-interaction.model';
import { GyroscopeService, HistogramData } from 'src/app/services/gyroscope.service';
import { GameStateDetails, PlayerService } from 'src/app/services/player.service';
import { TelemetryService } from '../../services/telemetry.service';
import { BatchingService } from '../../services/batching.service';


@Component({
  selector: 'app-player-game',
  templateUrl: './player-game.component.html',
  styleUrls: ['./player-game.component.css']
})
export class PlayerGameComponent implements OnInit, OnDestroy {

  get game(): Game {
    return this.playerService.game;
  }

  private _gameState: GameStateDetails = {
    id: '',
    status: GameStatus.NOT_STARTED,
    lastTimestamp: 0,
    teams: [],
  };
  get gameState(): GameStateDetails {
    return this._gameState;
  }

  private _showQuestionnaire = false;
  get showQuestionnaire(): boolean {
    return this._showQuestionnaire;
  }
  set showQuestionnaire(value: boolean) {
    this._showQuestionnaire = value;
  }
  toggleShowQuestionnaire() {
    this.showQuestionnaire = !this.showQuestionnaire;
  }

  FetchState = FetchState;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly playerService: PlayerService,
    private readonly gyroService: GyroscopeService,
    private readonly batchingService: BatchingService,
    public telemetryService: TelemetryService
  ) {
    // Subscribe to connection status changes
    this.telemetryService.connectionStatus$.subscribe(connected => {
      this.isWebSocketConnected = connected;
    });
  }

  @ViewChild('backgroundVideo', { static: true }) backgroundVideo!: ElementRef;
  private wakeLock: any = null;

  async requestWakeLock() {
    // Check if Wake Lock API is supported
    if ('wakeLock' in navigator) {
      try {
        this.wakeLock = await (navigator as any).wakeLock.request('screen');
      } catch (err) {
        console.error(`Failed to acquire wake lock: ${err}`);
        this.startVideoFallback(); // Fallback to video if API fails
      }
    } else {
      this.startVideoFallback(); // Fallback to video if API not available
    }
  }

  releaseWakeLock() {
    // Release wake lock if possible
    if (this.wakeLock) {
      this.wakeLock.release().then(() => {
        this.wakeLock = null;
      });
    }
    // Stop video fallback if running
    this.stopVideoFallback();
  }

  private startVideoFallback() {
    const videoElement = this.backgroundVideo.nativeElement as HTMLVideoElement;
    if (!!videoElement) {
      videoElement.play();
    }
  }

  private stopVideoFallback() {
    const videoElement = this.backgroundVideo.nativeElement as HTMLVideoElement;
    if (!!videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  }

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.stopTestMode();
    this.releaseWakeLock();
    this.destroy$.next();
    this.destroy$.complete();
  }

  isWebSocketConnected = false;

  async ngOnInit(): Promise<void> {
    this.requestWakeLock();
    console.log('GameComponent ngOnInit');
    console.log('GameComponent route', this.route);

    // Get the game ID from the route
    const gameId = this.route.snapshot.paramMap.get('gameId');

    // Join the game
    if (!!gameId) {
      await this.playerService.joinGame(gameId);
    }

    this.telemetryService.gameStateDetailsUpdate$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((gameState) => {
      this._gameState = gameState;
      if (this._gameState?.status === GameStatus.FINISHED) {
        if (!!this.game) {
          this.game.dateEnded = this.game.dateEnded || new Date();
        }
      }
      else {
        this.game.dateEnded = null;
      }
      this.setIsActiveCprSession();
    });

    // // Subscribe to the game data
    // this.playerService.subscribeToGameState(gameId)
    // .pipe(
    //   takeUntil(this.destroy$),
    // )
    // .subscribe({
    //   next: (gameState) => {
    //     this._gameState = gameState;
    //     if (this._gameState?.status === GameStatus.FINISHED) {
    //       if (!!this.game) {
    //         this.game.dateEnded = this.game.dateEnded || new Date();
    //       }
    //     }
    //     else {
    //       this.game.dateEnded = null;
    //     }
    //     this.setIsActiveCprSession();
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   }
    // });

    // // Subscribe to WebSocket connection status
    // this.playerService.telemetryConnectionStatus$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(isConnected => {
    //     this.isWebSocketConnected = isConnected;
    //     console.log(`WebSocket connection status: ${isConnected ? 'Connected' : 'Disconnected'}`);
    //   });

    this.gyroService.startListening();

    this.gyroService.getMotionDataStream().pipe(
      takeUntil(this.destroy$),
    ).subscribe((gyroscopeData) => {
      this.gyroData = gyroscopeData;
    });

    setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);

    this.playerService.player$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((player) => {
      if (!player) {
        return;
      }
      this.player.overwrite(player);
    });

    this.gyroService.zDataStream$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((zData) => {
      this.lastTimestamp = Date.now();
      this.currentTime = Date.now();

      try {
        if (!!this.game?.id && !!this.playerService.playerId) {
          let player = this.game.teams.flatMap(t => t.players).find(p => p.id === this.playerService.playerId);
          if (!player) {
            return;
          }
          this.playerService.updatePlayerGyroAndOrientation(this.game.id, player.name, zData);
        }
      }
      catch (error) {
        console.error('Error updating player gyro and orientation', error);
      }
    });
  }

  isActiveCprSession: boolean = false;
  private previousActiveState = false;
  _testModeInterval: any = null;

  private setIsActiveCprSession() {
    if (!this.game || !this.game.interactions || !this.player || !this.gameState) {
      const newActiveState = false;
      if (this.previousActiveState !== newActiveState) {
        this.previousActiveState = newActiveState;
        this.activeCprSessionChanged(newActiveState);
      }
      this.isActiveCprSession = newActiveState;
      return;
    }

    let cprInteractions = (this.game?.interactions || []).filter(i => ((i as any) instanceof CPRSession)) as CPRSession[];

    // see if the gameStatus current timestamp falls within the start and end timestamps of the CPR session
    const newActiveState = (cprInteractions || []).some(i => {
      if (`${i.groupNumber}` !== this.player?.name[0]) {
        return false;
      }
      if (!i.markers || i.markers.length === 0) {
        return false;
      }
      let start = i.markers[0].timestamp || 0;
      let end = i.markers[i.markers.length - 1].timestamp || 0;
      return this.gameState.lastTimestamp >= start && this.gameState.lastTimestamp <= end;
    });

    // Check if active state changed
    if (this.previousActiveState !== newActiveState) {
      console.log(`CPR Session active state changed: ${newActiveState}`);
      this.previousActiveState = newActiveState;
      this.activeCprSessionChanged(newActiveState);
    }

    this.isActiveCprSession = newActiveState;

    // Check for upcoming CPR sessions to preemptively establish connection
    if (!this.isActiveCprSession) {
      const upcomingCprSession = this.findUpcomingCprSession();
      if (upcomingCprSession) {
        const timeToSessionStart = upcomingCprSession.startTime - this.gameState.lastTimestamp;
        if (timeToSessionStart > 0 && timeToSessionStart < 30000) { // 30 seconds before
          console.log(`CPR Session starting soon (${timeToSessionStart}ms), prepping connection`);
          this.telemetryService.ensureConnection();
        }
      }
    }
  }

  // Helper method to find upcoming CPR session
  private findUpcomingCprSession(): { startTime: number, endTime: number } | null {
    if (!this.game?.interactions || !this.player) return null;

    const cprInteractions = (this.game.interactions || []).filter(i =>
      ((i as any) instanceof CPRSession) &&
      `${(i as CPRSession).groupNumber}` === this.player?.name[0]
    ) as CPRSession[];

    // Find the next session that hasn't started yet
    let upcomingSession = null;
    let earliestStart = Number.MAX_SAFE_INTEGER;

    for (const session of cprInteractions) {
      if (!session.markers || session.markers.length === 0) continue;

      const startTime = session.markers[0].timestamp || 0;
      const endTime = session.markers[session.markers.length - 1].timestamp || 0;

      if (startTime > this.gameState.lastTimestamp && startTime < earliestStart) {
        earliestStart = startTime;
        upcomingSession = { startTime, endTime };
      }
    }

    return upcomingSession;
  }

  private activeCprSessionChanged(isActive: boolean) {
    if (isActive) {
      // Start sending telemetry data at higher frequency
      this.gyroService.setHighPrecisionMode(true);
      this.batchingService.setBatchSize('small'); // Send data more frequently
      console.log('CPR session active - sending telemetry data');
    } else {
      // Reduce data frequency when not in active session
      this.gyroService.setHighPrecisionMode(false);
      this.batchingService.setBatchSize('large'); // Batch more data, send less frequently
      console.log('CPR session inactive - reducing telemetry frequency');
    }
  }

  startTestMode() {
    console.log('Starting telemetry test mode');

    // Ensure connection is established
    this.telemetryService.ensureConnection().then(connected => {
      if (connected) {
        // Enable high precision mode
        this.gyroService.setHighPrecisionMode(true);

        // Use small batch size for frequent data updates
        this.batchingService.setBatchSize('small');

        // Start sending test data
        const testInterval = setInterval(() => {
          if (this.game?.id) {
            const testData = this.gyroService.getCurrentReading();
            if (testData) {
              this.playerService.updatePlayerGyroAndOrientation(
                this.game.id,
                this.player?.name || 'test',
                [testData]
              );
            }
          }
        }, 500); // Send test data every 500ms

        // Store interval reference for cleanup
        this._testModeInterval = testInterval;
      }
    });
  }

  stopTestMode() {
    if (this._testModeInterval) {
      clearInterval(this._testModeInterval);
      this._testModeInterval = null;

      // Reset to normal mode
      this.gyroService.setHighPrecisionMode(false);
      this.batchingService.setBatchSize('large');
      console.log('Stopped telemetry test mode');
    }
  }

  lastTimestamp: number = 0;
  get hasScore(): boolean {
    return !!this.player?.finalResults;
  }
  get lastAccelerationTime(): number {
    return this.gyroService.lastAccelerationTime;
  }
  get dumpReason(): string {
    return this.gyroService.dumpReason;
  }
  get dataLength(): number {
    return this.gyroService.dataLength || 0;
  }
  get dataLastTimestamp(): number {
    return this.gyroService.dataLastTimestamp || 0;
  }
  get dataLastTimeStampDiff(): number {
    return this.gyroService.lastTimeStampDiff || 0;
  }
  get dataLastInterval(): number {
    return this.gyroService.lastInterval || 0;
  }
  get dataLastOffset(): number {
    return this.gyroService.lastOffset || 0;
  }
  get depthScore() {
    return Math.round(this.player.finalResults.depthScore || 0);
  }
  get compressionScore() {
    return Math.round(this.player.finalResults.compressionScore || 0);
  }
  get qualityScore() {
    return Math.round(this.player.finalResults.qualityScore || 0);
  }
  get overallScore() {
    return Math.round(this.player.finalResults.overallScore || 0);
  }

  currentTime: number = Date.now();

  // // FFT Chart
  // public fftChartType: ChartType = 'line';  // Or 'scatter'
  // public fftChartData: ChartData<'line' | 'scatter'> = {
  //     labels: [],  // Initialize with empty labels
  //     datasets: [
  //         { data: [], label: 'FFT', fill: false, borderColor: 'blue', pointBackgroundColor: 'blue' }
  //     ]
  // };
  // public fftChartOptions: any = {
  //     responsive: true,
  //     scales: {
  //         yAxes: [{
  //             ticks: {
  //                 beginAtZero: true
  //             }
  //         }]
  //     },
  //     // Additional options as needed
  // };


  depthStats: { min: number, max: number, duration: number, averageFrequency: number } = { min: 0, max: 0, duration: 0, averageFrequency: 0 };
  gyroData: any = null;
  get jsonGyroData() {
    return JSON.stringify(this.gyroData, null, 2);
  }
  gyroState: FetchState = FetchState.NONE;
  gyroError: string | null = null;
  motionPermission = null;
  // orientationPermission = null;

  async startGyro() {
    this.gyroState = FetchState.LOADING;
    try {
      this.motionPermission = await this.gyroService.requestMotionPermission();
      console.log('motionPermission', this.motionPermission);
      // this.orientationPermission = await this.gyroService.requestOrientationPermission();
      // console.log('orientationPermission', this.orientationPermission);
      if (this.motionPermission) {
        this.gyroState = FetchState.LOADED_ALL;
        this.gyroService.startListening();
      }
      else {
        this.gyroError = 'Permission denied';
        this.gyroState = FetchState.ERROR;
      }
    }
    catch (error) {
      this.gyroError = error as string;
      this.gyroState = FetchState.ERROR;
    }
  }

  get player() {
    if (!this.playerService.playerId || !this.game?.id) {
      return null;
    }
    const result = this.game.teams.flatMap(t => t.players).find(p => p.id === this.playerService.playerId) || null;
    return result;
  }

  get playerSide(): string {
    if (!this.player) {
      return '';
    }
    // if player name as number mod 10 is less than 5, return 'left', else return 'right'
    const playerNumber = parseInt(this.player.name, 10);
    if (isNaN(playerNumber)) {
      return '';
    }
    return playerNumber % 10 < 5 ? 'LEFT' : 'RIGHT';
  }

  get team(): Team | null {
    if (!this.playerService.playerId) {
      return null;
    }
    if (!this.game?.id) {
      return null;
    }

    return this.game.teams.find(t => t.players.some(p => p.id === this.playerService.playerId)) || null;
  }

  get scoreMessage(): string {
    let score = this.player?.finalResults?.overallScore || 0;
    if (score > 80) {
      return "GREAT JOB!";
    }
    else if (score > 60) {
      return "GOOD JOB!";
    }
    else if (score > 40) {
      return "NICE TRY!";
    }
    else {
      return "TRY AGAIN!";
    }
  }

  get question(): Question | undefined {
    if (!this.game?.id) {
      return undefined;
    }
    return this.game.currentQuestion;
  }

  // Add this method to your PlayerGameComponent class
  formatTime(timestamp: number): string {
    if (!timestamp) return '00:00';

    // Convert timestamp to MM:SS format
    const totalSeconds = Math.floor(timestamp / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Add leading zeros
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
